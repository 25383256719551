/**=====================
    16. Form CSS Start
==========================**/
.theme-form {
  .form-control {
    :focus {
      box-shadow: 0 0 0 0.2rem rgba(171, 140, 228, 0.05);
    }
  }
  &.sm-form {
    .form-group {
      margin-bottom: $sm-form-margin-bottom;
    }
  }
  .form-group {
    margin-bottom: $form-group-margin-bottom;
    textarea {
      border-color: $form-input-border-color;
      &::-webkit-input-placeholder {
        color: $form-placeholder-color;
        font-size: $form-placeholder-font-size;
        letter-spacing: $form-placeholder-letter-spacing;
        font-weight: $form-placeholder-font-weight;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"],
  input[type="number"],
  input[type="tel"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"],
  input[type="week"],
  input[type="url"],
  input[type="file"],
  textarea,
  select {
    border-color: $form-input-border-color;
    background-color: $form-input-bg-color;
    font-size: $form-placeholder-font-size;
    color: $form-placeholder-color;
    box-shadow: none;
    &::-webkit-input-placeholder {
      color: $form-placeholder-color;
      font-size: $form-placeholder-font-size;
      letter-spacing: $form-placeholder-letter-spacing;
      font-weight: $form-placeholder-font-weight;
    }
  }
  input[type="file"] {
    height: auto;
  }
  select.form-control:not([size]):not([multiple]) {
    border-color: $form-input-border-color;
    font-size: $form-placeholder-font-size;
  }
  .checkbox {
    label {
      padding-left: 10px;
    }
  }
  .form-select,
  .form-control {
    &:focus {
      box-shadow: none;
      border-color: $light-gray;
    }
  }
  .btn-fb {
    background-color: $fb;
    color: $white;
    width: 100%;
  }
  .btn-twitter {
    background-color: $twitter;
    color: $white;
    width: 100%;
  }
  .btn-google {
    background-color: $google-plus;
    color: $white;
    width: 100%;
  }
}
.billing-form {
  margin: 0 -10px;
  > div {
    padding: 0 10px;
  }
}
.form-group {
  margin-bottom: 20px;
}
.form-control {
  border-color: $light-semi-gray;
  &::placeholder {
    color: $light-text;
  }
  &:focus {
    box-shadow: none;
    border-color: $light-gray;
  }
}
.form-select {
  border: 1px solid $light-gray;
}
.form-inline {
  display: -webkit-inline-box;
  .form-group {
    margin-right: 15px;
    .col-form-label {
      margin-right: 5px;
    }
  }
}
.form-control {
  &::placeholder {
    color: $dark-gray !important;
    font-weight: 400 !important;
  }
}
.search-form {
  .form-group {
    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 25px;
      background: $border-color;
      left: 82px;
      top: 50px;
    }
    &:after {
      position: absolute;
      content: "\f002";
      font-family: FontAwesome;
      top: 53px;
      left: 53px;
      color: $dark-gray;
    }
  }
  input {
    border: 1px solid $light-semi-gray;
    padding: 10px 10px 10px 70px;
    border-radius: 50px;
    background-color: rgba($light-color, 0.3);
  }
}
.form-label-align-right {
  label {
    text-align: right;
    padding-top: 17px;
    font-weight: 500;
  }
}
.form-label {
  line-height: 1.8;
  &::after {
    line-height: 1.8;
  }
}
.col-form-label {
  font-weight: 600;
}
label {
  font-weight: 600;
}
/**=====================
    16. Form CSS Ends
==========================**/