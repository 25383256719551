/* Earnings.scss */

/* أنماط بطاقة الأرباع */
.quarter-card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #ffffff; /* الحفاظ على خلفية بيضاء */
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* ألوان محددة لكل ربع */
  &.Q1 {
    border-left: 5px solid #007bff; /* أزرق */
  }

  &.Q2 {
    border-left: 5px solid #28a745; /* أخضر */
  }

  &.Q3 {
    border-left: 5px solid #ffc107; /* أصفر */
  }

  &.Q4 {
    border-left: 5px solid #dc3545; /* أحمر */
  }

  .quarter-icon {
    position: absolute;
    top: 10px; /* تقليل المسافة من الأعلى */
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #ffffff; /* لون النص داخل الدائرة */
    background-color: inherit; /* سيتم تحديد اللون بناءً على نوع البطاقة */
    /* إزالة الشفافية لجعل الأيقونات ملونة بالكامل */
    /* opacity: 0.2; */
  }

  /* تحديد لون الخلفية للأيقونات بناءً على نوع البطاقة */
  &.Q1 .quarter-icon {
    background-color: #007bff; /* أزرق */
  }

  &.Q2 .quarter-icon {
    background-color: #28a745; /* أخضر */
  }

  &.Q3 .quarter-icon {
    background-color: #ffc107; /* أصفر */
  }

  &.Q4 .quarter-icon {
    background-color: #dc3545; /* أحمر */
  }

  .quarter-info {
    padding: 20px;
    position: relative;
    z-index: 2;
    padding-top: 50px; /* زيادة الحشو العلوي لابتعاد الأيقونة عن النص */
  }

  .quarter-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333333;
  }

  .quarter-value {
    font-size: 2.5rem;
    font-weight: 700;
    color: #555555;
    margin-bottom: 12px;
  }

  .quarter-subinfo {
    display: flex;
    flex-direction: column;

    span {
      font-size: 0.95rem;
      color: #777777;
      margin-bottom: 5px;

      strong {
        color: #555555;
      }
    }
  }
}

/* أنماط بطاقات البيانات الأخرى */
.numbers-up-card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: #ffffff; /* الحفاظ على خلفية بيضاء */
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  /* ألوان محددة لكل نوع بيانات */
  &.Profit {
    border-left: 5px solid #17a2b8; /* معلومات */
  }

  &.Invoices {
    border-left: 5px solid #6c757d; /* ثانوي */
  }

  &.Payments {
    border-left: 5px solid #007bff; /* أزرق */
  }

  &.Count {
    border-left: 5px solid #28a745; /* أخضر */
  }

  &.Difference {
    border-left: 5px solid #dc3545; /* أحمر */
  }

  &.VAT {
    border-left: 5px solid #ffc107; /* أصفر */
  }

  .numbers-up-icon {
    position: absolute;
    top: 10px; /* تقليل المسافة من الأعلى */
    right: 15px;
    font-size: 50px;
    /* إزالة الشفافية لجعل الأيقونات ملونة بالكامل */
    /* opacity: 0.2; */
  }

  .numbers-up-info {
    padding: 20px;
    position: relative;
    z-index: 2;
    padding-top: 50px; /* زيادة الحشو العلوي لابتعاد الأيقونة عن النص */
  }

  .numbers-up-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333333;
  }

  .numbers-up-value {
    font-size: 2.5rem;
    font-weight: 700;
    color: #555555;
  }
}

/* أنماط الفلترة */
.date-filters {
  background-color: #ffffff; /* الحفاظ على خلفية بيضاء */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;

  .form-group {
    margin-bottom: 15px;
  }

  .btn-primary {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
  }
}

/* تحسين الاستجابة (Responsive Design) */
@media (max-width: 768px) {
  .quarter-card, .numbers-up-card {
    .quarter-icon, .numbers-up-icon {
      font-size: 40px;
      width: 35px; /* تقليل حجم الدائرة على الشاشات الصغيرة */
      height: 35px;
    }

    .quarter-title, .numbers-up-title {
      font-size: 1rem;
    }

    .quarter-value, .numbers-up-value {
      font-size: 2rem;
    }

    .quarter-info, .numbers-up-info {
      padding-top: 40px; /* تقليل الحشو العلوي للأجهزة الصغيرة */
    }
  }
}