/**=====================
    23. React-Plugin CSS Start
==========================**/
.simple-list {
  .list-group-item {
    padding: 0;
    border: none;
  }
}
// tree view 
.deni-react-treeview-container {
  width: 100%;
  padding: 15px;
  margin: 0 !important;
  .deni-react-treeview-item-container {
    .expand-button {
      font-size: 16px;
    }
    .icon-and-text {
      .text {
        font-family: "SF Pro Text";
      }
    }
    .actionButton {
      svg {
        width: 12px;
        height: 12px;
        margin: 0 5px;
      }
    }
  }
}
// range slider 
.range-slider {
  output {
    padding: 0 5px;
  }
}
// slick
.slider-spacing {
.slick-slider {
  margin: 0 -5px;
}
.slick-slide {
  margin: 0 5px;
}
}
.slick-page {
  .slick-slider {
    margin: -8px 0 20px;
  }
}

.slick-slider {
  margin: -7px 0 0;
  .item {
    &:focus {
      outline: none;
    }
  }
  .slick-dots {
    li {
      button {
        &:before {
          font-size: 12px;
          color: var(--theme-default);
        }
      }
      &.slick-active {
        button {
          &:before {
            color: var(--theme-default);
          }
        }
      }
    }
  }
}
.slider-spacing {
  .slick {
    &-slider {
      margin: 0 0 30px;
    }
    &-dots {
      bottom: -37px;
    }
  }
}
// range slider
.rangeslider-horizontal,
.rangeslider-vertical {
  .rangeslider__fill {
    background-color: var(--theme-default) !important;
  }
  .rangeslider__handle {
    &:focus {
      outline: none;
    }
  }
}
.slider-vertical,
.vertical-height {
  .value {
    text-align: center;
  }
}
.rangeslider-vertical {
  .rangeslider__labels {
    margin: 0 0 0 32px !important;
  }
}
.input-range__slider {
  background: var(--theme-default);
  border: 1px solid var(--theme-default);
}
.input-range__track--active {
  background: var(--theme-default);
}
.input-range {
  margin-bottom: 34px;
}
// dropzone
.dzu-dropzone {
  border: 2px dashed var(--theme-default) !important;
  background: rgba($primary-color, 0.2);
  overflow: auto !important;
  .dz-message {
    .note {
      background-color: $transparent-color;
    }
  }
}
.dzu-inputLabel {
  color: var(--theme-default) !important;
  text-align: center;
  margin-bottom: 0;
}
.dzu-previewContainer {
  padding: 30px;
}
.dzu-inputLabelWithFiles {
  margin-left: 30px;
  background-color: var(--theme-default) !important;
  color: $white !important;
}
.dzu-submitButton {
  background-color: var(--theme-default) !important;
  &:disabled {
    background-color: $light-gray !important;
  }
}
// calendar
.rbc-row-content {
  z-index: 1;
}
.rbc-event {
	background-color: $primary-color;
}
.rbc-day-slot {
	.rbc-background-event {
		background-color: $primary-color;
	}
}
.rbc-toolbar {
	button.rbc-active {
		background-color: var(--theme-default)!important;
		color: $white !important;
    box-shadow: none;
	}
	button {
    border: 1px solid $primary-color !important;
		&:active {
			background-color: var(--theme-default)!important;
			color: $white !important;
      box-shadow: none;
		}
	}
}
.fc {
  .fc-daygrid-body, .fc-scrollgrid-section table {
    width: 100% !important;
  }
}
.megaoptions-border-space-sm{
  .mega-title-badge{
    .badge{
      padding: 6px 4px 5px;
    }
  }
}
// form widget page
ol.progtrckr{
  li.progtrckr-todo{
    &::before{
      background-color:$gray-60 !important;
      border-radius: 1.2em;
    }
  }
}
ol.progtrckr{
  li.progtrckr-done, li.progtrckr-doing, li.progtrckr-todo{
    &::before{
      width: 30px !important;
      line-height: 2 !important;
    }
  }
}
// Image Cropper
.crop-portion {
  margin-left: 30px;
  margin-top: 30px;
  vertical-align: baseline;
}
.ReactCrop {
  margin-top: 15px;
}
.cropper-view-box {
  outline-color: var(--theme-default);
  outline: 1px solid var(--theme-default);
}
.cropper-point {
  background-color: var(--theme-default);
}
// image upload
.files-gallery {
  text-align: center;
  img {
    max-height: 130px;
    object-fit: cover;
    margin: 0 auto;
  }
}
// sticky
.note {
  background-color: rgba($primary-color, 0.3);
  &:nth-child(2n) {
    background: rgba($secondary-color, 0.3);
  }
}
.react-stickies-wrapper {
  .close {
    &:before,
    &:after {
      background: $transparent-color !important;
    }
  }
  .react-grid-layout {
    .react-grid-item {
      &:nth-child(1n) {
        .note {
          background: lighten($primary-color, 35%) !important;
        }
      }
      &:nth-child(2n) {
        .note {
          background: lighten($secondary-color, 35%) !important;
        }
      }
      &:nth-child(3n) {
        .note {
          background: lighten($success-color, 50%) !important;
        }
      }
      &:nth-child(4n) {
        .note {
          background: lighten($info-color, 30%) !important;
        }
      }
      &:nth-child(5n) {
        .note {
          background: lighten($warning-color, 35%) !important;
        }
      }
      &:nth-child(6n) {
        .note {
          background: lighten($danger-color, 25%) !important;
        }
      }
    }
  }
}
// sweetalert
.lead {
  margin-bottom: 5px;
}
.swal2-container {
  .swal2-actions {
    .swal2-styled {
      font-size: 14px;
    }
  }
}
.swal2-styled {
  &.swal2-confirm {
    background-color: var(--theme-default) !important;
    &:focus {
      box-shadow: 0 0 0 1px $white, 0 0 0 3px rgba($primary-color, 0.4);
    }
  }
}
.swal2-title {
  font-size: 20px !important;
}
.swal2-progress-steps {
  .swal2-progress-step,
  .swal2-progress-step-line {
    background: var(--theme-default) !important;
  }
  .swal2-progress-step {
    &.swal2-active-progress-step {
      background: var(--theme-default) !important;
      ~ .swal2-progress-step,
      ~ .swal2-progress-step-line {
        background: rgba($primary-color, 0.2) !important;
      }
    }
  }
}
// datetime picker
.bootstrap-datetimepicker-widget {
  tr {
    th,
    td {
      &:active,
      &.active {
        background-color: var(--theme-default);
      }
      span {
        &:active,
        &.active {
          background-color: var(--theme-default);
        }
      }
    }
  }
}
.typeahead{
  .form-group{
    margin-bottom: 0;
  }
}
.table-responsive{
  table{
    thead{
      tr{
        border-bottom: 2px solid $light-semi-gray;
      }
    }
  }
}
// upload
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
  background: $transparent-color !important;
  .uploadPictureContainer {
    margin: 1% !important;
  }
  .chooseFileButton {
    margin-top: 0 !important;
  }
}
.react-datepicker__navigation--previous {
  left: 10px;
}
.react-datepicker__navigation--next {
  right: 10px;
}
.date-picker{
  .theme-form{
    .min-date{
      .react-datepicker-wrapper{
        + .react-datepicker-wrapper{
          input{
            margin-top: 10px;
          }
        }
      }
    }
  }
}   
// daterange picker
.daterangepicker {
  td,
  th {
    &.available {
      &:hover {
        background-color: var(--theme-default);
      }
    }
  }
}
// to-do
.todo {
  .action-box {
    &.large {
      .icon {
        .icon-trash,
        .icon-check {
          color: var(--theme-default);
        }
      }
    }
  }
  .todo-list-wrapper {
    #todo-list {
      li {
        &.completed {
          .task-container {
            .task-label {
              color: var(--theme-default);
            }
          }
        }
      }
    }
  }
  @-webkit-keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }
    100% {
      background: $white;
    }
  }
  @keyframes taskHighlighter {
    0% {
      background: rgba($primary-color, 0.5);
    }
    100% {
      background: $white;
    }
  }
}
// clock

.fcrYGG{

  background-color: transparent !important;
  .sc-htpNat {
    background: url(../../images/clock-face.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100%;
  }
}

.react-clock__face{
  color: $white;
  border: 1px solid $white;
}
.react-clock__mark__body,
.react-clock__hand__body {
  background-color: $white !important;
}
// scrollable page
.scrollarea {
  max-width: 800px;
  max-height: 300px;
  .scrollbar-container {
    z-index: 7 !important;
  }
}
//form wizard
ol {
  display: flex;
  &.progtrckr {
    list-style-type: none;
    padding: 0;
    em{
      display: none;
    }
    li {
      display: inline-block;
      text-align: center;
      line-height: 4.5rem;
      cursor: pointer;
      width: 100%;
      span {
        padding: 0 1.5rem;
      }
      &.progtrckr-doing {
        color: black;
        border-bottom: 4px solid $gray-60;
        &:before {
          content: "\2022";
          color: $white;
          background-color: $gray-60;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }
        &:hover {
          &:before {
            color: $primary-color;
          }
        }
      }
      &.progtrckr-done {
        color: black;
        border-bottom: 4px solid $primary-color;
        &:before {
          content: "\2713";
          color: $white;
          background-color: $primary-color;
          width: 1.2em;
          line-height: 1.2em;
          border-radius: 1.2em;
        }
        &:hover {
          &:before {
            color: $dark-color;
          }
        }
      }
      &:after {
        content: "\00a0\00a0";
      }
      &:before {
        position: relative;
        bottom: -3.7rem;
        float: left;
        left: 50%;
      }
      &.progtrckr-todo {
        &:before {
          content: "\2022";
          color: $white;
          background-color: $transparent-color;
          width: 1.2em;
          line-height: 1.4em;
        }
        &:hover {
          &:before {
            color: $primary-color;
          }
        }
      }
    }
  }
}
.base-input{
  .card-footer{
    > div{
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
form.needs-validation {
	.row {
		input~span {
			color: $danger-color;
		}
    .input-group ~ span{
      color: $danger-color;
    }
	}
}
.chart-widget-top{
  #chart-widget1 , #chart-widget2 , #chart-widget3{
    margin-bottom: -15px;
  }
}
// calender
.rbc-calendar {
  height: 1000px !important;
}
#external-events {
  position: absolute;
  z-index: 2;
  padding: 10px;
  border: 1px solid $gray-60;
  background: $transparent-color;
  overflow-y: auto;
  width: 90%;
}
#external-events .fc-event {
  background: $primary-color;
  margin: 1em 0;
  cursor: move;
}
.fc-event, .fc-event-dot{
  padding: 7px !important;
  color: $white !important;
}
.fc-button-primary, .fc-event, .fc-event-dot{
  background-color: $primary-color !important;
  border: 1px solid $primary-color !important;
}
//  base-typeahead
.rbt-token {
  color: var(--theme-default) !important;
  background-color: rgba($primary-color, 0.1) !important;
  .rbt-token-remove-button {
    padding: 4px;
    background-color: transparent;
    color: $primary-color;
    border: none;
  }
}
.btn-toolbar {
  margin-bottom: -8px !important;
}
.rbt-aux {
  .rbt-close {
    margin-top: 0;
    background-color: rgba($primary-color, 0.1);
    color: $primary-color;
    border: none;
  }
}
// time-picker
.react-timekeeper {
  .react-timekeeper__tb-hour--active, .react-timekeeper__tb-minute--active {
    color: var(--theme-default);
  }
  .react-timekeeper__meridiem--active {
    background: var(--theme-default);
    color: $white;
  }
  .react-timekeeper__hand-circle-outer, .react-timekeeper__hand-circle-center {
    fill: rgba($primary-color, 0.1);
  }
  .react-timekeeper__clock-hand {
    stroke: rgba($primary-color, 0.1);
  } 
  + .react-timekeeper {
    margin: 0 15px;
  }
}
.react_times_button {
  .wrapper {
    display: block;
  }
}
.time_picker_wrapper2 {
  display: flex;
  .time_picker_container {
    width: 44%;
  }
  .gap {
    padding: 0 5px;
  }
}
.inlineform{
  display: flex;
}
.bootstrap-touchspin{
  .input-group-append{
    .dropdown-toggle.btn-light{
      padding: 7px 28px;
    }
  }
}
.billing-form{
  display: flex;
}
.form-default{
  .checkbox{
    label{
      margin-top: 0;
    }
  }
  fieldset{
    border: unset;
  }
}
.select2-drpdwn{
  .css-1okebmr-indicatorSeparator{
    background: none;
  }
}
.datetime-picker{
  .react-datepicker__input-container{
    input{
      padding: 7px 12px;
    }
  }
  .input-group{
    flex-wrap: unset;
  }
}
// toastr
.Toastify__close-button {
  font-weight: 500;
  svg {
    stroke: $white !important;
    fill: $white !important;
  }
}
.Toastify__toast--success {
  background: lighten($success-color, 5%) !important;
  color: $white !important;
  font-weight: 500;
  svg {
    fill: transparent !important;
    stroke: $white !important;
  }
  .Toastify__progress-bar {
    background: lighten($success-color, 35%) !important;
  }
}
.Toastify__toast--info {
  background: lighten($info-color, 15%) !important;
  color: $white !important;
  svg {
    fill: transparent !important;
    stroke: $white !important;
  }
  .Toastify__progress-bar {
    background: lighten($info-color, 35%) !important;
  }
}
.Toastify__toast--warning {
  background: lighten($warning-color, 15%) !important;
  color: $white !important;
  svg {
    fill: transparent !important;
    stroke: $white !important;
  }
  .Toastify__progress-bar {
    background: lighten($warning-color, 25%) !important;
  }
}
.Toastify__toast--error {
  background: lighten($danger-color, 5%) !important;
  color: $white !important;
  font-weight: 500;
  svg {
    fill: transparent !important;
    stroke: $white !important;
  }
  .Toastify__progress-bar {
    background: lighten($danger-color, 20%) !important;
  }
}
// draggable card
.draggable-card {
  > div {
    > div {
      width: 100%;
      display: flex;
      div[data-testid="lane"] {
        width: 100%;
      }
      div[data-testid="card"] {
        > div {
          > div {
            min-width: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}
.react-kanban-board  {
  .react-kanban-column {
    width: 100%;
  }
  .react-kanban-card {
    max-width: unset;
    width: 100%;
  }
}
.jkanban-container {
  .custom-board{
    .kanban-drag{
      .react-kanban-column{
        &:first-child {
          background-color: rgba($info-color, 0.3);
          > div[data-rbd-drag-handle-draggable-id] {
            background-color: $info-color;
            color: $white;
          }
        }
        &:nth-child(2){
          background-color:rgba($warning-color, 0.3);
          > div[data-rbd-drag-handle-draggable-id] {
            background-color: $warning-color;
            color: $white;
          }
        }
        &:nth-child(3){
          background-color:rgba($success-color, 0.3);
          > div[data-rbd-drag-handle-draggable-id] {
            background-color: $success-color;
            color: $white;
          }
        }
      }
    }
  }
  .kanban-container {
    display: flex;
    .kanban-item {
      .customers{
        ul.simple-list{
          li{
            background-color: $transparent-color;
            + li{
              img{
                margin: 0;
              }
            }
          }
        }
      }
      &:focus {
        outline: none;
      }
      .kanban-box {
        border: 1px solid $light-semi-gray;
        &:focus {
          outline: none;
        }
      }
    }
    .kanban-drag {
      width: 100%;
      div[data-testid="lane-header"] {
        &:focus {
          outline: none;
        }
        > div {
          &:focus {
            outline: none;
          }
        }
      }
      > div {
        padding: 0;
        > div {
          display: flex;
          width: 100%;
          div[data-testid="lane"] {
            width: 100%;
            background-color: rgba(115, 102, 255, 0.05);
            padding: 0;
            > div {
              width: 100%;
              padding: 20px;
              border-bottom: 1px solid rgba($primary-color,0.1);
              > div{
                padding-bottom: 0;
              }
            }
            div[data-testid="card"] {
              > div {
                width: 100%;
              }
              + div[data-testid="card"] {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }
  .react-kanban-column {
    background-color: rgba($primary-color, 0.05);
    width: 100%;
    padding: 0;
    .react-kanban-column-header {
      padding-bottom: 0;
    }
    > div {
      padding: 15px;
      > div {
        > div {
          display: block !important;
        }
      }
    }
    div[tabindex="0"] {
      + div[tabindex="0"] {
        margin-top: 20px;
      }
    }
  }
}
.product-list{
  &.table-responsive{
    table{
      thead{
        tr{
          border-bottom: none;
        }
      }
    }
  }
}
//DragNDrop
.ui-sortable {
  .container {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: var(--theme-default);
    div {
      padding: 10px;
      background-color: darken($primary-color, 5%);
      transition: opacity 0.4s ease-in-out;
      color: $white;
    }
    div {
      cursor: move;
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &.ex-moved {
      background-color: $form-wizard;
    }
    &.ex-over {
      background-color: var(--theme-default);
    }
    &:nth-child(odd) {
      background-color: var(--theme-default);
    }
  }
}
.gh-fork {
  position: fixed;
  top: 0;
  right: 0;
  border: 0;
}
.gu-mirror {
  padding: 10px;
  background-color: darken($primary-color, 5%);
  transition: opacity 0.4s ease-in-out;
  color: $white;
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
.wrapper {
  display: table;
}
.gu-mirror {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.handle {
  padding: 0 5px;
  margin-right: 5px;
  background-color: darken($primary-color, 15%);
  cursor: move;
}
// timeline
.vertical-timeline {
  &::before {
    background: $light-color;
  }
  .vertical-timeline-element {
    p {
      font-size: 13px !important;
    }
    .vertical-timeline-element-icon {
      color: $white;
    }
    &:first-child,
    &:nth-child(7) {
      .vertical-timeline-element-icon {
        background-color: var(--theme-default);
      }
    }
    &:nth-child(2) {
      .vertical-timeline-element-icon {
        background-color: var(--theme-secondary);
      }
    }
    &:nth-child(3),
    &:last-child {
      .vertical-timeline-element-icon {
        background-color: $success-color;
      }
    }
    &:nth-child(4) {
      .vertical-timeline-element-icon {
        background-color: $info-color;
      }
    }
    &:nth-child(5) {
      .vertical-timeline-element-icon {
        background-color: $warning-color;
      }
    }
    &:nth-child(6) {
      .vertical-timeline-element-icon {
        background-color: $danger-color;
      }
    }
  }
}
.vertical-timeline-element-content {
  box-shadow: 1px 1px 2px 1px rgba($black, 0.08);
  background-color: $light;
  audio {
    width: 100%;
    margin-top: 15px;
  }
  .vertical-timeline-element-date {
    font-size: 14px !important;
  }
  .vertical-timeline-element-content-arrow {
    border-right: 7px solid $light-color;
  }
}
.vertical-timeline--two-columns {
  .vertical-timeline-element-content {
    .vertical-timeline-element-date {
      top: 10px !important;
    }
  }
}
.vertical-timeline--one-column {
  &:before {
    left: 28px !important;
  }
  .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
  }
  .vertical-timeline-element-content {
    margin-left: 90px;
    img {
      width: 500px;
    }
    .vertical-timeline-element-date {
      float: none;
      display: block;
    }
  }
}
// table
.rdt_Table{
  .rdt_TableBody{
    .rdt_TableCell{
      padding: 15px;
    }
  }
}
// product list page
.product-table{
  .rdt_TableBody{
    .rdt_TableRow{
      &:hover{
        .rdt_TableCell{
          &:first-child{
            background-color: $table-row-hover;
          }
        }
      }
      &:nth-child(odd){
        .rdt_TableCell{
          &:first-child{
            background-color: $light-table-color;
          }
        }
      }
    }
    .rdt_TableCell{
      img{
        width: 65px !important;
        height: 65px !important;
      }
      + .rdt_TableCell{
        > div{
          h6{
            margin-bottom: 6px;
          }
        }
      }
    }
  }
}
// leafleft map
.leaflet-top,
.leaflet-bottom {
  z-index: 7 !important;
}
.leaflet-pane {
  z-index: 1 !important;
}
// vector map
.map-block {
  svg {
    g {
      transform-origin: 0 0;
    }
  }
}
#gmap-simple {
  svg {
    path {
      fill: var(--theme-default) !important;
    }
  }
}
#usa {
  svg {
    path {
      fill: var(--theme-secondary) !important;
    }
  }
}
#canada {
  svg {
    path {
      fill: $success-color !important;
    }
  }
}
#asia {
  svg {
    path {
      fill: $warning-color !important;
    }
  }
}
#europe {
  svg {
    path {
      fill: $danger-color !important;
    }
  }
}
#america {
  svg {
    path {
      fill: $dark-color !important;
    }
  }
}
.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 20px;
  height: 20px;
}
.jvectormap-zoomin {
  bottom: 65px;
}
.jvectormap-zoomout {
  bottom: 30px;
}
.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  position: absolute;
  border-radius: 3px;
  background: $theme-font-color;
  padding: 5px;
  color: $white;
  cursor: pointer;
  text-align: center;
  box-sizing: content-box;
}
// rating
.rating {
  align-items: center;
  span {
    .fa {
      color: var(--theme-default);
    }
    span {
      span {
        color: var(--theme-default);
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .jkanban-container {
    .kanban-container {
      .kanban-drag {
        padding: 0;
        > div {
          width: 100% !important;
          > div {
            div[data-testid="lane"] {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
.vertical-scroll{
  max-height: 280px;
}

// responsive css
@media screen and (max-width: 991px) {
  .dzu-previewContainer { 
    padding: 20px;
  }
  .slider-spacing {
    .slick {
      &-slider {
        margin: 0 0 18px;
      }
      &-dots {
        bottom: -25px;
      }
    }
  }
}
/**=====================
    23. React-Plugin CSS Ends
==========================**/