/**=====================
    33. Header CSS Start
==========================**/
$header-size: 136px;
%common {
  border-left-color: $dark-color;
  transition: $sidebar-transition;
}
%for-animated-hover {
  box-shadow: $main-header-shadow;
  transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
%for-animated-hover-box {
  opacity: 1;
  transform: translateY(0px);
  visibility: visible;
  &:before {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $white;
    content: "";
    top: -7px;
    position: absolute;
    right: 25px;
    z-index: 2;
  }
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid $light-background;
    content: "";
    top: -7px;
    position: absolute;
    right: 25px;
    z-index: 1;
  }
}
/*======= Page Header css Start ======= */
.page-wrapper {
  &.horizontal-wrapper{
    .page-body-wrapper{
      &.horizontal-menu{
        .page-body{
          margin-top: 130px;
        }
      }
    }
  }
  .page-main-header {
    position: $main-header-position;
    top: $main-header-top;
    z-index: $main-header-z-index;
    width: 100%;
    display: flex;
    align-items: center;
    transition: $sidebar-transition;
    background-color: $white;
    box-shadow: $main-header-shadow;
    .toggle-sidebar,
    .onhover-dropdown {
      position: relative;
      background-color: transparent;
      &:first-child,
      &:last-child {
        &::before {
          background-color: unset;
        }
      }
      &:before {
        content: "";
        position: absolute;
        height: 40px;
        width: 40px;
        background-color: rgba($primary-color, 0.1);
        border-radius: 100%;
        left: -11px;
        z-index: -2;
        top: -8px;
        transform: scale(0);
        transition: all 0.3s ease;
      }
      &:hover {
        &:before {
          transform: scale(1);
          transition: all 0.3s ease;
        }
        .notification-box {
          svg {
            color: var(--theme-default) !important;
            stroke: var(--theme-default) !important;
          }
        }
        svg {
          color: var(--theme-default) !important;
          stroke: var(--theme-default) !important;
        }
      }
      h6 {
        margin-bottom: 0;
      }
    }
    .onhover-dropdown {
      &:before {
        top: -2px;
        left: -2px;
      }
    }
    &.open {
      margin-left: 0;
      width: 100%;
      transition: $sidebar-transition;
    }
  }
  .page-body-wrapper {
    height: 100vh;
    .page-body {
      min-height: calc(100vh - #{$header-size});
      margin-top: $header-size;
      padding: $page-body-padding;
      position: relative;
      transition: 0.5s;
    }
    .page-header {
      padding-top: $page-header-padding;
      padding-bottom: $page-header-padding;
      .row {
        align-items: center;
        h3 {
          font-size: $page-title-font-size;
          margin-bottom: $page-title-margin-bottom;
          text-transform: $page-title-text-tranform;
          font-weight: 700;
          small {
            display: $page-small-title-display;
            font-size: $page-small-title-font-size;
            margin-bottom: $page-small-title-margin-bottom;
            text-transform: $page-small-title-text-transform;
            color: $theme-body-sub-title-color;
          }
        }
      }
      .breadcrumb {
        background-color: $transparent-color;
        padding: 0;
        margin-top: 5px;
        margin-bottom: 0;
        li {
          font-size: 14px;
          letter-spacing: 1px;
          font-weight: 500;
        }
        .breadcrumb-item {
          & + .breadcrumb-item {
            &:before {
              content: $breadcrumb-content;
            }
          }
          a {
            color: var(--theme-default);
            svg {
              width: 14px;
              height: 14px;
              vertical-align: text-top;
            }
          }
        }
      }
    }
  }
}
/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.page-wrapper.compact-wrapper{
  .page-body-wrapper{
    header.main-nav.close_icon{
      .main-navbar{
        .nav-menu{
          .dropdown{
            .menu-title.active{
              ~ .sidebar-submenu{
                display: none !important;
              }
            }
          }
        }
      }
    }
  }
}
.page-main-header {
  max-width: 100vw;
  .main-header-right {
    width: 100%;
    display: flex;
    align-items: center;
    padding: $main-header-right-padding;
    position: relative;
    .toggle-sidebar {
      margin-right: 25px;
      cursor: pointer;
      padding: 0;
      svg {
        width: 18px;
      }
    }
    .vertical-mobile-sidebar {
      display: none;
    }
    .nav-left {
      i {
        margin-right: 20px;
      }
      input:focus {
        outline: 0 !important;
      }
    }
    .nav-right {
      .simple-list{
        li.onhover-dropdown{
          &:last-child{
            display: flex;
            align-items: center;
          }
        }
      }
      .onhover-show-div{
        top: 50px;
        right: 0;
        left: unset;
        li{
          padding: 15px;
        }
      }
      .bookmark-flip {
        width: unset !important;
        box-shadow: unset !important;
        background-color: $white !important;
        overflow: initial;
        .flip-card{
          width: 300px;
          height: 240px;
          .flip-card-inner{
            position: relative;
            height: 100%;
            text-align: center;
            transform-style: preserve-3d;
            transition: transform 0.6s, -webkit-transform 0.6s;
            .front{
              transition: .3s;
              .bookmark-dropdown {
                li {
                  &:first-child {
                    max-height: 130px;
                    overflow: auto;
                  }
                }
              }
            }
            .back{
              transform: rotateY(180deg);
            }
          }
          .flip-card-inner.flipped{
            transform: rotateY(180deg);
            .front{
              opacity: 0;
            }
          }
        }
        .bookmark-dropdown{
          padding: 15px;
          li{
            &:hover{
              background-color: unset;
            }
            &:last-child{
              justify-content: center;
            }
          }
          .bookmark-content{
            .bookmark-icon{
              width: 52px;
              height: 52px;
              background-color: $light-color;
              border-radius: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;
              &:hover{
                background-color: rgba($primary-color, 0.1);
                stroke: $primary-color;
                svg{
                  stroke: $primary-color !important;
                }
              }
              svg{
                stroke: $theme-body-font-color !important;
              }
            }
          }
        }
        .Typeahead-menu {
          top: 53%;
          background: transparent;
          box-shadow: none;
          left: -1px;
          overflow: auto;
          max-height: 78px;
        }
        .front{
          background-color: $white;
          position: absolute;
          overflow: hidden;
          width: 300px;
          height: auto;
          backface-visibility: hidden;
          box-shadow: $card-box-shadow;
          h6{
            padding: 20px;
            text-align: center;
          }
        }
        .back {
          background-color: $white;
          position: absolute;
          overflow: hidden;
          width: 300px;
          height: 240px;
          backface-visibility: hidden;
          box-shadow: $card-box-shadow;
          li {
            &:hover{
              background-color: unset;
            }
            .flip-back{
              margin: 0 auto;
            }
            &:first-child {
              margin-top: 20px;
              height: 140px !important;
              svg {
                position: relative;
                width: 16px;
                height: 16px;
                top: -2px;
                right: 0;
                opacity: 1;
              }
            }
          }
        }
        .ProfileCard-realName {
          text-align: left;
        }
        .ProfileCard-avatar {
          svg {
            color: $theme-body-font-color !important;
          }
        }
      }
    }
    .left-menu-header {
      ul {
        li {
          width: 100%;
          .search-bg {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            i {
              color: $semi-dark;
            }
          }
          .search-form {
            display: flex;
            align-items: center;
            border-left: 1px solid $gray-60;
            padding: 0 10px;
            input {
              padding: 8px 0;
              border: none;
              outline: none;
              background: $transparent-color;
              color: $semi-dark;
              font-weight: 500;
              font-family: $font-roboto;
              margin-top: 3px;
              &::placeholder {
                color: $semi-dark;
                font-weight: 400;
                font-family: $font-roboto;
              }
            }
          }
        }
      }
    }
    .nav-right {
      text-align: left;
      padding-left: $main-header-right-nav-right;
      padding-right: $main-header-right-nav-right;
      ul {
        li {
          .mode {
            font-size: 18px;
            transition: all 0.3s ease;
            width: 15px;
            text-align: center;
          }
          svg {
            vertical-align: $main-header-right-nav-icon-align;
            width: $main-header-right-nav-icon-size;
            path {
              color: $main-header-right-nav-icon-color;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .onhover-show-div {
        li {
          display: block;
          .media {
            a {
              color: $semi-dark;
            }
          }
          a {
            font-size: 15px;
            letter-spacing: 0.3px;
            font-weight: 600;
          }
        }
      }
      > ul {
        padding: 0 0;
      }
      &.right-menu {
        ul {
          justify-content: flex-end;
          .cart {
            ul {
              height: 331px;
              overflow-y: scroll;
              li {
                border-bottom: 1px solid $light-gray;
                padding: 15px 20px;
                div {
                  h6 {
                    font-weight: 600;
                    &.qty {
                      display: flex;
                      align-items: center;
                      margin-bottom: 0;
                      input {
                        max-width: 60px;
                        margin-left: 15px;
                        padding: 5px 10px;
                        height: 30px;
                      }
                    }
                    &.price {
                      color: var(--theme-default);
                    }
                  }
                  h5 {
                    margin-bottom: 0;
                  }
                  p {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
          li {
            position: relative;
            .notification {
              position: absolute;
              top: -2px;
              right: -9px;
              padding: 5px 7px;
            }
            .profile-media {
              img {
                width: 35px;
                height: 35px;
                margin: 0;
              }
              .media-body {
                span {
                  color: $semi-dark;
                  font-size: 15px;
                  letter-spacing: 0.3px;
                  font-weight: 600;
                }
                p {
                  font-size: 12px;
                  line-height: 1;
                  color: rgba($theme-body-font-color, 0.7);
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
      .icon-user {
        font-size: 16px;
      }
      > ul {
        display: flex;
        align-items: center;
        > li {
          position: relative;
          padding: 6px 10px;
          border-radius: 4px;
          margin-right: 5px;
          .badge {
            padding: 4px 8px;
          }
          span {
            letter-spacing: 0.9px;
            .fa {
              color: $gray-60;
            }
            ul {
              left: inherit;
              right: -10px;
              width: 130px;
              &:before,
              &:after {
                left: inherit;
                right: 10px;
              }
              li {
                display: block;
                a {
                  font-size: 14px;
                  color: $dark-color;
                  i {
                    margin-left: 10px;
                    font-size: 13px;
                    color: $dark-color;
                  }
                }
              }
            }
          }
        }
        .flag-icon {
          font-size: 16px;
        }
      }
      .chat-dropdown {
        width: 330px;
        top: 49px;
        right: 0;
        left: unset;
        ul {
          max-height: 252px;
          overflow-y: scroll;
        }
        li {
          border-bottom: 1px solid $light-gray;
          padding: 20px 20px 0;
          .media {
            position: relative;
            .status-circle {
              left: 0;
              top: 0;
            }
            .media-body {
              a{
                span{
                  color: $theme-body-font-color;
                }
              }
              > span {
                font-weight: 500;
              }
              p {
                margin-bottom: 15px;
                color: $dark-gray;
              }
            }
          }
        }
        &:before,
        &:after {
          right: 20px !important;
          left: unset !important;
        }
      }
      .language-dropdown {
        top: 50px;
        left: -110px;
        right: unset;
        li {
          padding: 10px 15px;
          + li {
            border-top: 1px solid $light-color;
          }
          a {
            display: flex;
            i.flag-icon {
              margin-right: 10px;
            }
          }
        }
      }
      .notification-box {
        position: relative;
        .badge {
          position: absolute;
          right: -5px;
          top: -6px;
          padding: 2px 4px;
          font-size: 12px;
          font-weight: 700;
          font-family: $font-work-sans, $font-serif;
        }
      }
      .notification-dropdown {
        top: 52px;
        width: 300px;
        right: -10px !important;
        left: unset;
        ul{
          li{
            p{
              a{
                color: $theme-body-font-color;
                font-size: 14px;
                font-weight: 500;
              }
            }
          }
        }
        i {
          position: absolute;
          left: 0;
          top: 4px;
        }
        li {
          padding: 7px 20px;
          .media {
            position: relative;
            img {
              position: absolute;
              left: 0;
              top: 4px;
            }
            .media-body {
              padding-left: 40px;
            }
          }
          p {
            span {
              color: $light-font;
            }
          }
        }
        &:before,
        &:after {
          right: 28px !important;
          left: unset !important;
        }
      }
      .profile-dropdown {
        width: 160px;
        top: 57px;
        left: -80px;
        padding: 10px;
        li {
          padding: 10px !important;
          a{
            span{
              color: $theme-body-font-color;
            }
          }
          svg{
            stroke: $theme-body-font-color !important;
          }
          .btn {
            padding: 0.375rem 1rem;
          }
          &:hover {
            a{
              span{
                color: $primary-color;
              }
            }
            svg{
              stroke: $primary-color !important;
            }
          }
          &:last-child {
            border-top: $card-border-color !important;
            padding-top: 10px !important;
          }
          + li {
            padding-top: 0 !important;
          }
          svg {
            width: 16px;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
      }
    }
    li {
      display: inline-block;
      position: relative;
    }
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/
/*=======Mega menu css start=======*/
.mega-menu {
  perspective: inherit;
  position: static !important;
  padding-top: 5px;
  .default-according {
    .card {
      .btn-link {
        font-weight: 500;
        color: gray;
      }
      .card-body {
        font-size: 12px;
      }
    }
  }
  .onhover-show-div {
    width: 1500px;
  }
  .card-body {
    padding: 20px;
  }
  .title {
    color: $theme-body-font-color;
    font-weight: 600;
    border-bottom: 1px solid $light-semi-gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  div {
    > div {
      a {
        margin-bottom: 0px;
        display: inline-block;
        color: $theme-body-sub-title-color;
      }
    }
  }
  .custom-nav-img {
    position: static;
    .desk {
      line-height: 25px;
      font-size: 14px;
      color: $theme-body-sub-title-color;
    }
  }
  .list-unstyled {
    div {
      a {
        padding: 8px 35px 8px 0;
        transition: $sidebar-transition;
        &:hover {
          padding: 8px 35px 8px 10px;
          transition: $sidebar-transition;
        }
      }
    }
  }
  .galleria div {
    margin-bottom: 5px;
    &:hover {
      transform: translateY(4px) scale(1.04);
    }
  }
  .galleria-list .galleria > div {
    width: 25%;
    font-size: 12px;
    float: left;
    color: $black;
    transition: ease-in 0.3s;
    > a {
      display: block;
      margin: 5px 0 10px;
      padding: 0 0 !important;
    }
    img {
      border-radius: 3px;
      max-width: 100%;
    }
    .username {
      font-size: 12px;
      color: $theme-body-font-color;
      margin-bottom: 5px;
      small {
        display: block;
        font-size: 11px;
        color: $theme-body-sub-title-color;
        margin-top: 3px;
      }
    }
  }
  .drilldown {
    overflow: hidden;
    width: 100%;
    padding: 0;
    transform: translate3d(0, 0, 0);
    a {
      width: 100%;
      &:hover {
        background-color: transparent;
        color: var(--theme-default);
      }
    }
  }
  .drilldown-sub {
    display: none;
  }
  .drilldown-back {
    font-weight: bold;
  }
}
/*=======Mega menu css end=======*/
// responsive header
@media only screen and (max-width: 1366px) {
  .profile-dropdown {
    &.onhover-show-div {
      right: 0;
      left: unset !important;
      &:before, &:after {
        right: 10px !important;
        left: unset !important;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .page-main-header {
    .main-header-right {
      .nav-right {
        .bookmark-flip{
          .back, .flip-card{
            height: 240px;
          }
        }
        .notification-dropdown, .profile-dropdown {
          top: 50px;
          &::before , &::after{
            right: 20px !important;
          }
        }
      }
      .left-menu-header {
        ul {
          li {
            width: 260px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
  .onhover-dropdown {
    &:hover {
      .onhover-show-div {
        &::before,
        &::after {
          right: 45px;
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .toggle-sidebar {
        margin-right: 18px;
      }
      .nav-right {
        .language-dropdown{
          left: -70px;
        }
        .notification-dropdown,
        .chat-dropdown, .bookmark-flip {
          width: 270px;
          right: -115px !important;
          &:before,
          &:after {
            right: 124px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .ProfileCard-realName{
    a{
      font-size: 12px;
    }
  }
  .page-main-header{
    .main-header-right{
      .nav-right{
        .bookmark-flip{
          .Typeahead-menu{
            max-height: 70px;
          }
          .back{
            li{
              &:first-child{
                height: 120px !important;
              }
            }
          }
          .bookmark-dropdown{
            li{
              padding: 9px;
            }
            .bookmark-content{
              .bookmark-icon{
                width: 46px;
                height: 46px;
              }
            }
          }
        }
      }
    }
  }     
  .page-wrapper{
    .page-main-header{
      .main-header-right{
        .nav-right {
          .notification-dropdown,
          .chat-dropdown {
            width: 250px !important;
          }
        }
        .nav-right.right-menu{
          li.onhover-dropdown{
            &:last-child{
              .media-body{
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .onhover-dropdown {
    &:hover {
      .onhover-show-div {
        &::before,
        &::after {
          right: 16px;
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right.right-menu {
        .onhover-show-div.bookmark-flip{
          left: -130px;
        }
        ul {
          .cart {
            ul {
              li {
                padding: 15px 12px;
              }
            }
          }
        }
      }
      .nav-right {
        .bookmark-flip{
          .back, .flip-card{
            width: 242px;
            height: 210px;
          }
          .front{
            width: 242px;
          }
        }
        .chat-dropdown {
          li {
            padding: 15px 14px 0;
          }
        }
        ul {
          li {
            svg {
              width: 16px;
            }
          }
        }
        .notification-dropdown {
          li {
            padding: 7px 12px;
            .media {
              .media-body {
                padding-left: 30px;
              }
            }
          }
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        >ul{
          >li{
            margin-right: 0;
          }
        }
        .language-dropdown {
          top: 40px;
        }
        .profile-dropdown {
          left: -100px;
        }
      }
      .left-menu-header {
        ul {
          li {
            width: auto;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .onhover-dropdown {
    &:hover {
      .onhover-show-div {
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
}
/**=====================
    33. Header CSS Ends
==========================**/