/**=====================
    72. theme layout CSS Start
==========================**/
//Sidebar color scss
$color1: #405365;
$color2: #1c1d1e;
$color3: #0c0c3c;
$color4: #0747a6;
$color5: #4d3096;
@each $bg-name, $bg-color in (color1, $color1), (color2, $color2),
  (color3, $color3), (color4, $color4), (color5, $color5), (light, $light-color),
  (dark, #2f3c4e)
{
  .bg-#{$bg-name} {
    background-color: $bg-color;
  }
}
//Sidebar settings
.page-wrapper.compact-wrapper,
.page-wrapper.horizontal-wrapper {
  .page-body-wrapper {
    &.horizontal-menu {
      header {
        &.main-nav {
          &.dark-sidebar {
            background: $dark-color;
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $dark-card-background;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $dark-card-background;
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: var(--theme-default);
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $dark-card-background;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: var(--theme-default) !important;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &.color1-sidebar {
            background-color: $color1;
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $color1;
                        border-color: $light-all-font-color;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $color1;
                }
              }
              &:hover {
                &.custom-scrollbar {
                  color: darken($color1, 8%);
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: $white;
                        &:after {
                          border-color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $color1;
                              border-color: $light-all-font-color;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: $white;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &.color2-sidebar {
            background: $color2;
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $color2;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $color2;
                }
              }
              &:hover {
                &.custom-scrollbar {
                  color: darken($color2, 8%);
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: var(--theme-default);
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $color2;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: var(--theme-default);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &.color3-sidebar {
            background: $color3;
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $color3;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $color3;
                }
              }
              &:hover {
                &.custom-scrollbar {
                  color: darken($color3, 8%);
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: var(--theme-default);
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $color3;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: var(--theme-default);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &.color4-sidebar {
            background: $color4;
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $color4;
                        border-color: $light-all-font-color;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $color4;
                }
              }
              &:hover {
                &.custom-scrollbar {
                  color: darken($color4, 8%);
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: $white;
                        &:after {
                          border-color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $color4;
                              border-color: $light-all-font-color;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: $white;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &.color5-sidebar {
            background: $color5;
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $color5;
                        border-color: $light-all-font-color;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $color5;
                }
              }
              &:hover {
                &.custom-scrollbar {
                  color: darken($color5, 8%);
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: $white;
                        &:after {
                          border-color: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $color5;
                              border-color: $light-all-font-color;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: $white;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &[sidebar-layout="border-sidebar"] {
            .main-navbar {
              .nav-menu {
                > li {
                  .nav-link {
                    border-bottom: 1px solid $light-gray;
                    transition: all 0.3s ease;
                  }
                }
              }
            }
          }
          &[sidebar-layout="iconcolor-sidebar"] {
            .main-navbar {
              .nav-menu {
                > li {
                  $colors: $success-color, $secondary-color, $primary-color,
                    $danger-color, $info-color, $warning-color, $light-color;
                  $repeat: 100;
                  @for $i from 1 through $repeat {
                    &:nth-child(#{length($colors)}n + #{$i}) {
                      svg {
                        color: lighten(
                          nth($colors, random(length($colors))),
                          15%
                        ) !important;
                      }
                      a {
                        &:hover {
                          > span {
                            color: lighten(
                              nth($colors, random(length($colors))),
                              15%
                            );
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.light-sidebar {
              .main-navbar {
                .nav-menu {
                  > li {
                    $colors: $success-color, $secondary-color, $primary-color,
                      $danger-color, $info-color, $warning-color, $dark-color;
                    $repeat: 100;
                    @for $i from 1 through $repeat {
                      &:nth-child(#{length($colors)}n + #{$i}) {
                        svg {
                          color: darken(
                            nth($colors, random(length($colors))),
                            15%
                          );
                        }
                        a {
                          &:hover {
                            span {
                              color: darken(
                                nth($colors, random(length($colors))),
                                15%
                              );
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.sidebar-pattern1,
          &.sidebar-pattern2,
          &.sidebar-pattern3,
          &.sidebar-pattern4,
          &.sidebar-pattern5,
          &.sidebar-pattern6 {
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $dark-card-background;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $dark-card-background;
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: var(--theme-default);
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $dark-card-background;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: var(--theme-default);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
          &.sidebar-img1,
          &.sidebar-img2,
          &.sidebar-img3,
          &.sidebar-img4,
          &.sidebar-img5,
          &.sidebar-img6 {
            .nav-menu {
              > li {
                .nav-submenu {
                  li {
                    a {
                      &:after {
                        background: $dark-card-background;
                      }
                    }
                  }
                }
                .nav-link {
                  &.active {
                    color: $white;
                  }
                }
                > a {
                  color: $dark-all-font-color;
                }
              }
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: $dark-card-background;
                }
              }
              .dropdown {
                ul.nav-submenu {
                  li {
                    a {
                      color: $dark-all-font-color;
                      &.active {
                        color: var(--theme-default);
                      }
                    }
                  }
                }
              }
            }
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
            .main-navbar {
              .nav-menu {
                & > li {
                  .nav-submenu {
                    &:after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &::after {
                              background-color: $dark-card-background;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: var(--theme-default);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  a {
                    svg {
                      color: $dark-all-font-color !important;
                    }
                    &.active {
                      svg {
                        color: $white !important;
                      }
                    }
                  }
                }
              }
            }
            .according-menu {
              i {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
  }
}
//Dark layout
body {
  &.light-only {
    .page-wrapper.horizontal-wrapper {
      .page-body-wrapper {
        .page-body {
          background-color: $light;
          ul.the-icons {
            li {
              color: $black;
              display: inline-block;
              padding: 10px;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
                color: $white;
              }
              em {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  &.dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .social-review-sec .review-details li:nth-child(2),
    .goal-overview-sec .goal-details li:nth-child(n + 2) {
      border-left-color: $dark-card-border;
    }
    .reactour__helper {
      color: $theme-body-font-color;
    }
    .dzu-previewButton {
      filter: brightness(0) invert(1);
    }
    .dzu-previewContainer {
      border-bottom: 1px solid $dark-card-border;
    }
    .draggable-card {
      .react-kanban-board {
        color: $theme-body-font-color;
      }
    }
    .statistics-sec {
      .card-footer {
        border-top-color: $dark-card-border;
      }
    }
    .goal-overview-sec {
      .card-footer {
        border-top-color: $dark-card-border;
      }
    }
    .comingsoon {
      .list-group-item {
        background-color: transparent;
      }
      .comingsoon-inner {
        .countdown {
          ul {
            li {
              background: transparent;
            }
          }
        }
      }
    }
    .grid-showcase {
      span {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
      }
    }
    ol.progtrckr {
      li {
        &.progtrckr-done {
          color: $dark-small-font-color;
        }
        &.progtrckr-doing {
          color: $dark-all-font-color;
        }
      }
    } 
    // goggle chart
    #reactgooglegraph-10 {
      svg {
        g {
          rect {
            &:first-child {
              fill: $dark-card-background;
            }
          }
        }
      }
    }
    #reactgooglegraph-11 {
      svg {
        g {
          rect {
            &:nth-child(odd) {
              fill: $dark-card-background;
            }
            &:nth-child(even) {
              fill: $dark-body-background;
            }
          }
        }
      }
    }
    #reactgooglegraph-15 {
      svg {
        g {
          rect {
            &:first-child {
              fill: $dark-card-background;
            }
          }
        }
      }
    }
    // react timeline
    .vertical-timeline-element-content {
      background-color: $dark-body-background;
      .vertical-timeline-element-content-arrow {
        border-right-color: $dark-body-background;
      }
    }
    .vertical-timeline {
      &::before {
        background: $dark-body-background;
      }
    }
    .vertical-timeline-element-icon {
        box-shadow: 0 0 0 4px $dark-body-background, inset 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 4px rgb(0 0 0 / 5%);
    }
    /* react select 2 css */
    .js-example-basic-single {
      > div {
        background: $dark-body-background;
        border-color: $dark-body-background;
        div[data-value], > div > div {
          color: $dark-all-font-color;
        }
        div[role="button"] {
          color: $theme-body-font-color;
        }
      }
    }
    /* react calender css start */
    .rbc-toolbar {
      button {
        color:  $dark-all-font-color;
        &:hover {
          color: $theme-body-font-color;
        }
      }
    }
    .rbc-month-view, .rbc-time-view {
      border-color: $dark-card-border;
    }
    .rbc-day-bg {
      + .rbc-day-bg {
        border-color: $dark-card-border;
      }
    }
    .rbc-month-row {
      + .rbc-month-row {
        border-top-color: $dark-card-border;
      }
    }
    .rbc-off-range-bg {
      background: $dark-body-background;
    }
    .rbc-header {
      border-bottom-color: $dark-card-border;
      + .rbc-header {
        border-left-color: $dark-card-border;
      }
    }
    .rbc-timeslot-group {
      border-bottom-color: $dark-card-border;
    }
    .rbc-time-content > * + * > *, .rbc-time-header-content {
      border-left-color: $dark-card-border;
    }
    .rbc-day-slot {
      .rbc-time-slot {
        border-top-color: $dark-card-border;
      }
    }
    .rbc-time-content {
      border-top-color: $dark-card-border;
    }
    .rbc-today {
      background-color: rgba($primary-color, 0.2);
    }
    #external-events {
      border-color: $dark-card-border;
    }
    .fc-theme-standard {
      .fc-scrollgrid {
        border-color: $dark-card-border;
      }
    }
    /* react calender css end */
    .modal {
      .form-control {
        background-color: $dark-body-background;
        border-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .form-select {
        color: $dark-all-font-color;
      }
    }
    .bookmark-tabcontent {
      .tab-content {
        .card {
          .card-header {
            border-bottom-color: $dark-card-border;
          }
        }
      }
    }
    .page-main-header{
      .main-header-right{
        .nav-right{
          .onhover-show-div{
            li{
              .media{
                a{
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .dashboard-chat{
      .chat{
        .media{
          .media-body{
            .message-main{
              p{
                color: $dark-small-font-color
              }
            }
          }
        }
      }
    }
    .latest-update-sec{
      .update-block{
        table{
          tbody{
            tr{
              td{
                p{
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .media {
          .media-body {
            .message-main {
              p {
                background-color: $dark-body-background;
                &:before {
                  border-right-color: $dark-body-background;
                }
              }
            }
          }
        }
      }
    }
    .feature-products{
      .featured-sec{
        .select-options{
          .btn-square{
            background-color: $dark-card-background !important;
            border: 1px solid $dark-card-border !important;
          }
        }
      }
    } 
    .modal-content{
      .btn-close{
        filter: brightness(0) invert(1);
      }
    } 
    .product-box.modal{
      .modal-header{
        .product-details{
          .product-view{
            border-top: 1px solid $dark-card-border;
            border-bottom: 1px solid $dark-card-border;
          }
          .product-qnty{
            .input-group{
              .touchspin.text-center{
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
              }
            }
          }
          .product-size{
            ul{
              li{
                border: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
    .calender-widget{
      .cal-date{
        h5{
          color: $dark-all-font-color;
        }
      }
      .cal-desc{
        h6{
          color: $dark-all-font-color;
        }
      }
    }
    .product-page-main{
      .pro-group{
        table{
          td{
            border-top: unset !important;
          }
        }
      }
    }
    .page-main-header{
      .main-header-right{
        .nav-right{
          ul{
            li{
              a.txt-dark{
                h6{
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .product-page-main{
      .border-tab.nav-tabs{
        .nav-item{
          .nav-link{
            &.active{
              background-color: rgba($primary-color, 0.1);
            }
          }
        }
      }
    }
    .options-main .option-box {
      border-color: $dark-card-border;
    }
    .manage-invoice table tbody tr td span,
    .manage-invoice table tbody tr td p {
      color: $dark-all-font-color;
    }
    .perform-overview-sec{
      .card{
        border: unset !important;
        .card-body {
          background-color: $dark-body-background;
        }
      }
    }  
    .project-calendar .card .card-header {
      border-color: $dark-card-border;
    }
    .email-wrap .email-body .email-compose .cke_contents.cke_reset {
      border: 1px solid $dark-card-background;
      background-color: $dark-card-background;
    }
    .social-list .media .media-body span,
    .social-status .media .media-body h6 {
      color: $dark-all-font-color;
    }
    .list-persons .profile-mail .email-general {
      border-top: 1px solid $dark-card-border;
    }
    .page-main-header{
      .main-header-right{
        .nav-right{
          .profile-dropdown{
            li{
              &:hover{
                svg{
                  stroke: $primary-color !important;
                }
                a{
                  span{
                    color: $primary-color;
                  }
                }
              }
              a{
                span{
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .user-profile {
      .follow {
        ul {
          &.follow-list {
            border-top: 1px solid $dark-card-border;
            li:nth-child(n + 2) {
              border-left: 1px solid $dark-card-border;
            }
          }
        }
      }
      .profile-header {
        .userpro-box {
          background-color: $dark-card-background;
          h4 {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .bookmark-wrap {
      .left-bookmark {
        ul {
          li {
            a {
              > .title {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .edit-profile {
      .profile-title {
        border-bottom: 1px solid $dark-card-border;
      }
    }
    .custom-card {
      .profile-details {
        border-bottom-color: $dark-card-border;
        h4 {
          a{
            color: $dark-all-font-color;
          }
        }
      }
    }
    .table-success {
      background-color: lighten($success-color, 18%);
    }
    .daterangepicker:before {
      border-bottom-color: $light-all-font-color;
    }
    .breadcrumb-item {
      color: $dark-all-font-color;
    }
    .blog-box {
      .blog-details-second {
        .detail-footer {
          border-top-color: $dark-card-border;
          ul.social-list li:nth-child(n + 2) {
            border-left-color: $dark-card-border;
          }
        }
        h6 {
          color: $dark-all-font-color;
        }
      }
      .blog-details {
        .blog-social {
          li {
            background: transparent;
          }
        }
      }
    }
    .customizer-links {
      background-color: $dark-card-border;
      border: 1px solid $dark-card-border;
    }
    .border-top {
      border-top: 1px solid $dark-card-background !important;
    }
    #menu {
      .btn {
        &:hover {
          background-color: $dark-card-background;
        }
      }
      .dropdown {
        .btn-default {
          color: $dark-all-font-color;
          border-color: $dark-card-border;
        }
      }
      .move-today {
        border-color: $dark-card-border;
      }
      .move-day {
        border-color: $dark-card-border;
      }
    }
    .btn {
      color: $dark-all-font-color;
    }
    .rdt_Pagination{
      background-color: $dark-card-background;
      color: $dark-all-font-color;
      svg{
        fill: $dark-all-font-color;
      }
    }
    .rdt_Table{
      .rdt_TableBody{
        .rdt_TableRow{
          border-bottom: 1px solid $dark-card-border;
          color: $dark-all-font-color;
          &:last-child{
            border-bottom: unset;
          }
          &:nth-child(odd){
            .rdt_TableCell{
              background-color: $dark-body-background;
            }
          }
          &:nth-child(even){
            .rdt_TableCell{
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .rdt_Table{
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;
      color: $dark-all-font-color;
      .rdt_TableHeadRow{
        background-color: $dark-card-background;
        color: $dark-all-font-color;
        border-bottom: 1px solid $dark-card-border;
        .rdt_TableCol{
          color: $dark-all-font-color;
          .rdt_TableCol_Sortable{
            &:hover{
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .order-history{
      div.rdt_TableHeader{
        + div{
          &:nth-child(2){
            border: 1px solid $dark-card-border !important;
          }
        }
      }
      .rdt_Table{
        .rdt_TableBody{
          .rdt_TableCell{
            .product-name{
              a{
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .invoice{
      .invo-profile{
        .media {
          border-bottom-color: $dark-card-border;
        }
        .invo-profile-left{
          .media{
            .media-body{
              h4{
                a{
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .invo-header{
      .media-left{
        img{
          filter: brightness(0) invert(1);
        }
      }
    }
    .prooduct-details-box{
      .price{
        span{
          color: $dark-small-font-color;
        }
      }
      .product-name{
        h6{
          a{
            color: $dark-all-font-color;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        .order-box{
          ul{
            li{
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    // chat
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-msg-box {
            .other-message {
              background-color: $dark-card-background;
              border-color: $dark-card-border;
            }
          }
        }
      }
      .chat-menu {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
        .user-profile {
          .follow {
            border-color: $dark-card-border;
            .follow-num {
              color: $dark-all-font-color;
            }
          }
          .image {
            .icon-wrapper {
              background-color: $dark-body-background;
              box-shadow: none;
            }
          }
          .digits {
            border-color: $dark-card-border;
          }
        }
      }
    }
    .social-status {
      form {
        .form-group {
          .form-control-social {
            background-color: $dark-card-background;
            border-color: $dark-card-border;
          }
        }
      }
    }
    .chat-box {
      .about {
        .name {
          color: $dark-all-font-color;
        }
      }
      .chat-menu {
        border-left: 1px solid $dark-card-border;
        .nav-tabs {
          border-bottom: 1px solid $dark-card-border;
          .nav-item {
            .nav-link {
              &.active {
                color: $dark-all-font-color !important;
              }
            }
          }
        }
      }
      .status {
        color: $dark-small-font-color;
        p {
          color: $dark-all-font-color !important;
        }
      }
      .chat-right-aside {
        .chat {
          .chat-header {
            border-bottom: 1px solid $dark-card-border;
            .chat-menu-icons {
              li {
                a {
                  i {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
            img {
              box-shadow: 1px 1px 4px 1px $dark-body-background;
            }
          }
          .chat-msg-box {
            .my-message {
              border: 1px solid $dark-card-border;
            }
            .message {
              color: $dark-all-font-color;
            }
            .other-message {
              background-color: $dark-body-background;
            }
          }
          .chat-message {
            background-color: $dark-card-background;
            .input-group {
              .form-control {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
      .chat-history {
        .call-icons {
          ul {
            li {
              border: 1px solid $dark-card-border;
              a {
                color: $dark-small-font-color;
              }
            }
          }
        }
      }
    }
    .help-sec{
      .help-desk{
        .btn{
          background-color: $white !important;
          border: 1px solid $white !important;
        }
      }
    }
    .top-sell-sec{
      .top-sell-table{
        table{
          tbody{
            tr{
              td{
                .t-title{
                  .d-inline-block{
                    span{
                      a{
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .connect-sec{
      table{
        tr{
          td{
            border-top: unset !important;
          }
        }
      }
    }
    .our-activity-table{
      .groups-table{
        tbody{
          tr{
            td{
              .media-body{
                span{
                  a{
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    .github-chart{
      .echarts-for-react{
        > div{
          + div{
            background-color: $dark-card-background !important;
            color: $dark-all-font-color !important;
            border-color: $dark-card-border !important;
          }
        }
      }
    }
    .ecommerce-dash{
      .center-content{
        .center-content-right{
          border-left: 1px solid $dark-card-border;
        }
      }
    }  
    .payment-sec-main{
      .visa-card{
        background-color: $dark-card-background;
      }
    } 
    .table-borderless{
      tbody{
        td{
          border-top: unset !important;
        }
      }
    }
    .apexcharts-canvas{
      svg{
        g{
          line{
            stroke: $dark-body-background;
          }
        }
      }
    }
    .react-datepicker{
      background-color: $dark-body-background;
      color: $dark-small-font-color;
      .react-datepicker__day--outside-month{
        color: $light-gray;
      }
      .react-datepicker__day{
        color: $dark-all-font-color;
        &:hover{
          background-color: $dark-card-background;
        }
      }
      .react-datepicker__header{
        border-bottom: 1px solid $dark-card-border;
      }
      .react-datepicker__time-container {
        border-left-color: $dark-card-border;
        .react-datepicker__time {
          background: $dark-body-background;
          .react-datepicker__time-box {
            ul {
              &.react-datepicker__time-list {
                li {
                  &.react-datepicker__time-list-item {
                    &:hover {
                      background-color: $dark-card-background;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .jkanban-container{
      .kanban-container{
        .kanban-item{
          ul{
            li{
              background-color: $dark-body-background;
            }
          }
          .kanban-box{
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .customers{
      ul{
        li{
          img{
            border: 2px solid $dark-card-border;
          }
        }
      }
    }
    //index page
    .best-dealer-table {
      table {
        tbody {
          tr {
            td {
              .t-title{
                .d-inline-block{
                  span{
                    a{
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              .label {
                color: $dark-all-font-color;
              }
            }
            &:hover {
              td {
                span {
                  color: var(--theme-default);
                }
              }
            }
          }
        }
      }
    }
    .default-sec,
    .ecommerce-dash {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      span,
      p,
      td,
      th {
        color: $dark-all-font-color;
      }
    }
    .greeting-user {
      .btn {
        color: var(--theme-default);
        background-color: $white !important;
        border-color: $white !important;
      }
    }
    .default-sec,
    .ecommerce-dash {
      .card {
        .card-header {
          .center-content {
            .center-content-left {
              border-color: $dark-card-border;
            }
          }
        }
      }
    }
    .cal-datepicker{
      .react-datepicker{
        background-color: $dark-body-background;
        .react-datepicker__day{
          color: $dark-all-font-color;
        }
        .react-datepicker__header{
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .social-review-sec {
      .reviewer-profile {
        border-color: $dark-card-border;
      }
      .review-details {
        .left-review {
          border-color: $dark-card-border;
        }
      }
    }
    .goal-overview-sec {
      .goal-details {
        border-color: $dark-card-border;
        .complete-goal {
          border-color: $dark-card-border;
        }
      }
    }
    .project-goal-sec{
      .apexcharts-canvas{
        .apexcharts-plot-series{
          polygon{
            &:nth-child(even){
              fill: $dark-card-background;
              stroke: $dark-card-border;
            }
            &:nth-child(odd){
              fill: $dark-body-background;
              stroke: $dark-card-border;
            }
          }
        }
        svg{
          g{
            line{
              stroke: $transparent-color;
            }
          }
        }
      }
    }
    .crm-activity{
      ul.dates{
        li{
          + li{
            border-left: 1px solid $dark-card-border;
          }
        }
      }
      ul{
        li.media{
          + li{
            border-top: 1px solid $dark-card-border !important;
          }
        }
      }
    }
    .project-calendar{
      .react-datepicker{
        background-color: $dark-card-background;
        .react-datepicker__day{
          color: $dark-all-font-color;
        }
        .react-datepicker__header{
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .help-sec {
      .ask-question-btn {
        .btn {
          background-color: $white !important;
          border-color: $white !important;
        }
      }
    }
    // index page css end
    .perform-overview-sec {
      .widget-card {
        background-color: $dark-body-background;
        li{
          background-color: $dark-body-background;
        }
      }
    }
    .project-dash{
      .center-content{
        .center-content-right{
          border-left: 1px solid $dark-card-border;
        }
      }
    }  
    .payment-sec-main {
      #sync1 {
        .item {
          background: $dark-card-background;
        }
      }
    }
    .slick-next,
    .slick-prev {
      &:before {
        color: $dark-all-font-color;
      }
    }
    .connect-sec {
      table {
        tr {
          td {
            &:nth-child(n + 2) {
              border-color: $dark-card-border;
            }
          }
        }
      }
    }
    .table-responsive{
      table{
        thead{
          tr{
            border-bottom: 2px solid $dark-card-border;
          }
        }
      }
    }
    .user-status{
      table{
        tbody{
          tr{
            td{
              a{
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    } table tbody tr td a
    .custom-card{
      ul.card-social{
        border-bottom: unset;
      }
    }
    .new-product-main {
      .slider {
        .slick-prev,
        .slick-next {
          &:hover,
          &:focus {
            &:before {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .task-details{
      table{
        tbody{
          tr{
            td{
              span{
                a{
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    .b-r-light{
      border-right: 1px solid $dark-card-border !important;
    }
    .apexcharts-legend-text{
      color: $dark-all-font-color !important;
    }
    .apexcharts-title-text, .apexcharts-subtitle-text{
      fill: $dark-small-font-color;
    }
    .small-chart-widget.chart-widgets-small{
      #chart-widget8{
        .apexcharts-canvas{
          svg{
            g{
              line{
                stroke: $transparent-color;
              }
            }
          }
        }
      }
    }
    .apexcharts-xaxistooltip, .apexcharts-yaxistooltip{
      color: $dark-all-font-color;
      background: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .page-main-header{
      .main-header-right{
        .nav-right{
          .chat-dropdown{
            li{
              .media{
                .media-body{
                  a{
                    span{
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
          .notification-dropdown{
            ul{
              li{
                p{
                  a{
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    // dashboard-03 css start
    .congrats-sec {
      .congrats-content {
        .btn-light {
          background-color: $white !important;
          border-color: $white !important;
        }
      }
    }
    .btn-light {
      color: var(--theme-default);
    }
    .default-sec,
    .ecommerce-dash {
      .card {
        .card-header {
          border-color: $dark-card-border;
        }
      }
    }
    // project page
    .project-box {
      background-color: $dark-body-background;
      border-color: $dark-card-border;
      h6 {
        color: $dark-all-font-color;
      }
      p {
        color: $dark-small-font-color;
      }
      .modal {
        .modal-header {
          .product-box {
            .product-details {
              .product-size {
                ul {
                  li {
                    button {
                      background-color: transparent !important;
                    }
                    &:hover {
                      button {
                        background-color: var(--theme-default) !important;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .form-select {
      background-color: $dark-body-background;
      border-color: $dark-border-color;
      background-blend-mode: exclusion;
    }
    // email application
    .email-wrap {
      .email-right-aside {
        .email-body {
          .inbox {
            .media {
              &:hover {
                background-color: $dark-body-background;
              }
              .radio_animated,
              .checkbox_animated {
                &:after {
                  background-color: $dark-body-background !important;
                }
              }
              &:hover {
                .radio_animated,
                .checkbox_animated {
                  &:after {
                    background-color: $dark-card-background !important;
                  }
                }
              }
            }
          }
        }
      }
      .email-wrapper {
        .dzu-previewContainer{
          border-bottom: 1px solid $dark-card-border;
        }
        .dzu-previewButton{
          filter: brightness(0) invert(1);
        }
        .emailread-group {
          &:nth-child(n + 2) {
            border-color: $dark-card-border;
          }
        }
      }
      .email-top {
        border-color: $dark-card-border;
      }
    }
    .chat-box{
      .chat-right-aside{
        .chat{
          .chat-header{
            .chat-menu-icons{
              li{
                a{
                  svg{
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
        .chat-history.chat-msg-box{
          ul{
            li{
              &:hover{
                z-index: 0;
              }
            }
          }
        }
      }
      .people-list{
        ul{
          li.active{
            background-color: $transparent-color !important;
          }
        }
      }
      .chat-menu{
        .user-profile{
          .user-content{
            h5{
              a{
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    // file manager
    .form-control-plaintext {
      color: $dark-all-font-color;
    }
    .file-content {
      .form-inline {
        border-color: $dark-card-border;
        background-color: $dark-body-background;
      }
      .files {
        .file-box {
          background-color: $dark-body-background;
          border-color: $dark-body-background;
          .file-top {
            background-color: $dark-card-background;
            border-color: $dark-card-border;
          }
        }
      }
      .folder {
        .folder-box {
          background-color: $dark-body-background;
          border-color: $dark-body-background;
        }
      }
    }
    .file-sidebar {
      .pricing-plan {
        border-color: $dark-card-border;
        h6 {
          color: $dark-small-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    hr {
      background-color: $dark-card-border;
    }
    // kanban
    .kanban-board-header {
      background-color: $dark-body-background;
      border-color: $dark-card-border;
    }
    .kanban-item {
      .kanban-box {
        background-color: $dark-body-background;
        border-color: $dark-card-border;
        .date {
          color: $dark-small-font-color;
        }
      }
      .list {
        li {
          i {
            color: $dark-small-font-color;
          }
        }
      }
    }
    // ecommerce page
    .prooduct-details-box {
      .media {
        border-color: $dark-card-border;
      }
      .product-name {
        h6 {
          color: $dark-all-font-color;
        }
      }
    }
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      border-color: $dark-card-border;
    }
    .order-history {
      table {
        .qty-box {
          .input-group {
            border-color: $dark-card-border;
          }
        }
      }
    }
    .pricingtable {
      border-color: $dark-card-border;
      .pricing-content {
        .pricing-list {
          li {
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .pricing-simple {
      border-color: $dark-card-border;
      .pricing-content {
        h3 {
          color: $dark-all-font-color;
          &:before {
            background-color: $dark-card-border;
          }
        }
      }
    }
    .cke_chrome {
      border-color: $dark-card-border;
    }
    .cke_inner {
      background-color: $dark-card-background;
    }
    .project-list {
      .border-tab {
        &.nav-tabs {
          border-bottom: none !important;
          .nav-item {
            .nav-link {
              border: none;
            }
          }
        }
      }
    }
    .feature-products {
      form {
        > div {
          input {
            background-color: $dark-card-background !important;
          }
        }
      }
    }
    .product-page-main {
      .pro-group {
        &:nth-child(n + 2) {
          border-color: $dark-card-border;
        }
      }
    }
    .product-social {
      li {
        a {
          border-color: $dark-card-border;
        }
      }
    }
    .invoice {
      .invo-header {
        border-color: $dark-card-border;
      }
    }
    //form page css
    .input-group-text {
      background-color: $dark-card-background;
      color: $dark-all-font-color;
      border-color: $dark-body-background;
    }
    .form-check-input {
      background-color: $dark-body-background;
    }
    .datepicker--pointer {
      background-color: $dark-card-border;
      border-color: $dark-card-border;
    }
    .popover.top > .arrow:after {
      border-color: $dark-card-border;
    }
    .clockpicker-popover {
      .popover-title {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
      }
    }
    .clockpicker-plate {
      background-color: $dark-card-background;
      border-color: $dark-card-border;
    }
    .clockpicker-popover {
      .popover-content {
        background-color: $dark-card-background;
      }
    }
    .daterangepicker {
      &:after {
        border-bottom-color: $dark-card-background;
      }
      th {
        border: none;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border-color: $dark-card-border;
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
      &.ltr {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        tr {
          td {
            border: none;
            &.in-range,
            &.off {
              background-color: $dark-body-background;
              color: $dark-all-font-color;
              &:hover {
                background-color: var(--theme-default);
              }
            }
          }
        }
      }
      .input-mini {
        background-color: $dark-body-background;
        border: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .calendar-table {
        background-color: $dark-card-background;
        border-color: $dark-border-color;
      }
      th,
      td {
        &:hover {
          color: var(--theme-default);
        }
      }
    }
    .ranges {
      li {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
      }
    }
    .dropdown-basic {
      .dropdown {
        .dropdown-content {
          .dropdown-header {
            color: $dark-all-font-color;
          }
          a {
            background-color: $dark-card-background;
            border-color: $dark-card-border;
          }
        }
        .dropbtn {
          background-color: $dark-body-background !important;
          color: $dark-all-font-color !important;
        }
      }
    }
    // form wizard
    .f1 {
      .f1-steps {
        .f1-progress {
          background-color: $dark-card-border;
        }
        .f1-step {
          .f1-step-icon {
            background-color: $dark-body-background;
          }
          &.active {
            .f1-step-icon {
              background-color: var(--theme-default);
            }
          }
        }
      }
    }
    // tables
    .dropdown-menu {
      .dropdown-item {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
      }
    }
    .dataTables_wrapper {
      .dataTables_paginate {
        .paginate_button {
          border-color: $dark-card-border;
        }
      }
      .dataTables_filter {
        input {
          outline: none;
        }
      }
    }
    //breadcrumb
    .breadcrumb-item {
      + .breadcrumb-item {
        &:before {
          color: $dark-all-font-color;
        }
      }
      &.active {
        color: $dark-all-font-color;
      }
    }
    // img cropper
    .img-cropper {
      .docs-options {
        .dropdown-menu {
          .form-check-label {
            border-color: $dark-card-border;
            color: $dark-all-font-color;
          }
        }
      }
    }
    // timeline
    .cd-timeline-content {
      &::before {
        border-left-color: $dark-card-border;
      }
    }
    .cd-timeline-block {
      &:nth-child(even) {
        .cd-timeline-content {
          &:before {
            border-right-color: $dark-card-border;
          }
        }
      }
    }
    .btn-light {
      background-color: $dark-card-border !important;
      border-color: $dark-card-border !important;
    }
    .cke_wysiwyg_frame,
    .cke_wysiwyg_div {
      background-color: $dark-card-background;
    }
    // cke_editable
    .cke_editable {
      background-color: $dark-card-background !important;
    }
    // knowledgebase
    .knowledgebase-search {
      h3 {
        color: $theme-body-font-color;
      }
      .form-control-plaintext {
        color: $theme-body-font-color;
      }
      .form-inline {
        background-color: $dark-body-background;
        border: none;
      }
    }
    // theme customizer
    .customizer-links {
      .nav-link {
        background-color: $dark-card-background;
        &.active {
          background-color: rgba($primary-color, 0.5);
        }
      }
    }
    .customizer-contain {
      .customizer-body {
        .dashboard-type {
          .slick-prev,
          .slick-next {
            &:before {
              color: $theme-body-font-color;
            }
          }
          .dashboard-box {
            .title-wrraper {
              h3 {
                color: $theme-body-font-color;
              }
            }
          }
        }
      }
      .form-select {
        background-color: $white;
        border-color: $light-gray;
      }
    }
    //contacts css start
    .contacts-tabs {
      .nav-pills {
        border-color: $dark-card-border;
        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-card-border;
          }
        }
      }
    }
    .list-persons {
      .profile-mail {
        .media {
          .media-body {
            ul {
              li {
                + li {
                  border-color: $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .modal-header {
      .btn-close {
        filter: invert(1);
      }
    }
    .modal-header,
    .modal-footer,
    .modal-content {
      border-color: $dark-card-border;
    }
    //search page
    .search-list {
      .nav-item {
        .nav-link {
          background-color: rgba($primary-color, 0.1);
          color: $white;
          &.active {
            background-color: rgba($primary-color, 0.9);
          }
        }
      }
    }
    .search-page {
      .info-block {
        border-color: $dark-card-border;
      }
    }
    // ui kits
    .popover-body {
      background-color: $dark-card-background;
      color: $dark-all-font-color;
      border-color: $dark-card-border;
    }
    .popover-header {
      border-color: $dark-card-border;
    }
    .bs-popover-end > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
      border-right-color: $dark-card-border;
    }
    .bs-popover-start > .popover-arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
      border-left-color: $dark-card-border;
    }
    .dropdown-basic {
      .dropdown {
        .dropbtn {
          box-shadow: none;
        }
      }
    }
    // tabs
    .nav-tabs {
      .nav-link {
        border-color: $dark-card-border !important;
        color: $dark-all-font-color;
      }
    }
    // pagination
    .page-link {
      background-color: $dark-card-background;
      border-color: $dark-card-border;
    }
    // Edit profile css start
    .add-project {
      .text-inherit {
        color: var(--theme-default);
      }
    }
    //product page css
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
                border-color: $dark-card-border;
              }
            }
          }
        }
      }
    }
    // .ProfileCard{
    //   &:hover{
    //     background: rgba($primary-color, 0.1);
    //   }
    // }
    .ProfileCard-realName{
      a{
        color: $dark-all-font-color;
      }
    }
    //general widget-section css start
    .ecommerce-widget {
      border: 1px solid $dark-card-border !important;
      h6 {
        color: $dark-all-font-color;
      }
    }
    //general widget-section css End
    .page-main-header {
      .main-header-right {
        .nav-right {
          .chat-dropdown,
          .profile-dropdown {
            li {
              &:last-child {
                border-top: 1px solid $dark-card-border !important;
              }
            }
          }
        }
      }
    }
    .page-wrapper.horizontal-wrapper {
      .page-body-wrapper {
        &.horizontal-menu {
          header {
            &.main-nav {
              background-color: $dark-card-background;
              border-color: $dark-card-border;
              .main-navbar {
                .nav-menu {
                  > li {
                    > a {
                      color: $dark-all-font-color;
                    }
                    .nav-submenu {
                      background: $dark-card-background;
                      li {
                        a {
                          background-image: gray;
                          color: $dark-all-font-color;
                          &.active,
                          &:hover {
                            color: var(--theme-default);
                          }
                        }
                        .nav-sub-childmenu {
                          background: $dark-card-background;
                        }
                      }
                    }
                    .mega-menu-container {
                      background: $dark-card-background;
                      .mega-box {
                        .link-section {
                          .submenu-content {
                            li {
                              a {
                                color: $dark-all-font-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .left-arrow,
                .right-arrow {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
      }
    }
    $alert-name: primary, secondary, success, danger, warning, info, light, dark;
    $alert-color: $primary-color, $secondary-color, $success-color,
      $danger-color, $warning-color, $info-color, $light-color, $dark-color;
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var} {
        background-color: rgba(nth($alert-color, $i), 0.8) !important;
        border-color: rgba(nth($alert-color, $i), 0.9) !important;
        color: $white;

        .progress {
          height: 5px;
          background-color: darken(nth($alert-color, $i), 1%);
          border-radius: 0;
        }
        .progress-bar {
          background-color: lighten(nth($alert-color, $i), 50%);
        }
      }
      .alert-#{$var}.inverse {
        background-color: $transparent-color !important;
      }
    }
    .alert-light {
      color: $dark-card-border !important;
    }
    .options {
      > div {
        border: 1px solid $dark-card-border !important;
      }
    }
    .was-validated {
      .custom-control-input {
        &:invalid {
          ~ .custom-control-label {
            &::before {
              background-color: $dark-body-background;
            }
          }
        }
      }
      .form-select:valid:not([multiple]):not([size]) {
        background-repeat: no-repeat;
      }
    }
    .pricing-block {
      .pricing-simple{
        box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
      }
    }
    .dropdown-menu{
      .dropdown-item{
        color: $dark-all-font-color;
      }
    }
    .editor-preview {
      background: $dark-card-background;
    }
    .editor-preview-side {
      border-color: $dark-card-border;
    }
    .editor-toolbar, .editor-toolbar.fullscreen {
      border-top-color: $dark-card-border;
      border-left-color: $dark-card-border;
      border-right-color: $dark-card-border;
      background-color: $dark-card-background;
      button {
        color: $dark-all-font-color;
        &.active, &:hover {
          background: $dark-body-background;
          border-color: $dark-body-background;
        }
      }
      i.separator {
        border-left: 1px solid $dark-card-border;
        border-right: 1px solid $dark-card-background;
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .email-wrap{
      .cke_chrome{
        .cke_top{
          background-color: $profilecard;
        }
      }
    }
    .features-faq {
      .card-footer {
        border-top-color: $dark-card-border;
      }
    }
    .job-search {
      .job-description {
        border-top-color: $dark-card-border;
        .theme-form {
          border-bottom-color: $dark-card-border;
          .row {
            div[class^="col-"] {
              .input-group {
                .datepicker-here {
                  border: 1px solid $dark-body-background;
                }
              }
            }
          }
        }
      }
    }
    .product-page-main{
      .border-tab.nav-tabs{
        border-bottom: unset !important;
        .nav-item{
          .nav-link{
            border-bottom: unset;
          }
        }
      }  
    }
    .mega-inline,
    .mega-horizontal,
    .mega-vertical {
      .card {
        box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
      }
      &.plain-style,
      &.border-style,
      &.offer-style {
        .card {
          box-shadow: none !important;
        }
      }
      &.border-style {
        .card {
          border: 1px solid $dark-card-border !important;
        }
      }
      &.offer-style {
        .card {
          border: 1px dashed $dark-card-border !important;
        }
      }
    }
    .setting-dot {
      .setting-bg {
        background-color: var(--theme-secondary);
      }
    }
    .bookmark.pull-right {
      border: none;
    }
    .popover {
      code {
        background-color: unset;
      }
    }
    .Typeahead-menu {
      background-color: $dark-body-background;
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .bookmark {
      ul {
        li {
          background-color: $dark-card-background;
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-border-color;
              }
            }
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
        .animate-chk {
          .radio_animated {
            &:after {
              border: 2px solid $dark-card-background;
            }
          }
        }
      }
    }
    .order-box {
      .title-box {
        color: $custom-theme;
        border-bottom: 1px solid $dark-card-border;
      }
      .qty {
        li {
          color: $custom-theme;
          span {
            color: $custom-theme;
          }
        }
        border-bottom: 1px solid $dark-card-border;
      }
      .sub-total {
        li {
          color: $custom-theme;
        }
      }
      .total {
        li {
          color: $custom-theme;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-card-background;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
      .product-details {
        h4 {
          color: $dark-all-font-color;
        }
      }
      .modal {
        .modal-header {
          .product-box {
            .product-details {
              .product-view {
                border-color: $dark-card-border;
              }
            }
          }
        }
      }
    }
    .apexcharts-datalabels-group{
      text{
        fill: $dark-all-font-color;
      }
    }
    .review-details{
      li{
        + li{
          border-left: 1px solid $dark-card-border !important;
        }
      }
    }
    .profile-sec{
      .user-details-main{
        .user-detail{
          span{
            color: $dark-all-font-color;
          }
        }
      }
    }
    .product-list{
      table{
        tr{
          th{
            border-bottom: none !important;
            border-top: none !important;
          }
          td{
            border-top: none !important;
          }
        }
      }
    }
   .apexcharts-canvas{
     svg{
      .apexcharts-yaxis, .apexcharts-xaxis{
        .apexcharts-yaxis-texts-g, .apexcharts-xaxis-texts-g{
          .apexcharts-yaxis-label, .apexcharts-xaxis-label{
            fill: $dark-all-font-color;
          }
        }
      }
     }
   }
    .dashboard-chat{
      .chat{
        .right-side-chat{
          .media-body{
            p{
              &::before{
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .page-main-header{
      .main-header-right{
        .nav-right{
          .bookmark-flip{
            .front, .back{
              background-color: $dark-card-background;
            }
          }
          .bookmark-dropdown{
            input.form-control{
              color: $dark-all-font-color;
            }
            li{
              h6{
                border-bottom: 1px solid $dark-card-border !important;
              }
            }
          }
          .language-dropdown{
            li{
              + li{
                border-top: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
    .notification-dropdown.cart{
      ul{
        li{
          .media{
            .media-body{
              h6{
                input{
                  border: 1px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .goal-overview-sec{
      .goal-details{
        li{
          &:nth-child(n+2){
            border-left: 1px solid $dark-card-border;
          }
        }
      }
    }
    .apexcharts-text{
        fill: $dark-all-font-color;
    }
    .apexcharts-tooltip.apexcharts-theme-light{
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
      .apexcharts-tooltip-title{
        background: $dark-card-background;
        border: 1px solid $dark-card-border;
        color: $primary-color;
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-card-border;
      border-bottom: 1px solid $dark-card-border;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .page-main-header {
      .main-header-right {
        .nav-right {
          .bookmark-flip {
            .flip-card{
              .flip-card-inner{
                .front{
                  h6{
                    border-bottom: 1px solid $dark-card-border !important;
                  }
                }
              }
            }
            .bookmark-dropdown.flip-back-content{
              input.form-control{
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
              }
            }
            .bookmark-dropdown {
              .bookmark-content {
                .bookmark-icon {
                  background:$dark-body-background;
                  &:hover{
                    background: rgba($primary-color, 0.1);
                    svg{
                      stroke: $primary-color !important;
                    }
                  }
                  svg{
                    stroke: $dark-all-font-color !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          header {
            &.main-nav {
              background-color: $dark-card-background;
              border-right: 1px solid $dark-card-border;
              .nav-menu {
                > li {
                  .nav-submenu {
                    li {
                      a {
                        &:after {
                          background: $dark-small-font-color;
                        }
                      }
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &:after {
                              background-color: $dark-card-background;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .nav-link {
                    &.active {
                      color: var(--theme-default);
                    }
                  }
                  > a {
                    color: $dark-all-font-color;
                  }
                }
                &.custom-scrollbar {
                  &::-webkit-scrollbar-thumb {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown {
                  ul.nav-submenu {
                    li {
                      a {
                        color: $dark-all-font-color;
                        &.active {
                          color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
              }
              .logo-wrapper {
                background-color: $dark-card-background;
                img {
                  filter: brightness(0) invert(1);
                }
              }
              .main-navbar {
                .nav-menu {
                  color: $dark-body-background;
                  > li {
                    .nav-submenu {
                      &:after {
                        background-color: $light-all-font-color;
                      }
                    }
                    a {
                      svg {
                        color: $dark-all-font-color !important;
                      }
                    }
                  }
                }
              }
              .logo-icon-wrapper {
                background-color: $dark-card-background;
              }
            }
          }
          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
          .page-body {
            background-color: $dark-body-background;
          }
        }
      }
      &.compact-sidebar {
        &.compact-wrapper {
          .page-body-wrapper {
            header {
              &.main-nav {
                .main-navbar {
                  .nav-menu {
                    > li {
                      border-color: $dark-card-border;
                      .nav-link {
                        &.active {
                          background-color: $dark-card-background;
                        }
                        &:hover {
                          background-color: $dark-card-background;
                        }
                      }
                      .nav-submenu {
                        background-color: $dark-card-background;
                      }
                      a {
                        svg {
                          background-color: $dark-card-border;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .page-wrapper.horizontal-wrapper,
    .compact-wrapper {
      /* Main Header start */
      .page-main-header {
        background-color: $dark-body-background;
        .main-header-right {
          background-color: $dark-card-background;
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            > ul {
              > li {
                svg {
                  stroke: $white !important;
                }
              }
            }
            &.right-menu {
              > ul {
                .cart {
                  ul li div h6.qty input {
                    background-color: $dark-card-background;
                  }
                }
                > li {
                  .profile-media {
                    .media-body {
                      p {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                  p {
                    color: $dark-all-font-color;
                  }
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                  .bg-light {
                    background-color: $theme-font-color !important;
                    color: $dark-all-font-color !important;
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: var(--theme-default);
                    svg {
                      line,
                      path,
                      circle {
                        color: var(--theme-default) !important;
                      }
                    }
                  }
                }
              }
            }
            > ul {
              > li {
                .media {
                  .media-body {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
            .chat-dropdown {
              li {
                border-bottom: 1px solid $dark-card-border;
                color: $white;
              }
            }
          }
          .left-menu-header {
            ul {
              li {
                .search-form {
                  border-left-color: $dark-card-inbox;
                  input {
                    &::placeholder {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
        .main-header-left {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .main-header-right {
            .nav-right {
              > ul {
                background-color: $dark-card-background;
                box-shadow: 0 2px 2px 2px $dark-border-color;
                border-color: $dark-card-border;
              }
            }
          }
          .main-header-left {
            .logo-wrapper {
              img {
                filter: brightness(0) invert(1);
              }
            }
          }
        }
      }
      /* Main Header ends */
      .page-body-wrapper {
        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .media-body,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .media {
              .media-body {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .media {
              .media-body {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-card-border;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .media {
              .media-body {
                .circle-left {
                  border: 5px solid $theme-font-color;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .media {
              .media-body {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .media {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .media-body {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb {
            li {
              color: $dark-all-font-color;
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
            border: 1px solid $dark-card-border;
            .setting-option {
              background-color: $dark-card-background;
            }
            .chart-block {
              #bar-chart2 {
                svg {
                  > rect {
                    fill: $dark-card-background;
                  }
                  > g {
                    text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .card-header {
              background-color: $transparent-color;
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                background-color: $dark-card-background;
                i {
                  color: $dark-all-font-color;
                  &.fa-cog {
                    color: var(--theme-default);
                  }
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              > :not(:first-child) {
                border-top-color: $dark-card-border;
              }
              th,
              td {
                color: $dark-all-font-color;
                border-top: 1px solid $dark-card-border;
              }
              thead {
                th {
                  border-bottom: 1px solid $dark-card-border;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid var(--theme-default);
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $dark-card-background;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              thead {
                &.table-primary {
                 th {
                  color: var(--theme-default);
                 }
                }
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid var(--theme-default);
                  }
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-card-border !important;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
            }
            .switch {
              .switch-state {
                background-color: $dark-body-background;
              }
              input {
                &:checked {
                  + .switch-state {
                    background-color: var(--theme-default);
                  }
                }
              }
            }
            .bg-white {
              background-color: $white !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-border-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-color: $dark-card-border;
              background-color: $dark-card-border;
            }
            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-card-border;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-card-border;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
              border: 1px solid $dark-card-border;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }
            .border-right {
              border-color: $dark-card-border !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#gantt_chart {
                svg {
                  g {
                    rect {
                      &:first-child {
                        fill: $dark-body-background;
                      }
                    }
                  }
                }
                rect {
                  &:nth-child(6) {
                    fill: $dark-body-background;
                  }
                }
              }
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-card-border;
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-card-border;
            }
            .page-link {
              border: 1px solid $dark-card-border;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              color: $dark-all-font-color;
              background-color: $dark-card-background;
            }
            .page-item {
              &:hover {
                .page-link {
                  background-color: $dark-body-background;
                }
              }
            }
            .page-item.active {
              .page-link {
                background-color: $dark-body-background;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .border-tab.nav-tabs {
              border-bottom: 1px solid $dark-card-border;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: var(--theme-default);
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-card-border;
                  &.br-active,
                  &.br-selected {
                    background-color: var(--theme-default);
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-card-border;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                  &.br-active,
                  &.br-selected {
                    border: 2px solid var(--theme-default);
                    color: var(--theme-default);
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &:after {
                    color: $dark-card-border;
                  }
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: var(--theme-default);
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-card-border;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-card-background;
              border-color: $dark-card-border;
            }
            .breadcrumb {
              &.bg-white {
                background-color: $dark-card-background !important;
              }
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
          }
          .default-according {
            .card {
              border: none;
            }
          }
          .form-builder-header-1,
          .form-builder-2-header {
            background-color: $dark-body-background;
          }
          .form-builder {
            .nav-primary {
              .show {
                > .nav-link {
                  color: $white;
                }
              }
              .nav-link {
                color: $dark-all-font-color;
                &.active {
                  color: $white;
                }
              }
              .nav-pills.nav-primary {
                .show {
                  > .nav-link {
                    color: $white;
                  }
                }
                .nav-link {
                  color: $dark-all-font-color;
                  &.active {
                    color: $white;
                  }
                }
              }
            }
            .drag-box {
              fieldset {
                border: 1px solid $dark-card-border;
              }
            }
            .help-block {
              color: $dark-small-font-color;
            }
          }
          #viewhtml {
            .render {
              background-color: $dark-card-background;
              color: $dark-all-font-color;
              border-color: $dark-border-color;
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border-color: $dark-card-border;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .jsgrid-grid-header {
            background-color: $dark-card-background;
            border-color: $dark-card-border;
          }
          .jsgrid-header-row,
          .jsgrid-filter-row {
            > .jsgrid-header-cell,
            > .jsgrid-cell {
              background: $dark-card-background;
              border: 1px solid $dark-card-border;
              input {
                background-color: $dark-body-background;
                border-color: $dark-card-border;
                color: $dark-all-font-color;
              }
            }
            select {
              background-color: $dark-body-background;
              border-color: $dark-card-border;
              color: $dark-all-font-color;
            }
          }
          .jsgrid-row {
            > .jsgrid-cell {
              background-color: $dark-card-background;
            }
          }
          .jsgrid-alt-row {
            > .jsgrid-cell {
              background-color: $dark-body-background;
            }
          }
          .jsgrid-cell,
          .jsgrid-grid-body {
            border: 1px solid $dark-card-border;
          }
          .jsgrid-selected-row {
            > .jsgrid-cell {
              background-color: $dark-datatable-sorting;
            }
          }
          .jsgrid {
            .jsgrid-pager {
              [class*="jsgrid-pager"] {
                border: 1px solid $dark-card-border;
              }
              .jsgrid-pager-page {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .jsgrid-pager-current-page {
            color: $dark-all-font-color;
            font-weight: 700;
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: var(--theme-default);
                }
              }
            }
            .separator {
              border-bottom: 1px solid $dark-card-border;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: var(--theme-default) !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: var(--theme-default);
                  a {
                    color: var(--theme-default);
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 14px 0 $dark-body-background;
              .navs-icon {
                li {
                  p {
                    color: $white;
                  }
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: var(--theme-default) !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-all-font-color;
                }
              }
            }
          }
          .default-according {
            .card {
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
              .card-body,
              .card-header {
                border: 1px solid $dark-card-border;
              }
            }
          }
          .border {
            border: 1px solid $dark-card-border !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-color: $dark-card-border;
                  &:first-child {
                    border-color: $dark-card-border;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-color: $dark-card-border;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .media {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-card-border;
              }
            }
            h4 {
              border-bottom-color: $dark-card-border;
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
              }
            }
            hr {
              border-color: $dark-card-border;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-card-border;
              color: $sidebar-submenu-font-color;
              display: inline-block;
              padding: 10px;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
              em {
                display: none;
              }
            }
          }
          .button-builder-wrap {
            .box {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
            }
            .button-preview {
              h2 {
                color: $dark-all-font-color;
              }
            }
            pre.well {
              background-color: $dark-card-background !important;
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-card-border;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-card-border;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color;
          }
          .note {
            textarea.form-control {
              background: transparent;
              border: none;
            }
          }
          .select2-drpdwn {
            @each $form-name, $select-color in 
            (primary, var(--theme-default)),
            (secondary, var(--theme-secondary)), 
            (success, $success-color),
            (danger, $danger-color), 
            (info, $info-color), 
            (light, $light-color),
            (inverse, $dark-color), 
            (warning, $warning-color)
            {
              .form-control-#{$form-name} {
                border-color: $select-color;
                color: $select-color;
              }
            }
            @each $fill-name, $select-color in 
            (primary, var(--theme-default)),
            (secondary, var(--theme-secondary)), 
            (success, $success-color),
            (danger, $danger-color), 
            (info, $info-color), 
            (light, $light-color),
            (inverse, $dark-color), 
            (warning, $warning-color)
            {
              .form-control-#{$fill-name}-fill {
                background-color: $select-color;
                color: $white;
              }
            }
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .browser-widget {
            .media-body {
              column-rule: 1px solid $dark-card-border;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                i {
                  color: var(--theme-default);
                }
              }
            }
          }
          .widget-joins {
            .media {
              .media-body {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-card-border;
              }
            }
            &:before,
            &:after {
              background-color: $dark-card-border;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-card-border !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-card-border;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-color: $dark-card-border;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
        }
        footer {
          p {
            color: $dark-all-font-color;
          }
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
          &.blockquote-footer {
            border-top: none;
          }
        }
        .form-select {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .note {
        textarea {
          color: $dark-all-font-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;
        h2 {
          background-color: $dark-card-background;
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: none;
        color: $dark-all-font-color;
        &.active {
          background-color: var(--theme-default) !important;
          border-color: var(--theme-default);
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .page-main-header  {
        .list-group-item {
          background: transparent;
        }
      }
      .breadcrumb {
        .list-group-item {
          &.active {
            background-color: transparent !important;
          }
        }
      }
      .page-list {
        .list-group-item {
          border: 1px solid $dark-card-border;
          + .list-group-item {
            border-top-width: 0;
          }
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      $item-primary: #004085;
      $item-secondary: #383d41;
      $item-success: #155724;
      $item-danger: #721c24;
      $item-warning: #856404;
      $item-info: #0c5460;
      $item-light: #818182;
      $item-dark: #1b1e21;
      @each $dark-color, $dark-type in (primary, $item-primary), (secondary, $item-secondary),
        (success, $item-success), (danger, $item-danger), (warning, $item-warning), (info, $item-info),
        (light, $item-light), (dark, $item-dark)
      {
        .list-group-item-#{$dark-color} {
          color: $dark-type;
          background-color: rgba($dark-type, 0.1);
        }
      }
      .customers {
        ul {
          .list-group-item {
            background: transparent;
          }
        }
      }

      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
        .modal-header {
          border-bottom: 1px solid $dark-card-border;
          .close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-card-border;
        }
      }
     
      .comingsoon {
        background-blend-mode: overlay;
        background-color: $dark-body-background !important;
        .list-group-item {
          background-color: transparent;
        }
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-card-border;
            border-bottom: 1px solid $dark-card-border;
            .title {
              color: $dark-all-font-color;
            } 
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .search-page {
        ul {
          &.search-info {
            >li {
              + li {
                border-left: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
      .authentication-main {
        background-color: $dark-card-border;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: var(--theme-default);
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: var(--theme-default);
            border: 1px solid var(--theme-default);
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-card-border;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: $dark-card-background;
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .sm {
          background-color: $dark-card-background;
          border: 1px solid $dark-border-color;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
        .chat-menu {
          border-top: 1px solid $dark-card-border;
          background-color: $dark-card-background;
        }
      }
      @media only screen and (max-width: 767px) {
        .congrats-sec {
          .setting-option.bg-transparent {
            background-color: $dark-card-background !important;
          }
        }
        .feature-products {
          .filter-toggle {
            background-color: $dark-card-background;
          }
        }
        .chat-box {
          .people-list {
            ul {
              li {
                border-color: $dark-card-border !important;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 575px) {
        .goal-overview-sec {
          .goal-details {
            .expected-goal {
              border-color: $dark-card-border;
            }
          }
        }
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
        .page-main-header {
          .main-header-right {
            .left-menu-header {
              ul {
                li {
                  .search-form {
                    .form-control-plaintext {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-border-color;
        border: 1px dotted $dark-border-color;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-border-color;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background-color: $dark-card-background;
      border-color: $dark-card-border;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .u-step {
      background-color: $dark-card-border;
      &.active,
      &.current {
        background: var(--theme-default);
        color: $white;
      }
    }
    .u-step.disabled .u-step-title,
    .u-step.disabled p {
      color: $dark-all-font-color;
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-card-border;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: $theme-font-color;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-card-border;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .nav-tabs {
      border-bottom-color: $dark-card-border;
      .nav-link {
        &.active {
          background-color: rgba($primary-color, 0.1);
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color $dark-card-border;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            border-color: $dark-card-border;
            &.active {
              border-color: var(--theme-default);
            }
          }
        }
      }
    }
    .border-tab.nav-left,
    .border-tab.nav-right {
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          color: var(--theme-default);
        }
      }
      .show {
        > .nav-link {
          color: var(--theme-default);
        }
      }
    }
    .border-tab.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: var(--theme-secondary);
          color: var(--theme-secondary) !important;
        }
      }
      .show > .nav-link {
        border-left-color: var(--theme-secondary);
        color: var(--theme-default) !important;
      }
      .nav-item.show {
        color: var(--theme-secondary) !important;
        border-left-color: var(--theme-secondary);
      }
    }
    .border-tab.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .border-tab.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .border-tab.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: var(--theme-secondary) !important;
        }
      }
      .nav-item {
        &.show {
          color: var(--theme-secondary) !important;
        }
      }
    }
    .border-tab.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: $dark-body-background;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-card-border;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-card-border;
      border-bottom: 1px solid $dark-card-border;
      &::before {
        background-color: $dark-card-border;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &:before {
                color: $dark-all-font-color;
              }
              &:after {
                background-color: $dark-card-background;
                border-color: $dark-card-border;
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-color: $dark-card-border;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: var(--theme-default);
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border-color: $dark-card-border;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
      .post-about {
        ul {
          li {
            h5 {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .profile-post {
        .post-header {
          .media {
             h5 {
               color: $dark-all-font-color;
             }
          }
        }
        .post-body {
          .post-react {
            h6 {
              color: $dark-small-font-color;
            }
            ul {
              li {
                background: transparent;
              }
            }
          }
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .note {
      textarea {
        color: $dark-all-font-color;
      }
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-border-color;
      }
    }
    pre {
      background-color: $dark-body-background;
    }
    .error-wrapper {
      background-color: rgba(54, 64, 74, 0.8);
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-card-border;
        .close {
          color: $dark-all-font-color;
          font-weight: 400;
        }
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-card-border;
        }
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
        color: $light-all-font-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border-color: $dark-card-border !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              a {
                color: $white;
              }
              &:hover {
                color: $white;
                background-color: var(--theme-default);
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        background-color: $dark-card-background;
        h6 {
          color: $dark-all-font-color;
        }
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              a {
                color: rgba($primary-color, 0.6);
                h5 {
                  color: rgba($primary-color, 0.6);
                }
              }
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    .basic-calendar {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
        &.badge {
          color: $black;
        }
      }
    }
    .social-status {
      .media {
        .media-body {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .media-body {
        border: 1px solid $dark-card-border;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
  }
  &.dark-sidebar {
    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          header {
            &.main-nav {
              background-color: $dark-card-background;
              .nav-menu {
                > li {
                  .nav-link{
                    &.active{
                      span{
                        color: $primary-color;
                      }
                    }
                  }
                  .nav-submenu {
                    li {
                      a {
                        &:after {
                          background: $dark-small-font-color;
                        }
                      }
                    }
                  }
                  .mega-menu-container {
                    .mega-box {
                      .link-section {
                        .submenu-title {
                          h5 {
                            color: $dark-all-font-color;
                            &:after {
                              background-color: $dark-card-background;
                            }
                          }
                        }
                        .submenu-content {
                          &.opensubmegamenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                              }
                            }
                          }
                        }
                      }
                    }
                    &::after {
                      background-color: $light-all-font-color;
                    }
                  }
                  .nav-link {
                    &.active {
                      color: $white;
                    }
                  }
                  > a {
                    color: $dark-all-font-color;
                  }
                }
                &.custom-scrollbar {
                  &::-webkit-scrollbar-thumb {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown {
                  ul.nav-submenu {
                    li {
                      a {
                        color: $dark-all-font-color;
                        &.active {
                          color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
              }
              .logo-wrapper {
                background-color: $dark-card-background;
                img {
                  filter: brightness(0) invert(1);
                }
              }
              .main-navbar {
                .nav-menu {
                  > li {
                    .nav-submenu {
                      &:after {
                        background-color: $light-all-font-color;
                      }
                    }
                    a {
                      svg {
                        color: $dark-all-font-color !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
      &.horizontal-wrapper {
        .page-body-wrapper {
          &.horizontal-menu {
            header {
              &.main-nav {
                background: $dark-card-background;
                .main-navbar {
                  .nav-menu {
                    > li {
                      > a {
                        color: $dark-all-font-color;
                      }
                      .nav-submenu {
                        background: $dark-card-background;
                        li {
                          a {
                            color: $dark-all-font-color;
                            &.active,
                            &:hover {
                              color: var(--theme-default);
                            }
                          }
                          .nav-sub-childmenu {
                            background: $dark-card-background;
                          }
                        }
                      }
                      .mega-menu-container {
                        background: $dark-card-background;
                        .mega-box {
                          .link-section {
                            .submenu-content {
                              li {
                                a {
                                  color: $dark-all-font-color;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .left-arrow,
                  .right-arrow {
                    background-color: $dark-card-background;
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
/**=====================
    72. theme layout CSS ends
==========================**/