/**=====================
    49. Dashboard 3 CSS Start
==========================**/
.congrats-sec {
  @include back-img(url("../../images/dashboard-3/Welcome.png"));
  padding: 50px;
  .congrats-content {
    width: 50%;
    h3 {
      font-size: 22px;
      color: $white;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      color: rgba($white, 0.5) !important;
      font-weight: 400;
      font-family: $font-roboto;
      margin-bottom: 20px;
    }
    .btn-light {
      color: var(--theme-default) !important;
      font-weight: 700;
      padding: 10px 20px;
    }
  }
  .badge {
    position: absolute;
    right: 5%;
    top: 12%;
    color: $white;
    background: $primary-color;
    padding: 11px;
  }
}
.cal-date-widget{
  .cal-datepicker{
    .react-datepicker{
      .react-datepicker__navigation-icon{
        top: 2px;
      }
    }
  }
}
// date picker
.react-datepicker__navigation {
  background-color: $primary-color;
  border-radius: 5px;
  color: $white;
}
.react-datepicker__day-name {
  color: $primary-color;
}
.react-datepicker__week , .react-datepicker__day{
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-datepicker__navigation-icon--next{
  &::before{
    top: 11px;
    left: -4px;
    border-color: $white;
  }
}
.react-datepicker__navigation-icon--previous {
  &::before {
    top: 11px;
    left: -5px;
    right: unset;
    border-color: $white;
  }
}
.chart-widget-top{
  .card-body{
    ~ div{
      > div {
        margin-left: -5px;
      }
    }
  }
}
.project-dash, .cal-datepicker{
  .react-datepicker__navigation-icon--next{
    &::before{
      top: 8px;
      left: -4px;
      border-color: $white;
    }
  }
  .react-datepicker__navigation-icon--previous {
    &::before {
      top: 8px;
      left: -5px;
      right: unset;
      border-color: $white;
    }
  }
}
.project-dash {
  .default-datepicker {
    padding: 20px 16px 0;
    .react-datepicker {
      width: 100%;
      box-shadow: none;
    }
  }
  .react-datepicker__navigation-icon--next, .react-datepicker__navigation-icon--previous{
    &::before{
      top: 11px;
    }
  }
  .react-datepicker__month-container {
    width: 100%;
    .react-datepicker__month {
      margin: 0;
    }
  }
  .react-datepicker__day-names{
    display: flex;
    justify-content: center;
    .react-datepicker__day-name{
      width: 100% !important;
    }
  }
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 100% !important;
    height: 42px !important;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
  .react-datepicker__day.react-datepicker__day--outside-month {
    color: $light-text;
  }
}
.manage-invoice-table {
  .manage-invoice {
    table {
      tbody {
        border-top: unset;
        tr {
          td {
            vertical-align: middle;
            img {
              margin-left: 15px;
              margin-right: 15px;
            }
            &:first-child {
              display: flex;
              align-items: center;

              .t-title {
                display: flex;
                align-items: center;
              }
            }
          }
          &:hover {
            td {
              color: var(--theme-default);
              span {
                color: var(--theme-default);
              }
              p {
                color: var(--theme-default) !important;
              }
            }
          }
        }
      }
      tr {
        th,
        td {
          min-width: 165px;
          &:first-child {
            padding-left: 0;
            min-width: 255px;
          }
          &:last-child {
            padding-right: 0;
          }
          &:nth-child(6),
          &:nth-child(7) {
            min-width: 100px;
          }
        }
        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.manage-invoice {
  table {
    tbody {
      tr {
        td {
          span {
            font-size: 14px;
            color: $theme-body-font-color;
          }
          p {
            color: $theme-body-font-color;
          }
          &:last-child {
            min-width: 100px;
          }
        }
      }
    }
    thead {
      tr {
        th {
          padding-top: 0;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
// calender
.project-calendar {
  .card {
    margin-bottom: 0;
    .card-header {
      border-bottom: 1px solid $light-semi-gray;
    }
    .card-body {
      padding: 0;
    }
  }
}
// employee sec
.employee-sec {
  .table {
    td {
      padding-left: 0;
      padding-right: 0;
      p {
        color: $light-text;
        font-size: 12px;
      }
    }
    tbody {
      tr {
        td {
          padding-top: 16px;
          padding-bottom: 17px;
          &:first-child {
            min-width: 330px;
          }
          &:last-child {
            text-align: right;
            min-width: 85px;
          }
        }
      }
    }
  }
  tr {
    .round-light {
      transition: all 0.5s ease;
      i {
        transition: all 0.5s ease;
      }
    }
    &:hover {
      .icon-primary {
        background-color: var(--theme-default);
        i {
          color: $white;
        }
      }
      .icon-secondary {
        background-color: var(--theme-secondary);
        i {
          color: $white;
        }
      }
      .icon-success {
        background-color: $success-color;
        i {
          color: $white;
        }
      }
      .icon-warning {
        background-color: $warning-color;
        i {
          color: $white;
        }
      }
    }
  }
}
// Today task section
.project-goal-sec {
  .card {
    .card-body {
      #radar-chart {
        .apexcharts-radar-series {
          line {
            display: none;
          }
        }
      }
    }
  }
}
.today-task-sec {
  .btn {
    padding: 5px 10px;
    display: flex;
    width: fit-content;
    margin-left: auto;
    &.light-primary {
      background-color: rgba($primary-color, 0.1);
      color: var(--theme-default);
    }
    &.light-secondary {
      background-color: rgba($secondary-color, 0.1);
      color: var(--theme-secondary);
    }
    &.light-success {
      background-color: rgba($success-color, 0.1);
      color: $success-color;
    }
    &.light-warning {
      background-color: rgba($warning-color, 0.1);
      color: $warning-color;
    }
    &.light-info {
      background-color: rgba($info-color, 0.1);
      color: $info-color;
    }
  }
  tr {
    &:hover {
      .btn {
        &.light-primary {
          background-color: var(--theme-default);
          color: $white;
        }
        &.light-secondary {
          background-color: var(--theme-secondary);
          color: $white;
        }
        &.light-success {
          background-color: $success-color;
          color: $white;
        }
        &.light-warning {
          background-color: $warning-color;
          color: $white;
        }
        &.light-info {
          background-color: $info-color;
          color: $white;
        }
      }
    }
    td {
      p {
        color: $light-text;
        font-size: 12px;
      }
    }
  }
}
.visitor-sec {
  .jvector-map-height {
    height: 320px;
  }
  .jvectormap-zoomin,
  .jvectormap-zoomout {
    display: none;
  }
}
// box layout Today task section,Project Goal Section css start
[main-theme-layout="box-layout"] {
  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        .page-body {
          .today-task-sec,
          .project-goal-sec {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
.task-details {
  table {
    tbody {
      tr {
        td {
          padding: 17px 10px;
          width: 90px;
          span{
            a{
              color: $theme-body-font-color;
            }
          }
          &:first-child {
            min-width: 245px;
            padding-left: 0;
          }
          &:nth-child(2) {
            min-width: 120px;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}
// Map sectiom
.show-value-top {
  margin-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 10px;
  justify-content: center;
  .counry-data {
    display: flex;
    align-items: center;
    font-size: 12px;
    &:nth-child(n + 2) {
      margin-left: 20px;
    }
  }
}
.round {
  margin-right: 10px;
  @include square(9px, 9px, 100%);
}
@media only screen and (max-width: 1750px) {
  .today-task-sec,
  .project-goal-sec {
    margin-top: unset;
  }
  .task-details {
    table {
      tbody {
        tr {
          td {
            padding: 12px 10px;
          }
        }
      }
    }
  }
  #myRadarGraph-1 {
    height: 290px;
  }
  .show-value-top {
    .counry-data {
      &:nth-child(n + 2) {
        margin-left: 10px;
      }
    }
  }
}
@media only screen and (max-width: 1660px) {
  .show-value-top {
    .counry-data {
      font-size: 10px;
      .round {
        margin-right: 5px;
      }
    }
  }
  .visitor-sec {
    .jvector-map-height {
      height: 260px;
    }
  }
  .employee-sec {
    .table {
      tbody {
        tr {
          td {
            padding-top: 13px;
            padding-bottom: 15px;
          }
          .round-light {
            width: 35px;
            height: 35px;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
    .card {
      .card-body {
        padding: 23px;
      }
    }
  }
}
@media screen and (max-width: 1500px) and (min-width: 1366px) {
  .congrats-sec {
    padding: 25px;
  }
  .ecommerce-dash,
  .project-dash {
    .card {
      .card-header {
        display: block !important;
      }
    }
    .show-value-top {
      padding-left: 15px;
      padding-right: 15px;
    }
    .date-picker-university {
      .datepicker-inline {
        .datepicker {
          .datepicker--nav {
            position: unset;
          }
        }
      }
    }
  }
  .employee-sec {
    .table {
      tbody {
        tr {
          td {
            min-width: 105px;
            &:first-child {
              min-width: 330px;
            }
          }
          .round-light {
            width: 35px;
            height: 35px;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  .annual-earning-sec {
    .ct-golden-section {
      height: 385px;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .product-sales {
    table {
      tr {
        td {
          &:first-child {
            min-width: 175px !important;
          }
        }
      }
    }
  }
  .congrats-sec {
    padding: 30px;
  }
}
@media only screen and (max-width: 1365px) {
  .project-calendar {
    .card {
      margin-bottom: 30px;
    }
  }
  .date-picker-university {
    .datepicker-inline {
      .datepicker {
        .datepicker--nav {
          padding-top: 15px;
        }
        .datepicker--content {
          padding-top: 10px;
          padding-bottom: 10px;
          .datepicker--days {
            .datepicker--cells {
              .datepicker--cell-day,
              .datepicker--cell-year {
                height: 36px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .congrats-sec {
    .congrats-content {
      width: 45%;
    }
  }
  .employee-sec {
    .table {
      tbody {
        tr {
          td {
            padding-top: 12px;
            padding-bottom: 13px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .date-picker-university {
    .datepicker-inline {
      .datepicker {
        .datepicker--nav {
          top: 2%;
        }
      }
    }
  }
  .project-calendar {
    .card {
      .card-body {
        padding: 0 !important;
      }
    }
  }
  .project-dash {
    .today-task-sec,
    .project-goal-sec {
      .card {
        .card-header {
          display: block;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .congrats-sec {
    padding: 20px;
    background-image: unset;
    .congrats-content {
      margin: 0 auto;
      text-align: center;
      width: 70%;
      h3 {
        color: $theme-body-font-color;
      }
      p {
        color: $light-text !important;
      }
      .btn-light {
        background-color: var(--theme-default) !important;
        border-color: var(--theme-default) !important;
        color: $white !important;
        padding: 7px 15px;
        i {
          color: $white !important;
        }
      }
    }
    .setting-option {
      &.bg-transparent {
        background-color: $white !important;
        i {
          color: var(--theme-default);
        }
      }
      .setting-badge {
        &.light-badge {
          background-color: rgba($primary-color, 0.2);
          i {
            color: var(--theme-default);
          }
        }
      }
    }
  }
  .show-value-top {
    .counry-data {
      &:nth-child(n + 2) {
        margin-left: 15px;
      }
    }
  }
  .employee-sec {
    .table {
      tbody {
        tr {
          td {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  .round-light {
    width: 35px;
    height: 35px;
  }
}
@media only screen and (max-width: 480px) {
  .order-chart{
    div{
      .apexcharts-subtitle-text{
        font-size: 14px;
      }
    }
  }
  .bar-chart-widget{
    .bottom-content{
      h4{
        font-size: 18px;
      }
    }
  }
  .show-value-top{
    margin-bottom: 16px;
  }
  .congrats-sec {
    .congrats-content {
      width: auto;
      h3 {
        font-size: 18px;
      }
      p {
        margin-bottom: 15px;
      }
    }
  }
}
@media only screen and (max-width: 451px) {
  .emp-details-table {
    table {
      tbody {
        tr {
          td {
            min-width: 280px;
          }
        }
      }
    }
  }
  .date-picker-university {
    .datepicker-inline {
      .datepicker {
        .datepicker--nav {
          position: unset;
        }
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .congrats-sec {
    .congrats-content {
      h3 {
        font-size: 16px;
        padding-left: 20px;
        padding-right: 20px;
      }
      .btn-light {
        padding: 5px 12px;
        font-size: 12px;
      }
    }
    .setting-option {
      top: 15px !important;
      right: 15px !important;
    }
  }
  .show-value-top {
    padding-left: 15px;
    padding-right: 15px;
    display: block !important;
    text-align: center;
  }
}
/**=====================
    49. Dashboard 3 CSS Ends
==========================**/