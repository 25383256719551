/**=====================
    58. base_inputs CSS start
==========================**/
.jkanban-container {
  footer {
    display: none;
  }
}
.kanban-container {
  display: contents;
}
.kanban-item {
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
  p {
    font-size: 13px;
    color: $light-text;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .list {
    display: inline-block;
    opacity: 0.4;
    li {
      display: inline-block;
      font-size: 12px;
      background: transparent;
      i {
        margin-right: 10px;
      }
    }
  }
  .customers {
    p {
      line-height: 2.2;
    }
  }
  .kanban-box {
    border: 1px solid $light-gray;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    display: block;
    color: inherit;
    background-color: $white;
    &:hover {
      color: inherit;
    }
    & + .kanban-box {
      margin-top: 20px;
    }
    .badge {
      font-weight: 400;
    }
    h6 {
      font-size: 15px;
      margin-top: 5px;
      font-weight: 500;
    }
    .dropdown {
      display: inline-block;
      right: 10px;
      position: absolute;
      top: 20px;
      .dropdown-item {
        font-size: 13px;
        font-weight: 500;
        padding: 5px 10px;
        text-align: left;
      }
      svg {
        height: 14px;
      }
    }
    .d-flex {
      justify-content: space-between;
    }
    .dropdown-toggle {
      &:after {
        content: none;
      }
    }
    .customers ul li + li {
      margin-left: -10px;
    }
    .date {
      font-size: 11px;
    }
  }
}
footer {
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 1199px) {
  .jkanban-container {
    .btn {
      margin-bottom: 10px;
      margin-left: 20px;
    }
    .kanban-btn {
      margin-bottom: -10px;
    }
  }
}
@media only screen and (max-width: 700px) {
  .jkanban-container {
    .btn {
      margin-left: unset;
    }
  }
}
@media only screen and (max-width: 480px) {
  .jkanban-container {
    .card {
      .card-body {
        padding: 15px !important;
      }
    }
    .btn {
      padding: 8px 13px;
    }
  }
  .kanban-container {
    .kanban-board-header {
      padding: 15px;
    }
    .kanban-drag {
      padding: 15px;
    }
  }
  .kanban-item {
    .list {
      li {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
/**=====================
    58. base_inputs CSS ends
==========================**/