/**========================
    56. Invoice CSS Start
=============================**/
.invoice {
  p {
    color: $light-text;
    text-transform: capitalize;
  }
  .invo-pal{
    input{
      width: 170px;
      float: right;
      border: none;
    }
  }
  .invo-header {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid $light-gray;
  }
  .table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          td{
              background-color: rgba($primary-color, 0.05);
            --bs-table-accent-bg: unset;
          }
        }
        h6 {
          text-transform: capitalize;
          color: var(--theme-default);
        }
      }
    }
  }
  .invo-profile {
    margin-bottom: 15px;
    .invo-profile-left {
      .media {
        .media-left {
          margin-right: 15px;
        }
        .media-body {
          h4 {
            font-size: 16px;
            a{
              color: $theme-body-font-color;
            }
          }
          p {
            span {
              display: block;
            }
          }
        }
      }
    }
    .invo-profile-right {
      text-align: right;
      h6 {
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .invoice {
    h4 {
      margin-bottom: 0px;
    }
    h3 {
      margin-bottom: 0px;
    }
    .invo-profile {
      img.media{
        border-bottom: 0;
        padding: 0;
        margin: 0;
      }
      .media {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $light-gray;
      }
    }
    .invo-profile {
      .invo-profile-right {
        text-align: left;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .invoice {
    .card {
      .card-body {
        padding: 20px;
      }
    }
    h4 {
      font-size: 20px;
    }
    h3 {
      font-size: 20px;
    }
    table {
      td {
        min-width: 150px;
        &:first-child {
          min-width: 280px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .invoice {
    .invo-pal {
      margin-top: 15px;
      text-align: left !important;
    }
  }
}
@media only screen and (max-width: 575px) {
  .invoice {
    h4 {
      font-size: 18px;
    }
    h3 {
      font-size: 15px;
    }
    p {
      font-size: 13px;
    }
    .invo-header {
      .media-body {
        text-align: right;
      }
    }
    .text-xs-center {
      margin-top: 20px;
      text-align: center !important;
    }
    .text-md-end {
      text-align: left;
    }
  }
}
@media only screen and (max-width: 420px) {
  .invoice {
    .invo-header {
      .media-left {
        img {
          width: 50% !important;
        }
      }
    }
    .invo-profile {
      .media {
        display: block;
        .media-body {
          margin-left: 0 !important;
          margin-top: 10px;
        }
      }
    }
  }
}
/**========================
    56. Invoice CSS ends
==========================**/