/**=====================
     65. Pricing CSS Start
==========================**/
.pricing-simple {
  border: 1px solid $light-gray;
  margin-bottom: 0;
  .pricing-content {
    text-align: center;
    padding: 30px;
    h3 {
      position: relative;
      margin-bottom: 38px;
      font-weight: 600;
      &:before {
        position: absolute;
        content: "";
        width: 80px;
        height: 3px;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: -10px;
        background-color: $light-semi-gray;
      }
    }
    h1 {
      font-size: 30px;
      margin-bottom: 30px;
      font-weight: 400;
      width: 100px;
      height: 100px;
      color:$primary-color;
      font-weight: 600;
      background-color: rgba($primary-color, 0.1);
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h6 {
      color: $light-text;
      font-weight: 600;
    }
  }
  .btn {
    display: block;
    font-size: 16px;
  }
}
.pricingtable {
  border: 1px solid $light-gray;
  text-align: center;
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
  .pricing-header {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: rgba($primary-color, 0.1);
    h3 {
      text-transform: uppercase;
      color:$primary-color;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .pricing-content {
    padding-top: 45px;
    padding-bottom: 45px;
    .price-value {
      color: $white;
      background-color:$primary-color;
      width: 130px;
      height: 130px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      border-top-left-radius: 20px;
      border-bottom-right-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 0;
        text-transform: capitalize;
        span {
          font-size: 16px;
          display: block;
        }
      }
    }
    .pricing-list {
      margin-bottom: 30px;
      display: inline-block;
      li {
        font-weight: 500;
        text-align: left;
        position: relative;
        padding-left: 30px;
        color: $light-text;
        font-size: 16px;
        &:nth-child(n + 2) {
          margin-top: 20px;
        }
        &:before {
          content: "\f00c";
          color:$primary-color;
          font-family: FontAwesome;
          text-align: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          top: -1px;
          left: 0;
        }
        span {
          color: $theme-body-font-color;
          margin-right: 10px;
        }
      }
    }
  }
}
.pricing-block {
  margin-bottom: -30px;
  > div {
    margin-bottom: 30px;
  }
}
@media (max-width: 1470px) {
  .pricing-block {
    > div {
      flex: 50%;
    }
  }
}
@media (max-width: 767px) {
  .pricing-block{
    > div{
      margin-bottom: 20px;
    }
  }
  .pricingtable {
    .pricing-header {
      padding-top: 15px;
      padding-bottom: 15px;
      h3 {
        font-size: 20px;
      }
    }
    .pricing-content {
      padding-top: 30px;
      padding-bottom: 30px;
      .price-value {
        width: 110px;
        height: 110px;
        margin-bottom: 20px;
        h4 {
          font-size: 26px;
          span {
            font-size: 14px;
          }
        }
      }
      .pricing-list {
        margin-bottom: 20px;
        li {
          &:nth-child(n + 2) {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .pricingtable {
    .pricing-content {
      .price-value {
        width: 90px;
        height: 90px;
        h4 {
          font-size: 24px;
          span {
            font-size: 12px;
          }
        }
      }
      .pricing-list {
        li {
          font-size: 14px;
        }
      }
      .btn {
        font-size: 16px;
      }
    }
  }
  .pricing-simple {
    .pricing-content {
      padding: 20px;
      h3 {
        font-size: 24px;
        margin-bottom: 30px;
      }
      h1 {
        font-size: 24px;
        width: 90px;
        height: 90px;
        margin-bottom: 20px;
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .pricing-block {
    .pricing-simple.card{
      margin-bottom: 0 !important;
    }
    > div {
      flex: 100%;
    }
  }
}
/**=====================
    65. Pricing CSS Ends
==========================**/