/**=====================
    35. RTL CSS Start
==========================**/
.rtl {
  .price-range {
    direction: ltr;
  }
  ul {
    padding-right: 0;
  }
  .f-right {
    float: left;
  }
  .knowledgebase-search {
    .form-inline {
      svg {
        right: 20px;
        left: unset;
      }
    }
  }
  .rbc-btn-group, .rbc-calendar, .custom-file, .react-timekeeper, .rang-slide,
  .apexcharts-canvas {
    direction: ltr;
  }
  .pagination {
    .page-item {
      &:first-child {
        .page-link {
          border-left: none;
        }
      }
    }
  }
  .dropdown-menu {
    text-align: right;
  }
  .ps-1 {
    padding-left: unset !important;
    padding-right: 0.25rem !important;
  }
  .form-check-inline {
    margin-right: unset;
    margin-left: 1rem;
  }
  .text-lg-start {
    text-align: right !important;
  }
  .page-wrapper {
    &.compact-wrapper {
      .page-body-wrapper {
        header {
          &.main-nav {
            position: fixed;
            border-bottom: none;
            z-index: 9;
            height: auto;
            line-height: inherit;
            background: $white;
            width: 280px;
            text-align: right;
            transition: 0.5s;
            border-right: unset;
            border-left: 1px solid $light-gray;
            &.close_icon {
              margin-right: 0;
              margin-left: unset;
              transform: translate(280px);
              .logo-icon-wrapper {
                text-align: left;
                img {
                  margin-left: 8px;
                }
              }
              ~ .page-body,
              ~ footer {
                margin-left: 0;
                margin-right: 0;
                transition: 0.5s;
              }
              .main-navbar {
                .nav-menu {
                  > li {
                    a {
                      svg {
                        float: left;
                        margin-left: 0;
                      }
                    }
                  }
                  .dropdown {
                    .menu-title {
                      &.active {
                        ~ .menu-content {
                          display: none !important;
                        }
                      }
                    }
                  }
                }
              }
              .according-menu {
                display: none;
              }
              &:hover {
                .main-navbar {
                  .nav-menu {
                    > li {
                      a {
                        svg {
                          margin-left: 13px;
                        }
                      }
                    }
                  }
                }
              }
            }
            &:hover {
              transform: translate(0px);
              .main-navbar {
                .nav-menu {
                  .dropdown {
                    .menu-title {
                      &.active {
                        ~ .menu-content {
                          display: block !important;
                        }
                      }
                    }
                  }
                  > li {
                    a {
                      svg {
                        float: none;
                      }
                    }
                  }
                }
              }
              .according-menu {
                display: block;
              }
            }
            .main-navbar {
              .nav-menu {
                > li {
                  .nav-submenu {
                    &:after {
                      right: 30px;
                      left: unset;
                    }
                    > li {
                      a {
                        padding-right: 45px !important;
                        padding-left: unset !important;
                        &:after {
                          right: 26px;
                          left: unset;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .page-body {
          margin-left: unset;
          margin-right: 280px;
        }
        .main-nav {
          text-align: right;
          .main-navbar {
            .nav-menu {
              > li {
                a {
                  svg {
                    margin-right: unset;
                    margin-left: 15px;
                  }
                }
              }
            }
          }
        }
        .according-menu {
          right: unset;
          left: 20px;
        }
      }
    }
  }
  .ps-2{
    padding-right: 0.5rem !important;
    padding-left: unset !important;
  }
  .best-dealer-table{
    table{
      tr{
        th,td{
          &:last-child{
            text-align: left;
          }
        }
      }
    }
  }
  .collection-filter-block{
    ul{
      li{
        .media{
          svg{
            margin-left: 15px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .business-growth-sec, .annual-earning-sec{
    .card{
      .card-body{
        direction: ltr;
      }
    }
  }
  .chat-box{
    .chat-menu{
      .user-profile{
        .user-content{
          .social-list{
            li{
              &:nth-child(n+2){
                margin-right: 5px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
    .chat-right-aside{
      .chat{
        .chat-message{
          .text-box{
            .input-txt-bx{
              border-radius: 0 4px 4px 0;
            }
          }
        }
      }
    }
  }  
  .order-history{
    table{
      tbody{
        tr{
          .input-group{
            a.btn{
              border-radius: 0 !important;
            }
          }
        }
      }
    }
    .rdt_Table{
      .rdt_TableBody{
        .rdt_TableCell{
          .order-process{
            padding-right: 12px;
            padding-left: unset;
            .order-process-circle{
              &::before{
                left: unset;
                right: -12px;
              }
            }
          }
        }
      }
    }
  }
  .invoice{
    .invo-pal{
      input{
        float: left;
      }
    }
  }
  .cke_toolgroup {
    a.cke_button {
      &:last-child {
        &:after {
          right: unset;
          left: -3px;
        }
      }
    }
    a.cke_button.cke_button_disabled {
      &:hover {
        &:last-child {
          &:after {
            right: unset;
            left: -3px;
          }
        }
      }
    }
  }  
  .email-wrapper{
    .cke_toolbar_break{
      clear: right;
    }
    .cke_chrome{
      .cke_toolbar{
        float: right;
      }
    }
  }
  //horizontal-sidebar
  .page-wrapper {
    &.horizontal-wrapper {
      .page-body-wrapper {
        .page-body {
          margin-right: 0;
        }
        footer {
          margin-right: 0;
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right {
        > ul {
          > li {
            margin-left: 5px;
            margin-right: 0;
            &:last-child {
              margin-left: 0;
            }
          }
        }
        &.right-menu {
          ul {
            li {
              .profile-media {
                .media-body {
                  margin-right: 15px;
                  margin-left: unset;
                }
              }
            }
            &.wishlist {
              li {
                > a {
                  left: 15px;
                  right: unset;
                }
              }
            }
            .cart {
              ul {
                li {
                  div {
                    h6 {
                      &.qty {
                        input {
                          margin-right: 15px;
                          margin-left: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .notification-dropdown {
          left: -20px !important;
          right: unset !important;
          i {
            right: 0;
            left: unset;
          }
          li {
            .media {
              img {
                right: 0;
                left: unset;
              }
              .media-body {
                padding-right: 40px;
                padding-left: unset;
              }
            }
          }
          &:before,
          &:after {
            left: 28px !important;
            right: unset !important;
          }
        }
      }
      .toggle-sidebar {
        margin-left: 2px;
        margin-right: unset;
      }
    }
  }
  // index
  .default-dash,
  .ecommerce-dash,
  .project-dash {
    .center-content {
      span {
        margin-right: 10px;
        margin-left: 0;
      }
      .center-content-right {
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid $light-gray;
        border-left: none;
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
  .card {
    .setting-option {
      left: 30px;
      right: unset;
      text-align: left;
    }
  }
  .latest-update-sec {
    .update-block {
      table {
        tbody {
          tr {
            td {
              &:last-child {
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
  .page-main-header{
    .main-header-right{
      .nav-right{
        .language-dropdown{
          li{
            a{
              i.flag-icon{
                margin-left: 10px;
                margin-right: unset;
              }
            }
          }
        }
      }
    }
  } 
  .social-review-sec {
    .social-review-content {
      .star-sec-main {
        .star-sec {
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
    .reviewer-profile {
      li {
        &:nth-child(n + 2) {
          margin-right: 10px;
          margin-left: unset;
        }
      }
    }
    .review-details {
      li {
        &:nth-child(2) {
          border-left: none;
          border-right: 1px solid $light-gray;
          margin-right: 30px;
          padding-right: 30px;
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
  }
  .dashboard-chat {
    .chat {
      .left-side-chat {
        .media-body {
          p {
            &:before {
              left: 100%;
              right: unset;
              border-left: 7px solid lighten($primary-light, 7%);
              border-right: 7px solid transparent;
            }
          }
        }
      }
      .right-side-chat {
        .media-body {
          p {
            &:before {
              right: 100%;
              left: unset;
              border-right: 7px solid lighten($primary-light, 7%);
              border-left: 7px solid transparent;
            }
          }
        }
      }
    }
  }
  .chat-footer {
    svg {
      transform: scale(-1);
      margin-right: 10px;
      margin-left: 0;
    }
    .imogi {
      margin-left: 10px;
      margin-right: 0;
    }
  }
  .file-content{
    .btn{
      svg{
        margin: 0 0 2px 2px;
      }
    }
    .ellips{
      right: unset;
      left: 30px;
    }
  } 
  .file-manager{
    .files,.folder{
      li{
        &:nth-child(n+2){
          margin-right: 10px;
          margin-left: unset;
        }
      }
    }
  } 
  .file-sidebar{
    .pricing-plan{
      .bg-img{
        left: -40px;
        right: unset;
        transform: rotate(45deg);
      }
    }
  }  
  .goal-overview-sec {
    .goal-details {
      li {
        &:nth-child(n + 2) {
          border-right: 1px solid $light-gray;
          border-left: unset;
        }
      }
    }
  }
  .best-dealer-table {
    table {
      tr {
        th,
        td {
          &:first-child {
            padding-left: 10px;
            padding-right: 0;
          }
          &:last-child {
            padding-right: 10px;
            padding-left: 0;
          }
        }
      }
    }
  }
  .payment-sec-main{
    .visa-card{
      .d-flex.justify-content-between{
        direction: rtl;
      }
      ul.card-number{
        direction: rtl;
        li{
          &:nth-child(n+2){
            margin-right: 10px;
            margin-left: unset;
          }
        }
      }
    }
    #sync2 {
      .item {
        .check-circle {
          right: unset;
          left: 16px;
        }
      }
    }
  }
  .slid-wrapper {
    left: 35px;
    right: unset;
  }
  .slick-prev:before{
    content: '\f104' !important;
  }
  .slick-next:before{
    content: '\f105' !important;
  }
  // dashboard 2
  .perform-overview-sec {
    .overview {
      .slick-prev {
        left: 0;
      }
      .slick-next {
        left: 40px;
      }
    }
    .media {
      direction: rtl;
    }
    .widget-card {
      li{
        ul{
          margin-left: unset;
          margin-right: 10px;
        }
      }
      .star-main {
        .rating-cionlist {
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }
  }
  .service-contact-table {
    table {
      tr {
        td {
          &:last-child {
            padding-left: unset;
            padding-right: 0.75rem;
            .gradient-round {
              margin-left: unset;
              margin-right: auto;
            }
          }
          &:first-child {
            padding-right: unset;
            padding-left: 0.75rem;
          }
        }
      }
    }
  }
  .top-sell-sec {
    .top-sell-table {
      table {
        tbody {
          tr {
            td {
              &:first-child {
                padding-left: 10px;
                padding-right: 0;
              }
              &:last-child {
                padding-right: 10px;
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  }
  .our-activity-table {
    .table {
      th,
      td {
        &:last-child {
          text-align: left;
        }
      }
    }
  }
  .payment-sec-main {
    .visa-card {
      .card-logo {
        img {
          margin-left: auto;
        }
      }
    }
  }
  .payment-sec-main {
    #sync2 {
      .item {
        img {
          margin-left: auto;
        }
      }
    }
  }
  // dashboard 3
  .project-calendar {
    .datepicker--nav-action {
      svg {
        transform: scaleX(-1);
      }
    }
  }
  .today-task-sec {
    .btn {
      margin-right: auto;
      margin-left: unset;
    }
  }
  .date-picker-university {
    .datepicker-inline {
      .datepicker {
        .datepicker--nav {
          left: 28%;
        }
      }
    }
  }
  .show-value-top {
    .counry-data {
      &:nth-child(n + 2) {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  .dzu-previewButton{
    margin: 0 10px 2px 0;
  }
  .employee-sec {
    .table {
      tbody {
        tr {
          td {
            &:last-child {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .task-details {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 10px;
              padding-right: 0;
            }
            &:last-child {
              padding-right: 10px;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  .manage-invoice-table {
    .manage-invoice {
      table {
        tr {
          th,
          td {
            &:first-child {
              padding-right: 0;
              padding-left: 10px;
            }
            &:last-child {
              padding-left: 0;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  // bookmark app css
  .slick-slider {
    direction: ltr;
  }
  .bookmark-tabcontent {
    .details-bookmark {
      .bookmark-card {
        &.card {
          .favourite-icon {
            text-align: center;
          }
          .title-bookmark {
            text-align: right;
          }
        }
      }
    }
  }
  .bookmark-wrap {
    .row {
      > div {
        + div {
          padding-left: 15px !important;
        }
      }
    }
  }
  .ms-4 {
    margin-right: 1.5rem !important;
  }
  .best-dealer-table {
    table {
      tbody {
        tr {
          td {
            .align-middle {
              .status-circle {
                right: 32px;
              }
            }
          }
        }
      }
    }
  }
  .social-review-sec {
    .reviewer-profile {
      img {
        + img {
          margin-right: 10px;
          margin-left: unset;
        }
      }
    }
  }
  .round-light {
    margin-left: 15px;
    margin-right: unset;
  }
  .new-product-section {
    .new-product-main {
      direction: ltr;
      .slider {
        .slick-next {
          right: 15px;
          left: unset;
        }
      }
    }
  }
  .round {
    margin-left: 5px;
    margin-right: unset;
  }
  .congrats-sec {
    .congrats-content {
      margin-right: auto;
    }
  }
  .connect-sec {
    table {
      tr {
        td {
          &:nth-child(n + 2) {
            border-left: none;
            border-right: 1px solid $light-color;
          }
        }
      }
    }
  }
  .redial-block {
    .meeting-details {
      .meeting-rate {
        span {
          margin-left: 5px;
          margin-right: unset;
        }
      }
    }
  }
  .donut-chart-widget{
    direction: ltr;
  }
  .chart-container{
    > div{
      direction: ltr;
    }
  }
  .our-activity-table {
    .table {
      th,
      td {
        &:first-child {
          padding-left: 0.75rem;
          padding-right: unset;
        }
        &:last-child {
          padding-left: unset;
          padding-right: 0.75rem;
        }
      }
    }
  }
  .bookmark {
    text-align: left;
  }
  // project page
  .project-list {
    .btn {
      float: left;
    }
    ul {
      li {
        svg {
          margin-left: 5px;
          margin-right: unset;
        }
      }
    }
  }
  .project-box {
    .badge {
      right: unset;
      left: 15px;
    }
  }
  .customers {
    ul {
      li {
        + li {
          margin-left: unset;
          margin-right: -15px;
        }
      }
    }
  }
  .page-main-header{
    .main-header-right{
      .nav-right{
        .language-dropdown {
          right: -110px;
          left: unset;
        }
      }
    }
  }   
  .project-list {
    .border-tab {
      &.nav-tabs {
        .nav-item {
          .nav-link {
            padding: 5px 0 5px 30px;
          }
        }
      }
    }
  }
  .form-select {
    background-position: left 0.75rem center;
    padding: 0.375rem 0.75rem 0.375rem 1.75rem;
  }
  ul.list-group{
    i{
      margin-left: unset;
    }
  }
  .project-cards{
    .tab-content{
      .project-box{
        .customers{
          li.d-inline-block{
            img.media{
              margin-left: 0;
            }
          }
        }
      }
    }
  }    
  // file manager
  .file-content {
    .form-inline {
      i {
        padding-right: unset;
        padding-left: 10px;
      }
    }
  }
  .file-sidebar {
    .btn {
      svg {
        margin-left: 8px;
        margin-right: unset;
      }
    }
  }
  .kanban-item {
    .kanban-box {
      .customers {
        ul {
          li {
            + li {
              margin-left: unset;
              margin-right: -10px;
            }
          }
        }
      }
    }
    .list {
      li {
        i {
          margin-right: unset;
          margin-left: 10px;
        }
      }
    }
  }
  // user profile
  .user-profile {
    .social-media {
      ul {
        &.user-list-social {
          li {
            &:nth-child(n + 2) {
              margin-right: 5px;
              margin-left: unset;
            }
          }
        }
      }
    }
    .follow {
      ul.follow-list {
        li {
          &:nth-child(n + 2) {
            margin-right: 15px;
            padding-right: 15px;
            padding-left: 0;
            margin-left: 0;
            border-right: 1px solid $light-gray;
            border-left: none;
          }
        }
      }
    }
    .post-about {
      ul {
        li {
          .icon {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    }
    .profile-post {
      .post-body {
        .post-react {
          ul {
            li {
              &:nth-child(n + 2) {
                margin-left: unset;
                margin-right: -20px;
              }
            }
          }
          h6 {
            margin-right: 15px;
            margin-left: unset;
          }
        }
        .post-comment {
          li {
            &:nth-child(n + 2) {
              margin-left: 0;
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
  .social-tab {
    ul {
      li {
        &:nth-child(n + 2) {
          margin-left: unset;
          margin-right: 15px;
        }
        a {
          svg {
            margin-right: unset;
            margin-left: 5px;
          }
        }
      }
    }
    .input-group {
      .form-control {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
      .input-group-text {
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
  // ecommerce page
  .prooduct-details-box {
    .btn {
      right: unset;
      left: 15px;
    }
    .close {
      right: unset;
      left: 10px;
    }
  }
  .feature-products {
    .featured-sec {
      text-align: left;
      .select-options {
        margin-right: 10px;
        margin-left: unset;
      }
    }
    form {
      > div {
        input {
          padding-right: 30px;
          padding-left: 15px;
        }
      }
    }
  }
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: unset;
    margin-right: -1px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .form-check {
    .form-check-input {
      float: right;
    }
  }
  .pricingtable {
    .price-value {
      border-radius: 50%;
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    .pricing-content {
      .pricing-list {
        li {
          padding-right: 30px;
          padding-left: unset;
          text-align: right;
          &:before {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
  .invoice {
    .invo-profile {
      .invo-profile-right {
        text-align: left;
      }
      .invo-profile-left {
        .media {
          .media-left {
            margin-left: 15px;
            margin-right: 0;
          }
        }
      }
    }
  }
  // chat page
  .call-chat-body {
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-history {
            .caller-img {
              &.ps-0 {
                padding-left: 15px !important;
              }
            }
          }
        }
      }
    }
  }
  .chat-box {
    .user-image {
      float: right;
      margin-left: 5px;
      margin-right: 0;
    }
    .people-list {
      .search {
        i {
          left: 10px;
          right: unset;
        }
      }
    }
    .about {
      float: right;
      padding-right: 10px;
      padding-left: unset;
      text-align: right;
    }
    .chat-menu {
      left: 10px;
      right: unset;
      .nav {
        padding-right: 0;
      }
      &.ps-0 {
        padding-left: 15px !important;
      }
    }
    .chat-right-aside {
      .chat {
        .chat-message {
          .smiley-box {
            margin-left: 0.5rem;
            margin-right: unset;
          }
        }
        .chat-header {
          .chat-menu-icons {
            li {
              margin-left: unset;
              &:nth-child(n + 2) {
                margin-left: unset;
                margin-right: 24px;
              }
            }
          }
        }
      }
      &.pe-0 {
        padding-right: 15px !important;
      }
    }
    .chat {
      .chat-message {
        .text-box {
          .input-group-append {
            margin-right: -2px;
          }
          .btn {
            border-top-left-radius: 5px !important;
            border-bottom-left-radius: 5px !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
          }
        }
      }
    }
  }
  .chat-msg-box {
    ul {
      padding-right: 0;
    }
  }
  .product-price{
    direction: ltr;
  }
  .chat-left-aside {
    .status-circle {
      left: unset;
      right: 40px;
    }
  }
  .chat-right-aside {
    .chat {
      .chat-header {
        .chat-menu-icons {
          padding-left: 0;
          padding-right: unset;
          &.float-sm-right {
            float: left !important;
          }
        }
        img {
          float: right;
        }
      }
    }
  }
  // user pages
  .edit-profile {
    .profile-title {
      .media {
        .media-body {
          margin-right: 25px;
          margin-left: unset;
        }
      }
    }
  }
  // bookmark
  .bookmark-tabcontent {
    .tab-content {
      .card {
        .d-flex {
          ul {
            li {
              + li {
                margin-right: 5px;
                margin-left: unset;
              }
            }
          }
        }
      }
    }
    .details-bookmark {
      &.list-bookmark {
        .bookmark-card {
          .details-website {
            .title-bookmark {
              .hover-block {
                left: 30px;
                right: unset;
                text-align: left;
              }
            }
          }
          &.card {
            .favourite-icon {
              left: 15px;
              right: unset;
            }
          }
        }
      }
    }
  }
  .modal-header {
    .btn-close {
      margin-right: auto;
      margin-left: unset;
    }
  }
  .modal-header,
  .modal-footer,
  .modal-content {
    .btn-close {
      left: 25px;
      right: unset;
    }
  }
  // contact
  .list-persons {
    .profile-mail {
      .email-general {
        ul {
          padding-right: unset;
          padding-left: 20px;
          li {
            span {
              float: left;
            }
          }
        }
      }
      .media {
        .media-body {
          ul {
            li {
              + li {
                padding-left: unset;
                padding-right: 5px;
                margin-left: unset;
                margin-right: 5px;
                border-left: unset;
                border-right: 2px solid $light-color;
              }
            }
          }
        }
      }
    }
  }
  .contact-options {
    li {
      .btn-category {
        padding-right: 40px !important;
        padding-left: unset !important;
      }
    }
  }
  .contacts-tabs {
    .nav-pills {
      border-right: unset;
      border-left: 1px solid $light-semi-gray;
    }
  }
  // email css
  .email-wrap {
    .btn-mail {
      text-align: right;
    }
    .email-app-sidebar {
      .main-menu {
        & > li {
          text-align: right;
          a {
            i {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
      ul {
        li {
          a {
            padding-right: 20px;
            padding-left: unset;
          }
        }
      }
    }
    .email-content {
      .email-top {
        .user-emailid {
          &:after {
            float: left;
          }
        }
      }
    }
    .email-body {
      .attachment {
        ul {
          li {
            &:nth-child(n + 2) {
              margin-left: unset;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .actions {
      text-align: left;
    }
    .email-right-aside {
      .email-body {
        .media {
          .media-body {
            .inbox {
              svg {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }
        .inbox {
          svg {
            margin-right: unset;
            margin-left: 10px;
          }
          .media {
            .media-body {
              span {
                left: 20px;
                right: unset;
              }
            }
          }
        }
      }
      .email-profile {
        .inbox {
          p {
            margin-right: 15px;
            margin-left: unset;
          }
        }
      }
    }
  }
  .email-wrap{
    .email-wrapper{
      .right-download{
        float: left;
      }
      h6{
        float: right;
      }
    }
  }
  .calendar-wrap {
    .fc {
      direction: rtl;
      text-align: right;

      .fc-toolbar {
        .fc-left {
          float: right;
        }
        .fc-right {
          float: left;
        }
        & > * {
          & > * {
            margin-left: 0;
          }
        }
      }
    }
    .fc-events-container {
      text-align: right;
    }
    .fc-scroller {
      margin-right: unset;
      margin-left: -17px;
    }
  }
  .fc-agenda-view {
    .fc-day-grid {
      .fc-row {
        margin-right: 0 !important;
      }
    }
  }
  .fc-unthemed {
    .fc-row {
      margin-right: 0 !important;
    }
  }
  // form css
  .was-validated .form-control:invalid,
  html[dir="rtl"] .form-control.is-invalid,
  html[dir="rtl"] .was-validated .form-control:valid,
  html[dir="rtl"] .form-control.is-valid {
    background-position: left calc(0.375em + 0.1875rem) center;
    padding-left: calc(1.5em + 0.75rem);
    padding-right: 12px;
  }
  // switch
  .switch-showcase {
    .form-check {
      .form-check-input {
        float: left;
      }
    }
  }
  //tables csss
  .alert-dismissible {
    .btn-close {
      left: 0;
      right: unset;
    }
  }
  .dismiss-text {
    .alert {
      .btn-close {
        left: 40px;
      }
    }
  }
  // alert
  .alert {
    padding-right: 30px;
    padding-right: 15px;
  }
  // accordian
  .default-according {
    .card {
      .card-header {
        .btn-link {
          text-align: unset;
          text-align: right;
        }
      }
    }
  }
  .default-according {
    .card {
      .card-header {
        i {
          margin-left: 5px;
          margin-right: unset;
        }
      }
    }
  }
  .cal-date-widget {
    .cal-info {
      .pe-3 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
      }
      .ps-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
      }
    }
  }
  .me-4,
  .mx-4 {
    margin-left: 1.5rem !important;
    margin-right: unset !important;
  }
  .pe-4,
  .px-4 {
    padding-left: 1.5rem !important;
    padding-right: unset !important;
  }
  .me-1,
  .mx-1 {
    margin-left: 0.25rem !important;
    margin-right: unset !important;
  }
  .navs-dropdown {
    .onhover-show-div {
      right: 0;
    }
  }
  .default-according {
    &.style-1 {
      button {
        text-align: right;
        &:before {
          left: 20px;
          right: unset;
        }
        i {
          right: 18px;
          left: unset;
        }
      }
    }
    .card {
      .card-header {
        i {
          right: 18px;
          left: unset;
        }
      }
    }
  }
  .ProfileCard-details {
    padding-right: 30px;
    padding-left: unset;
    float: right;
  }
  .theme-tab {
    .tab-title {
      li {
        a {
          svg {
            margin-left: 5px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .spent {
    .spent-graph {
      .project-budget {
        margin-left: 30px;
        margin-right: unset;
      }
    }
  }
  .support-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              padding-right: 0;
              padding-left: unset;
            }
          }
        }
      }
    }
  }
  .widget-joins {
    .row {
      .pe-0 {
        padding-right: 15px !important;
      }
      .ps-0 {
        padding-left: 15px !important;
      }
    }
  }
  .order-box {
    .sub-total,
    .total,
    .qty {
      .shipping-class,
      li {
        .shopping-checkout-option,
        span {
          float: left;
          text-align: left;
        }
      }
      li {
        .count {
          float: left;
        }
      }
    }
    .title-box {
      span {
        float: left;
        text-align: left;
      }
    }
    .sub-total {
      .shipping-class {
        .shopping-checkout-option {
          label {
            text-align: left;
          }
        }
      }
    }
  }
  .img-paypal {
    margin-right: 15px;
    margin-left: unset;
  }
  .product-qnty {
    fieldset {
      .input-group {
        .input-group-prepend {
          .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }
  }
  .grid-options {
    margin-right: 10px;
    margin-left: unset;
    ul {
      li {
        a {
          .line-grid-1 {
            right: 12px;
            left: unset;
          }
          .line-grid-2 {
            right: 18px;
            left: unset;
          }
          .line-grid-3 {
            right: 36px;
            left: unset;
          }
          .line-grid-4 {
            right: 42px;
            left: unset;
          }
          .line-grid-5 {
            right: 48px;
            left: unset;
          }
          .line-grid-6 {
            right: 66px;
            left: unset;
          }
          .line-grid-7 {
            right: 72px;
            left: unset;
          }
          .line-grid-8 {
            right: 78px;
            left: unset;
          }
          .line-grid-9 {
            right: 84px;
            left: unset;
          }
          .line-grid-10 {
            right: 103px;
            left: unset;
          }
          .line-grid-11 {
            right: 109px;
            left: unset;
          }
          .line-grid-12 {
            right: 115px;
            left: unset;
          }
          .line-grid-13 {
            right: 121px;
            left: unset;
          }
          .line-grid-14 {
            right: 127px;
            left: unset;
          }
          .line-grid-15 {
            right: 133px;
            left: unset;
          }
        }
      }
    }
  }
  .product-filter {
    &.new-products {
      .owl-theme {
        .owl-nav {
          left: 0;
          right: unset;
        }
        .owl-item {
          .item {
            .product-box {
              .product-img {
                margin-right: 0;
                margin-left: 20px;
                order: 2;
              }
              .product-details {
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }
  .product-sidebar {
    .filter-section {
      .card {
        .card-header {
          h6 {
            .pull-right {
              i {
                left: auto;
                right: unset;
              }
            }
          }
        }
      }
    }
    .product-details {
      text-align: left !important;
    }
  }
  .feature-products {
    form {
      > div {
        i {
          left: 30px;
          right: unset;
        }
      }
    }
  }
  .product-wrapper-grid {
    &.list-view {
      .product-box {
        .product-details {
          text-align: right;
        }
      }
    }
  }
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .breadcrumb {
          margin-left: unset;
        }
      }
    }
  }
  .breadcrumb-item {
    + .breadcrumb-item {
      padding-left: unset;
      padding-right: 0.5rem;
      &:before {
        padding-right: unset;
        padding-left: 0.5rem;
        float: right;
      }
    }
  }
  .bookmark {
    ul {
      li {
        + li {
          margin-left: unset;
          margin-right: 10px;
        }
        .search-form {
          .form-control-search {
            left: -50px;
            right: unset;
            &:before {
              right: 50px;
              left: unset;
            }
            &:after {
              right: 20px;
              left: unset;
            }
          }
        }
      }
    }
  }
  .me-0,
  .mx-0 {
    margin-left: 0 !important;
    margin-right: unset !important;
  }
  .footer {
    .pull-right {
      float: left;
    }
  }
  .checkbox_animated,
  .radio_animated {
    margin: 0 8px 0 16px;
  }
  .button.remove {
    left: -10px;
    right: unset;
  }
  .code-box-copy__btn {
    left: 11px;
    right: unset;
  }
  ul {
    &.nav-menus {
      border-bottom: none;
    }
    &.notification-dropdown {
      &.onhover-show-div {
        li {
          text-align: right;
        }
      }
    }
  }
  .badge {
    + .badge {
      margin-right: 5px;
      margin-left: unset;
    }
  }
  .offset-xl-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  .m-l-10 {
    margin-right: 10px !important;
    margin-left: unset !important;
  }
  .m-r-20 {
    margin-left: 20px;
    margin-right: unset;
  }
  .m-r-30 {
    margin-left: 30px;
    margin-right: unset;
  }
  .m-r-5 {
    margin-left: 5px;
    margin-right: unset;
  }
  .text-start {
    text-align: right !important;
  }
  .me-2 {
    margin-left: 10px;
    margin-right: unset;
  }
  .pe-3,
  .px-3 {
    padding-left: 1rem !important;
    padding-right: unset !important;
  }
  .p-r-0 {
    padding-left: 0;
    padding-right: 15px;
  }
  .m-l-20 {
    margin-right: 20px !important;
    margin-left: unset !important;
  }
  .ps-3,
  .px-3 {
    padding-right: 1rem !important;
    padding-left: unset !important;
  }
  .b-r-light {
    border-left: 1px solid $light-color !important;
    border-right: unset !important;
  }
  .float-end {
    float: left !important;
  }
  .float-start {
    float: right !important;
  }
  .text-end {
    text-align: left !important;
  }
  .border-right {
    border-left: 1px solid $tab-color !important;
    border-right: unset !important;
  }
  .pe-0,
  .px-0 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  .ps-0,
  .px-0 {
    padding-right: 0 !important;
    padding-left: unset !important;
  }
  .ms-1 {
    margin-left: unset !important;
    margin-right: 0.25rem !important;
  }
  .owl-carousel {
    direction: ltr;
  }
  .btn-group {
    > {
      .btn:not(:last-child):not(.dropdown-toggle),
      :not(:last-child) > .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
      .btn:not(:first-child) {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      .btn:nth-child(2) {
        border-radius: 0 !important;
      }
    }
    .btn-group > :not(:first-child) > .btn {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .btn + {
      .btn,
      .btn-group {
        margin-right: -1px;
        border-right: none;
      }
    }
    .btn-group + {
      .btn,
      .btn-group {
        margin-right: -1px;
      }
    }
    .btn-group-vertical {
      .btn + {
        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
      .btn-group + {
        .btn,
        .btn-group {
          margin-right: -1px;
        }
      }
    }
  }
  .dzu-inputLabelWithFiles {
    margin-left: unset;
    margin-right: 30px;
  }
  // react tree view page
  .deni-react-treeview-item-container {
    .icon-and-text {
      .icon  {
        margin-right: unset;
        margin-left: 5px;
      }
    }
    &.level-2 {
      padding-left: unset !important;
      padding-right: 35px !important;
    }
    &.level-3 {
      padding-left: unset !important;
      padding-right: 65px !important;
    }
  }
  // dropdown page
  .dropdown-basic {
    .btn-group {
      > .btnn:not(:last-child):not(.dropdown-toggle) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
      }
    }
    .separated-btn {
      .btn {
        border-radius: 0.25rem;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
    .btn-group {
      margin-right: unset;
      margin-left: 18px;
    }
  }
  .me-3,
  .mx-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
  }
  .me-5,
  .mx-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
  }
  .ms-3,
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: unset !important;
  }
  .m-r-15 {
    margin-left: 15px;
    margin-right: unset;
  }
  .b-l-light {
    border-right: 1px solid $light-color !important;
    border-left: unset !important;
  }
  .p-l-0 {
    padding-left: unset;
    padding-right: 0px;
  }
  .ps-4 {
    padding-right: 1.5rem !important;
    padding-left: unset !important;
  }
  .me-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
  .ms-2 {
    margin-right: 0.5rem !important;
    margin-left: unset !important;
  }
  .pe-2 {
    padding-right: unset !important;
    padding-left: 0.5rem !important;
  }
  .alert {
    text-align: right;
  }
  .pull-right {
    float: left;
  }
  .form-inline {
    .form-group {
      margin-left: 15px;
      margin-right: 0;
    }
  }
  .card {
    text-align: right;
    .card-header {
      h5:not(.mb-0) {
        float: right;
      }
      span {
        clear: both;
      }
      .card-header-right {
        left: 35px;
        right: unset;
        .card-option {
          text-align: left;
          &.list-unstyled {
            padding-right: 0;
            padding-left: unset;
          }
        }
      }
      .pull-left {
        float: right;
      }
    }
    &.full-card {
      left: 0;
      right: unset;
    }
    .card-body {
      .alert {
        svg {
          margin-left: 4px;
          margin-right: unset;
        }
      }
      .value-left {
        margin-left: 30px;
        margin-right: unset;
      }
      .square {
        margin-left: 5px;
        margin-right: unset;
      }
    }
  }
  .modal-header {
    .close {
      margin-left: 0;
    }
  }
  .modal-footer {
    > :not(:first-child) {
      margin-right: 0.5rem;
      margin-left: 0;
    }
  }
  .form-inline {
    .form-group {
      .col-form-label {
        margin-left: 5px;
        margin-right: 0;
      }
    }
  }
  code[class*="language-"],
  pre[class*="language-"] {
    text-align: right;
  }
  .input-group-prepend {
    margin-left: -1px;
    margin-right: unset;
  }
  .input-group > .form-control:not(:last-child),
  .rtl .input-group > .form-select:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group
    > .input-group-append:last-child
    > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group
    > .input-group-append:last-child
    > .input-group-text:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group
    > .input-group-prepend:first-child
    > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .border-tab.nav-left .nav-link {
    text-align: left;
  }
  // social app page css
  .socialprofile {
    .social-group {
      ul {
        li {
          &:nth-child(4) {
            margin-right: -22px;
          }
        }
      }
    }
  }
  .social-status {
    form {
      .form-group {
        .form-control-social {
          padding-right: 15px;
          padding-left: unset;
        }
      }
    }
    .media {
      .social-status {
        right: 35px;
        left: unset;
      }
    }
  }
  .social-chat {
    .other-msg {
      margin-right: 40px;
      margin-left: unset;
    }
    .media-body {
      &:before {
        left: 100%;
        right: unset;
        border-left: 7px solid var(-semi-dark);
        border-right: unset;
      }
      &:after {
        left: 100%;
        right: unset;
        border-left: 7px solid $white;
        border-right: unset;
      }
    }
  }
  .timeline-content {
    .comment-number {
      i {
        margin-left: 20px;
        margin-right: unset;
      }
    }
  }
  .photos {
    ul {
      li {
        margin-left: 10px;
        margin-right: unset;
      }
    }
  }
  .avatar-showcase {
    .friend-pic {
      margin-left: 8px;
      margin-right: unset;
    }
    .pepole-knows {
      ul {
        li {
          margin-left: 21px;
          margin-right: unset;
          &:last-child {
            margin-left: 0;
          }
        }
      }
    }
  }
  // faq page css
  .faq-form {
    .search-icon {
      left: 12px;
      right: unset;
    }
  }
  .faq-widgets {
    .media-body {
      p {
        padding-left: 20px;
        padding-right: unset;
      }
    }
  }
  .header-faq,
  .faq-title {
    text-align: right;
  }
  .faq-accordion {
    .faq-header {
      svg {
        left: 20px;
        right: unset;
      }
    }
    .card {
      .btn-link {
        svg {
          margin-left: 10px !important;
          margin-right: unset;
          right: 20px;
        }
      }
    }
    &.default-according {
      .card {
        .card-header {
          .btn-link {
            padding-right: 52px !important;
            padding-left: 30px !important;
          }
        }
      }
    }
  }
  .navigation-option {
    ul {
      li {
        a {
          padding-right: 40px;
          padding-left: unset;
          svg {
            right: 15px;
            left: unset;
          }
        }
      }
    }
  }
  .updates-faq {
    margin-right: unset;
    margin-left: 30px;
  }
  // faq
  .job-search {
    .media {
      .media-body {
        ul.rating {
          margin-right: 10px;
          margin-left: unset;
        }
        .job-apply-btn {
          left: 30px;
          right: unset;
        }
      }
    }
  }
  .location-checkbox {
    span {
      padding-right: 33px;
      padding-left: 0;
    }
  }
  // knowledgebase page css
  .browse {
    .browse-articles {
      span {
        svg {
          margin-left: 10px;
          margin-right: unset;
        }
      }
      h6 {
        padding-right: 25px;
        padding-left: 0;
        span {
          svg {
            right: 0;
            left: unset;
          }
        }
      }
      ul {
        li {
          h5 {
            padding-right: 20px;
            padding-left: 0;
            svg {
              right: 0;
              left: unset;
              margin-left: 10px;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  // support-ticket
  .support-ticket {
    .pro-gress {
      ul {
        li {
          span {
            i {
              margin-right: 10px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  // learning app page css
  .categories {
    .learning-header {
      padding-right: 30px;
      padding-left: unset;
    }
    ul {
      li {
        padding: 5px 30px 5px 60px;
        .badge {
          right: unset;
          left: 30px;
        }
      }
    }
  }
  // flag-icon page
  .flag-icons,
  .feather-icons {
    div {
      .media-body {
        h5 {
          margin-left: 5px;
          margin-right: unset;
        }
      }
    }
  }
  //checkout page
  .form-check{
    .form-check-input {
      margin-right: -1.25rem;
      margin-left: unset;
    }
  }
  .pricing-block {
    svg {
      direction: ltr;
    }
  }
  // avatars page css
  .avatars {
    .avatar {
      margin-left: 10px;
      margin-right: unset;
      &:last-child {
        margin-left: 0;
      }
      .status {
        left: 4px;
        right: unset;
      }
    }
  }
  .customers {
    &.avatar-group {
      margin-left: 30px;
      margin-right: unset;
    }
  }
  .card {
    .card-header {
      h5 {
        float: none !important;
      }
    }
  }
  // general widgets css
  .weather-widget-two {
    .top-bg-whether {
      left: -28px;
      right: unset;
    }
    .bottom-whetherinfo {
      svg {
        left: unset;
        right: -35px;
      }
    }
  }
  .mobile-clock-widget {
    .bg-svg {
      left: unset;
      right: -25px;
    }
  }
  .redial-social-widget {
    &.radial-bar-70 {
      background-image: linear-gradient(
          342deg,
          $primary-color 90%,
          transparent 50%,
          transparent
        ),
        linear-gradient(270deg, $primary-color 50%, $light-color 50%, $light-color);
    }
  }
  // to-do css
  .todo {
    .todo-list-wrapper {
      .mark-all-tasks {
        left: 30px;
        right: unset;
      }
      #todo-list {
        li {
          .task-container {
            .task-action-btn {
              text-align: left;
            }
          }
        }
      }
    }
    .notification-popup {
      left: 10px;
      right: unset;
    }
  }
  .todo-options {
    .badges-todo {
      h6 {
        margin-left: 10px;
        margin-right: unset;
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
  .todo-list-btns {
    .dropdown-basic {
      .btn-group {
        .form-group {
          .checkbox {
            padding-right: 14px;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;
            padding-left: unset;
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }
      }
      .separated-btn {
        margin-right: -6px;
        margin-left: unset;
        .btn {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
        }
      }
    }
  }
  .search-todo {
    .dropdown-basic {
      .btn-group {
        margin-left: 18px;
        margin-right: unset;
      }
    }
  }
  //sticky note css
  .sticky-note {
    .note {
      float: right;
    }
  }
  // mega-menu css
  .mega-menu {
    .list-unstyled {
      div {
        a {
          padding: 8px 0 8px 35px;
          &:hover {
            padding: 8px 10px 8px 35px;
          }
        }
      }
    }
  }
  #testimonial {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .slide--item {
            .media {
              .me-3,
              .mx-3 {
                margin-right: 1rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
      }
    }
  }
  .needs-validation,
  .form-row {
    .input-group > .form-control:not(:last-child),
    .input-group > .form-select:not(:last-child) {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .form-check {
    padding-right: 1.25rem;
    padding-left: unset;
  }
  .mega-menu {
    .default-according {
      .card {
        .btn-link {
          text-align: right;
        }
      }
      &.style-1 {
        button[aria-expanded="true"],
        button[aria-expanded="false"] {
          &:before {
            right: unset;
            left: 20px;
          }
        }
      }
    }
  }
  // buttons css
  .btn-group-showcase {
    .btn-group {
      margin-left: 20px;
      margin-right: unset;
    }
  }
  .btn-showcase {
    .btn {
      margin-right: unset;
      margin-left: 18px;
    }
  }
  .btn-square {
    &.dropdown-toggle {
      border-top-left-radius: 0.25rem !important;
      border-bottom-left-radius: 0.25rem !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  #customer-review {
    .owl-item {
      img {
        order: 2;
      }
    }
  }
  .options {
    > div {
      margin: 0 0 8px 8px;
    }
  }
  // modal page
  .modal-dialog {
    .modal-content {
      .modal-body {
        p {
          a {
            margin-left: 0;
          }
        }
      }
    }
  }
  // forms page
  .drag-box {
    fieldset {
      .component {
        .form-group {
          .text-lg-start {
            text-align: right !important;
          }
          .input-group {
            .input-group-prepend {
              .btn,
              .input-group-text {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
              }
              .btn-right,
              .checkbox-radius {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
              }
            }
            #prependedcheckbox {
              border-top-right-radius: 0 !important;
              border-bottom-right-radius: 0 !important;
            }
            #appendedcheckbox {
              border-right-color: $light-semi-gray;
            }
            #buttondropdown {
              margin-right: unset;
              margin-left: -1px;
            }
          }
        }
      }
    }
  }
  .drag {
    form {
      text-align: right;
      input {
        text-align: right;
      }
      label {
        text-align: right !important;
      }
      .form-group {
        .input-group {
          .input-group-prepend {
            .btn,
            .input-group-text {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
            }
            .btn-right,
            .checkbox-radius {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
          #buttondropdown {
            margin-right: unset;
            margin-left: -1px;
          }
        }
      }
    }
  }
  .draggable {
    label {
      text-align: right;
    }
  }
  // compact small page
  .page-wrapper {
    .page-body-wrapper {
      .logo-wrapper {
        box-shadow: 9px 0 20px rgba($semi-dark, 0.1) !important;
        a {
          transform: translate(-64px);
        }
      }
    }
  }
  // search page
  .search-page {
    .nav i {
      margin-left: 10px;
      margin-right: unset;
    }
  }
  .product-stts {
    display: inline-block;
    .ms-1 {
      margin-right: 0.25rem !important;
      margin-left: unset !important;
    }
  }
  .search-form input {
    padding: 10px 70px 10px 10px;
    text-align: right;
  }
  // icons page
  .icon-hover-bottom {
    .icon-title {
      text-align: right;
    }
    .icon-popup {
      .icon-last {
        .form-inline {
          .form-group {
            .btn {
              margin-right: 15px;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  .icon-lists {
    div {
      i {
        margin: 0 10px 0 15px;
      }
    }
    ul {
      padding-right: 0;
    }
  }
  ul {
    &.icon-lists {
      padding-right: 30px;
    }
  }
  // customizer
  .floated-customizer-btn {
    &.third-floated-btn {
      left: 35px;
      right: unset;
    }
    & > span {
      margin-right: 10px;
    }
  }
  .floated-customizer-panel {
    text-align: right;
    left: -85px;
    right: unset;
    &.active {
      left: 35px;
      right: unset;
    }
    .close-customizer-btn {
      left: 30px;
      right: unset;
    }
    ul {
      padding-right: 0;
    }
  }
  // sidebar main page
  .page-wrapper {
    .page-body-wrapper {
      .page-header {
        .row {
          h3 {
            text-align: right;
          }
        }
      }
    }
    &.compact-wrapper {
      .page-main-header {
        margin-right: 280px;
        margin-left: unset;
        width: calc(100% - 280px);
        &.close_icon {
          margin-right: 0;
          margin-left: unset;
          width: calc(100% - 0px);
        }
      }
    }
    &.compact-sidebar {
      &.compact-wrapper {
        .page-main-header {
          margin-right: 150px;
          margin-left: unset;
          width: calc(100% - 150px);
          &.close_icon {
            margin-right: 0;
            width: calc(100% - 0px);
          }
        }
        .page-body-wrapper {
          header {
            &.main-nav {
              width: 150px;
              .main-navbar {
                .nav-menu {
                  > li {
                    .nav-submenu {
                      right: 150px;
                      left: unset;
                    }
                    &.mega-menu {
                      .menu-title {
                        &.active {
                          ~ .mega-menu-container {
                            right: 150px;
                            left: unset;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.close_icon {
                transform: translate(410px);
              }
            }
          }
          .page-body {
            margin-right: 150px;
            margin-left: unset;
          }
          footer {
            margin-right: 150px;
            margin-left: unset;
          }
        }
      }
    }
  }
  .customizer-contain {
    .customizer-body {
      .dashboard-type {
        .slick-next {
          left: unset;
        }
      }
    }
  }
  .page-main-header{
    .main-header-right{
      .nav-right{
        .onhover-show-div{
          left: 0;
          right: unset;
        }
      }
    }
  }   
  .tag-section{
    span{
      margin-right: 10px;
      margin-left: unset;
    }
    p{
      margin-right: 10px;
      margin-left: unset;
    }
  }
  // page main header
  .page-main-header {
    .main-header-right {
      .left-menu-header {
        text-align: right;
      }
      .nav-right {
        text-align: right;
        .chat-dropdown {
          left: -20px;
          right: unset;
          &:before,
          &:after {
            left: 30px !important;
            right: unset !important;
          }
          li {
            .media {
              .status-circle {
                left: unset;
                right: 0;
              }
            }
          }
        }
        > ul {
          > li {
            &:before {
              right: 0;
              left: unset;
            }
            .dot {
              left: 17px;
              right: unset;
            }
            &.onhover-dropdown {
              &:hover {
                .onhover-show-div {
                  &:before {
                    left: 25px;
                    right: unset;
                  }
                  &:after {
                    left: 25px;
                    right: unset;
                  }
                }
              }
              &:last-child {
                border-right: none;
              }
            }
            text-align: right;
            i {
              &.ms-2 {
                margin-right: 0.5rem !important;
                margin-left: unset !important;
              }
            }
          }
        }
        .profile-dropdown {
          right: -80px;
          left: unset;
          &:before,
          &:after {
            left: 15px !important;
            right: unset !important;
          }
          li {
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
      .mobile-sidebar {
        padding-left: 20px;
        padding-right: unset;
      }
    }
    &.open {
      margin-right: 0;
    }
  }
  ul {
    &.notification-dropdown {
      &.onhover-show-div {
        right: initial;
        left: -30px;
        padding: 0;
        &:before {
          right: inherit !important;
          left: 35px !important;
        }
        &:after {
          right: inherit !important;
          left: 35px !important;
        }
        li {
          margin-right: 0 !important;
          span {
            &.badge {
              margin-left: unset !important;
            }
            svg {
              margin-right: unset;
              margin-left: 10px;
            }
          }
          .notification-icon {
            margin-left: 20px;
            margin-right: unset;
          }
        }
      }
    }
  }
  .page-main-header {
    .main-header-right {
      .nav-right{
        .bookmark-flip{
          .ProfileCard-realName{
            text-align: right;
          }
        }
      }
      .toggle-sidebar {
        margin-left: 25px;
      }
      .left-menu-header {
        ul {
          li {
            .search-form {
              border-left: unset;
              border-right: 1px solid $light-gray;
            }
          }
        }
      }
    }
  }
  // alert page
  .card-body {
    .alert {
      svg {
        ~ p {
          padding-left: unset;
          padding-right: 20px;
        }
      }
    }
  }
  .alert {
    i {
      margin-left: 5px;
      margin-right: 0;
    }
  }
  .inverse {
    padding: 13px 65px 13px 20px;
    i {
      left: unset;
      right: 0;
    }
  }
  @each $var in $alert-name {
    $i: index($alert-name, $var);
    .alert-#{$var}.inverse {
      &:before {
        border-right: 7px solid nth($alert-color, $i);
        right: 54px;
        left: unset;
        border-left: unset;
      }
    }
  }
  .alert-dismissible {
    .close {
      left: 0;
      right: unset;
      span {
        padding-left: 0;
        padding-right: unset;
      }
    }
  }
  .icofont {
    &.icofont-truck {
      display: inline-block;
    }
  }
  // blog page
  .blog-box {
    .blog-details {
      padding-left: 20px;
      padding-right: unset;
      text-align: right;
      .blog-social {
        padding-right: 0;
        li {
          &:nth-child(n + 2) {
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid $light-gray;
            border-left: none;
            padding-left: unset;
            margin-left: unset;
          }
        }
      }
    }
    .blog-details-main {
      .blog-social {
        li {
          border-left: 1px solid $light-gray;
          border-right: unset;
          &:last-child {
            border-left: none;
          }
        }
      }
    }
    .blog-date {
      span {
        font-size: 36px;
        font-weight: 500;
        padding-left: 5px;
        padding-right: unset;
      }
    }
    &.blog-shadow {
      .blog-details {
        padding-right: 40px;
      }
    }
    .blog-details-second {
      .detail-footer {
        ul {
          &.social-list {
            li {
              i {
                margin-left: 5px;
                margin-right: unset;
              }
              &:nth-child(n + 2) {
                margin-right: 15px;
                padding-right: 15px;
                border-right: 1px solid $light-gray;
                margin-left: unset;
                padding-left: unset;
                border-left: unset;
              }
            }
          }
        }
      }
    }
  }
  .comment-box {
    .media {
      img {
        margin-left: 45px;
        margin-right: unset !important;
      }
      h6 {
        text-align: right;
      }
    }
    ul {
      ul {
        margin-right: 135px;
        margin-left: unset;
        padding-right: 0;
      }
    }
    .comment-social {
      text-align: left;
      margin-right: unset;
      li {
        &:nth-child(n + 2) {
          margin-right: 20px;
          padding-right: 20px;
          border-right: 1px solid $light-gray;
          margin-left: unset;
          padding-left: unset;
          border-left: unset;
        }
      }
    }
  }
  // social app page
  .custom-card {
    .card-footer {
      & > div {
        & + div {
          border-right: 1px solid $light-semi-gray;
          border-left: unset;
        }
      }
    }
    .card-social {
      li {
        &:nth-child(n + 2) {
          margin-left: 0;
          margin-right: 10px;
        }
      }
    }
    .card-profile {
      left: unset;
      right: 60px;
    }
  }
  .card-social {
    padding-right: 0;
  }
  .card-absolute {
    .card-header {
      right: 15px;
      left: unset;
    }
  }
  // error page
  .error-wrapper {
    .col-md-8 {
      &.offset-md-2 {
        margin: 0 auto !important;
      }
    }
  }
  // product page
  .modal.product-box{
    .modal-header{
      .product-details{
        .product-size{
          ul{
            li{
              &:nth-child(n + 2){
                margin-left: unset;
                margin-right: 15px;
              }
            }
          }
        }
        .product-qnty{
          .input-group{
            .btn.bootstrap-touchspin-down{
              border-radius: 0 0.25rem 0.25rem 0;
            }
          }
        }
        .rating-pro {
          li {
            + li {
              margin-left: unset;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .product-social {
    li {
      &:nth-child(n + 2) {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }
  ul.pro-services {
    li {
      svg {
        margin-left: 15px;
        margin-right: unset;
      }
    }
  }
  .product-box {
    .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
    .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
      border-left-color: $primary-color;
    }
    .ribbon-bookmark.ribbon-vertical-left:before,
    .ribbon-bookmark.ribbon-vertical-right:before {
      right: 0;
      left: unset;
      border-left: 15px solid $dark-color;
    }
    .ribbon-bookmark.ribbon-info:before {
      border-left-color: transparent;
    }
    .ribbon-warning.ribbon-clip:before {
      border-left-color: $warning-color;
      border-right-color: unset;
    }
    .ribbon-clip {
      right: -12px;
      left: unset;
      &:before {
        right: 0;
        left: unset;
      }
    }
    .product-details {
      .addcart-btn {
        .btn {
          &:nth-child(n + 2) {
            margin-right: 15px;
            margin-left: unset;
          }
        }
      }
    }
    .modal {
      .modal-header {
        .product-box {
          .product-details {
            .product-size {
              ul {
                li {
                  &:nth-child(n + 2) {
                    margin-right: 15px;
                    margin-left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .product-hover {
    ul {
      padding-right: 0;
    }
  }
  .customer-review {
    .media {
      img {
        margin-left: 25px;
        margin-right: 0;
      }
    }
  }
  .nav {
    padding-right: 0;
  }
  .form-label {
    &::after {
      left: 0;
      right: unset;
    }
  }
  // general widgets page
  .static-top-widget .media-body {
    padding-right: 30px;
    padding-left: unset;
    .icon-bg {
      left: -20px;
      right: unset;
    }
  }
  .widget-joins {
    .media {
      .details {
        border-right: 1px solid $light-semi-gray;
        border-left: unset;
      }
      .media-body {
        text-align: right;
      }
    }
  }
  .weather-widget-two {
    .bottom-whetherinfo {
      .whether-content {
        text-align: left;
      }
    }
  }
  // image cropper page
  .img-cropper {
    .btn-group {
      margin-right: 0;
      margin-left: 10px;
      button {
        &:last-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:first-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .docs-toggles {
    .btn-group {
      .btn {
        border-radius: 0;
        &:last-child {
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        &:first-child {
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }
    }
  }
  .btn-group {
    > .btn:not(:last-child):not(.dropdown-toggle) {
      > .btn-group:not(:last-child) > .btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .list-group {
    padding-right: 0;
    i,
    img {
      margin-left: 10px;
      margin-right: 0;
    }
  }
  .customers {
    .list-group {
      img {
        margin-left: 0;
      }
    }
  }
  // authentication page
  .authentication-box {
    text-align: right;
  }
  // mega options page
  .mega-horizontal {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
  }
  // navs page
  .navs-icon {
    svg {
      margin-left: 10px;
      margin-right: unset;
    }
    .fa {
      margin-left: 10px;
      margin-right: unset;
    }
  }
  .nav-list {
    .nav-list-disc {
      text-align: right;
      padding-right: 0;
    }
  }
  .pl-navs-inline {
    padding-right: 30px !important;
    padding-left: 0 !important;
  }
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
    left: 77px;
    right: unset;
  }
  .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
    left: 77px;
    right: unset;
  }
  .navs-dropdown {
    .onhover-show-div {
      .navs-icon {
        li {
          text-align: right;
        }
      }
    }
  }
  .navs-icon.default-according.style-1 {
    li {
      button[aria-expanded="true"] {
        &:before {
          left: 2px;
          right: unset;
        }
      }
      button[aria-expanded="false"]:before {
        left: 2px;
        right: unset;
      }
    }
  }
  // search page css
  .search-form {
    .form-group {
      &:after {
        right: 53px;
        left: unset;
      }
      &:before {
        right: 82px;
        left: unset;
      }
    }
  }
  // order history page css
  .order-history {
    table {
      tbody {
        tr {
          td {
            .product-name {
              .order-process {
                .order-process-circle {
                  &:before {
                    right: -15px;
                    left: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // product-page
  .product-page-details {
    span {
      padding-right: 15px;
      padding-left: 0;
    }
  }
  .m-checkbox-inline {
    label {
      margin-left: 20px;
      margin-right: 0;
    }
  }
  .form-label-align-right {
    label {
      text-align: left;
    }
  }
  .product-page-main {
    .pro-group {
      .pro-shop {
        .btn {
          &:nth-child(n + 2) {
            margin-right: 15px;
            margin-left: 0;
          }
        }
      }
    }
  }
  // rating page
  .br-theme-bars-reversed,
  .br-theme-bars-movie,
  .br-theme-bars-1to10,
  .br-theme-bars-square {
    .br-widget {
      .br-current-rating {
        float: right;
      }
      a {
        float: right;
      }
    }
  }
  // ribbons page
  .ribbon-left {
    right: auto;
    left: -2px;
  }
  .ribbon-vertical-left {
    right: auto;
    left: 12px;
  }
  .ribbon-vertical-right {
    right: auto;
    left: 12px;
  }
  // search page
  .star-ratings {
    ul {
      &.search-info {
        padding-right: 0;
      }
    }
  }
  // search page
  .search-page {
    ul {
      &.search-info {
        > li {
          + li {
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid $light-gray;
            padding-left: unset;
            margin-left: unset;
            border-left: unset;
          }
        }
      }
    }
    .search-form {
      .form-group {
        .input-group-text {
          right: 0;
          left: unset;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      }
    }
  }
  .CodeMirror-scroll {
    margin-left: -30px;
    margin-right: unset;
  }
  .primary-color,
  .yellow-color {
    ul {
      padding-right: 0;
    }
  }
  // steps page
  .u-step-desc {
    text-align: right;
  }
  .u-step-number {
    right: 20px;
    left: unset;
    & ~ .u-step-desc {
      margin-right: 50px;
    }
  }
  .u-pearl {
    &:after {
      left: 0;
      right: unset;
    }
    &:before {
      right: 0;
      left: unset;
    }
  }
  .u-step-icon {
    float: right;
    margin-left: 0.5em;
    margin-right: unset;
  }
  // summernote page
  .list-icons {
    padding-right: 0;
  }
  .card-header.note-toolbar {
    .note-color {
      .dropdown-toggle {
        padding-right: 5px;
      }
    }
    .form-group {
      &.draggable {
        text-align: right;
      }
    }
    .form-check {
      padding-right: 24px;
      padding-left: unset;
    }
    form {
      .custom-control {
        padding-right: 24px;
        padding-left: unset;
        .custom-control-label {
          &::after {
            right: 0;
            left: unset;
          }
          &::before {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }
  // input group page
  .dropdown-toggle::after {
    margin-right: 0.255em;
    margin-left: unset;
  }
  // tabbed card page
  .tabbed-card {
    ul {
      left: 22px;
      right: unset;
    }
  }
  // checkbox & radios page
  .checkbox {
    label {
      padding-right: 16px;
      padding-left: unset;
      &::before {
        right: 0;
        left: unset;
        margin-right: -16px;
        margin-left: unset;
      }
    }
  }
  .custom-radio-ml {
    margin-left: unset;
    margin-right: 6px;
  }
  .radio {
    label {
      padding-left: unset;
      padding-right: 5px;
      &::before {
        right: 0;
        left: unset;
        margin-right: -20px;
        margin-left: unset;
      }
      &::after {
        left: unset;
        right: 5px;
        margin-right: -20px;
        margin-left: unset;
      }
    }
  }
  .list-inline-item {
    &:not(:last-child) {
      margin-left: 0.5rem;
      margin-right: unset;
    }
  }
  .like-comment {
    ul {
      &.list-inline {
        padding-right: 0;
      }
    }
  }
  .listing {
    ul {
      padding-right: 0;
      .icofont {
        float: right;
      }
    }
  }
  // gallery page
  .gallery {
    .hover-5 {
      img {
        margin-right: 30px;
      }
      &:hover {
        img {
          margin-right: 0;
        }
      }
    }
  }
  // chart widget page
  .chart-widget-top {
    .text-end {
      text-align: left !important;
    }
    .num {
      .ms-1 {
        margin-right: 0.25rem !important;
      }
    }
  }
  .bar-chart-widget {
    .earning-details {
      i {
        left: -240px;
      }
    }
    .bottom-content {
      .b-r-light {
        border-left: unset !important;
        + .b-r-light{
          border-right: 1px solid $light-gray !important;
          border-left: unset !important;
        }
      }
      .num {
        .ms-1 {
          margin-right: 0.25rem !important;
        }
      }
    }
  }
  // dashboard project page
  .chart-vertical-center {
    #myDoughnutGraph,
    #myPolarGraph {
      width: auto !important;
    }
  }
  .crm-activity {
    ul {
      &.dates {
        li {
          + li {
            border-right: 1px solid $light-gray;
            padding-right: 10px;
            margin-right: 5px;
            border-left: unset;
            padding-left: unset;
            margin-left: unset;
          }
        }
      }
    }
  }
  div {
    &.dt-buttons {
      float: right;
    }
  }
  .dt-button-collection {
    left: -39px !important;
  }
  // footer page
  .page-wrapper {
    .page-body-wrapper {
      footer {
        margin-right: 280px;
        margin-left: unset;
        p {
          i {
            margin-right: 5px;
            margin-left: unset;
          }
        }
      }
    }
  }
  .footer-copyright {
    text-align: right;
  }
  .page-item {
    &:first-child {
      .page-link {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    &:last-child {
      .page-link {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // popover page
  .popover.top,
  .popover.bottom {
    > .arrow:after {
      margin-right: -10px;
      margin-left: unset;
    }
  }
  // touchspin page
  .bootstrap-touchspin {
    > .input-group-append {
      > .btn,
      > .input-group-text {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }
    > .form-control,
    > .form-select {
      &:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  // vector map page
  .jvectormap-zoomin,
  .jvectormap-zoomout,
  .jvectormap-goback {
    right: 10px;
    left: unset;
  }
  //customizer rtl css
  .customizer-links {
    right: unset;
    left: 0;
    &.open {
      right: unset;
      left: 330px;
      border-radius: 0 8px 8px 0;
    }
  }
  .customizer-contain {
    width: 330px;
    right: unset;
    left: -330px;
    &.open {
      right: unset;
      left: 0px;
    }
    .customizer-header {
      .icon-close {
        left: unset;
        right: 30px;
      }
    }
  }
  // boxes layout rtl css
  .box-layout {
    &.page-wrapper.horizontal-wrapper {
      .page-main-header {
        padding-right: 250px;
        padding-left: unset;
        &.open {
          padding-right: 0;
        }
      }
    }
  }
  // responsive css
  @media screen and (min-width: 1200px) and (max-width: 1500px) {
    .activity-sec {
      .activity-timeline {
        &.update-line {
          .media {
            .activity-line {
              right: 26px;
            }
          }
        }
      }
    }
    .blog-box {
      &.blog-shadow {
        .blog-details {
          padding-right: 15px;
        }
      }
      .blog-details {
        .blog-social {
          li {
            &:nth-child(n + 2) {
              margin-right: 15px;
              padding-right: 15px;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1366px) and (max-width: 1500px){
    .ecommerce-dash{
      .slid-wrapper{
        right: unset;
        left: 15px;
      }
    } 
  }
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    .learning-comment {
      float: right !important;
    }
    .comment-box {
      .media {
        img {
          margin-left: 25px;
        }
      }
      ul {
        ul {
          margin-right: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 1750px) {
    .show-value-top {
      .counry-data {
        &:nth-child(n + 2) {
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 1660px) {
    // video chat page
    .chat-box {
      .chat-right-aside {
        .chat {
          .chat-header {
            .chat-menu-icons {
              li:nth-child(n + 2) {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    // dashboard 2
    .perform-overview-sec {
      .overview {
        .slick-prev {
          right: 25%;
        }
        .slick-next {
          right: 20%;
        }
      }
    }
  }
  @media screen and (max-width: 1470px) {
    .edit-profile {
      .profile-title {
        .media {
          .media-body {
            margin-right: unset;
          }
        }
      }
    }
    // dashboard 2
    .perform-overview-sec {
      .overview {
        .slick-prev {
          right: 32%;
        }
        .slick-next {
          right: 27%;
        }
      }
    }
  }
  @media screen and (max-width: 1280px) {
    // user profile
    .user-profile {
      .social-tab {
        ul {
          li {
            &:nth-child(n + 2) {
              margin-right: 10px;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1366px) {
    // contact page
    .contact-options {
      li {
        .btn-category {
          padding-right: 30px !important;
        }
      }
      .learning-comment {
        margin-right: -14px !important;
        margin-left: unset !important;
      }
    }
  }
  @media only screen and (max-width: 1366px) {
    .file-content{
      .files,.folder{
        .file-box, .folder-box{
          margin-left: 8px !important;
          margin-right: unset !important;
        }
      }
    }  
    .product-wrapper {
      .product-grid {
        .feature-products {
          margin-right: 0;
        }
      }
    }
    .product-grid {
      margin-right: 0;
    }
    .d-none-productlist {
      margin-left: 10px;
      margin-right: unset;
    }
    // chat page
    .chat-left-aside {
      .status-circle {
        left: unset;
        right: 40px;
      }
    }
    .status-circle {
      right: 37px;
      left: unset;
    }
    // ecommerce dashboard css
    .best-dealer-table {
      table {
        tbody {
          tr {
            td {
              .fa-check-circle {
                right: 2px;
              }
            }
          }
        }
      }
    }
    .offer-box {
      .offer-slider {
        .selling-slide {
          .d-flex {
            .left-content {
              padding-left: unset;
              padding-right: 15px;
            }
          }
        }
      }
    }
    // file
    .file-content {
      .files {
        .file-box {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .page-wrapper {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              li {
                .submenu {
                  li {
                    &:first-child {
                      padding-right: 15px !important;
                      padding-left: 15px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .md-sidebar {
      .md-sidebar-aside {
        left: unset;
        right: 0;
      }
    }
    .jkanban-container {
      .btn {
        margin-right: 20px;
        margin-left: unset;
      }
    }
    .invoice {
      .invo-profile {
        .invo-profile-right {
          text-align: right;
        }
      }
    }
    // email application
    .email-wrap {
      .email-app-sidebar {
        .main-menu {
          & > li {
            text-align: right;
            a {
              i {
                margin-right: unset;
                margin-left: 10px;
              }
            }
          }
        }
        ul {
          padding-right: 0;
        }
      }
      .email-content {
        .email-top {
          .user-emailid {
            &:after {
              float: left;
            }
          }
        }
      }
      .email-sidebar {
        .email-left-aside {
          right: 0;
          left: unset;
        }
      }
    }
    // chat
    .chat-menu-icons {
      text-align: left;
    }
    .chat-box {
      .chat-history {
        .row {
          .col-sm-7 {
            padding-left: 0 !important;
            padding-right: unset !important;
          }
        }
      }
    }
    .chat-menu {
      left: 0;
      right: unset;
    }
    .invo-profile {
      .text-xl-end {
        text-align: right !important;
      }
    }
    .blog-box {
      &.blog-shadow {
        .blog-details {
          padding-right: 20px;
        }
      }
      .blog-details {
        padding-left: 0;
      }
      &.blog-list {
        .blog-details {
          padding: 20px;
        }
      }
    }
    .blog-single {
      .comment-box {
        ul {
          .comment-social {
            margin-right: unset;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .page-wrapper{
      .page-main-header{
        .main-header-left{
          .logo-wrapper{
            img{
              margin-right: unset;
              margin-left: 3px;
            }
          }
        }
      }
    } 
    .payment-sec-main {
      #sync2 {
        .item {
          .check-circle  {
            left: 12px;
          }
        }
      }
    }
    // index
    .card {
      .setting-option {
        left: 20px;
        top: 20px;
      }
    }
    .faq-accordion {
      .faq-header {
        svg {
          left: 20px;
        }
      }
    }
    .categories {
      .learning-header {
        padding-right: 20px;
      }
      ul {
        li {
          padding: 5px 20px;
        }
      }
    }
    .page-body-wrapper {
      .page-body,
      footer {
        margin-right: 0 !important;
      }
    }
    .page-wrapper {
      .page-body-wrapper {
        .page-header {
          .breadcrumb {
            float: left;
            margin-right: 0;
          }
        }
        .page-body {
          min-height: calc(100vh - 60px);
        }
      }
      .page-main-header {
        margin-right: 0;
      }
      &.compact-wrapper {
        .page-main-header {
          margin-right: 0 !important;
          margin-left: unset !important;
        }
        .page-body-wrapper {
          header {
            &.main-nav {
              &.close_icon {
                transform: translate(285px);
              }
            }
          }
        }
      }
    }
    .card-body {
      .value-left {
        margin-left: 20px;
      }
    }
    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              left: -127px;
            }
          }
        }
      }
    }
    .card {
      .card-header {
        .card-header-right {
          left: 15px;
        }
      }
    }
    .comment-box {
      .comment-social {
        li {
          &:nth-child(n + 2) {
            padding-right: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  @media (min-width: 768px) {
    .float-md-end {
      float: left !important;
    }
    .text-md-end {
      text-align: left !important;
    }
    .offset-md-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    .text-md-start {
      text-align: right !important;
    }
    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;
        ul {
          text-align: left;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) and (min-width: 576px){
    .page-main-header {
      .main-header-right {
        .nav-right {
          .language-dropdown{
            left: unset;
            right: -55px;
          }
          .notification-dropdown,.chat-dropdown, .bookmark-flip{
            right: unset !important;
            left: -120px !important;
          }
        }
      }
    }
    .page-main-header{
      .main-header-right{
        .toggle-sidebar{
          margin-left: 18px;
          margin-right: unset;
        }
      }
    }  
  }
  @media only screen and (max-width: 767px) {
    .footer-copyright{
      ~ div{
        p{
          float: none !important;
        }
      }
    }
    .feature-products{
      .filter-toggle{
        margin-right: 10px;
        margin-left: unset;
      }
    }
    .email-wrap {
      .email-right-aside {
        .email-body {
          .inbox {
            .media {
              .media-body {
                span  {
                  left: unset;
                  margin-left: unset;
                  margin-right: auto;
                }
              }
            }
          }
        }
      }
    }
    // user profile
    .ecommerce-widget {
      .text-md-end {
        text-align: left !important;
      }
    }
    .bar-chart-widget {
      .earning-details {
        i {
          left: -147px !important;
        }
      }
    }
    .blog-box {
      .blog-details {
        .blog-social {
          li {
            &:nth-child(n + 2) {
              margin-right: 10px;
              padding-right: 10px;
            }
          }
        }
      }
    }
    // genaral
    .text-md-end {
      text-align: left;
    }
    // project page
    .project-list {
      .btn {
        float: right;
      }
    }
    // blog
    .comment-box {
      ul {
        ul {
          text-align: right;
          float: revert;
          margin-right: 0;
        }
      }
    }
    .blog-single {
      .comment-box {
        ul {
          .comment-social {
            text-align: right;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 700px) {
    .jkanban-container {
      .btn {
        margin-right: unset;
      }
    }
  }
  @media screen and (min-width: 576px) and (max-width: 710px) {
    // widget
    .widget-joins {
      .media {
        .media-body {
          padding-right: unset;
        }
      }
    }
  }
  @media (min-width: 576px) {
    .offset-sm-3 {
      margin-right: 25%;
      margin-left: unset;
    }
    .text-sm-end {
      text-align: left !important;
    }
    .float-sm-end {
      float: left !important;
    }
    .me-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
      margin-right: unset !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .widget-joins {
      .row {
        .pe-0 {
          padding-right: 15px !important;
          padding-left: 15px !important;
        }
        .ps-0 {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
      }
    }
    .page-main-header{
      .main-header-right{
        .nav-right.right-menu{
          ul{
            li{
              .profile-media{
                img{
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
    .page-wrapper{
      .page-main-header{
        .main-header-right{
          .nav-right{
            .notification-dropdown.cart{
              left: unset !important;
              right: -15px !important;
            }
          }
          .main-header-left{
            margin-right: unset;
          }
        }
      }
      &.compact-sidebar {
        &.compact-wrapper {
          .page-body-wrapper {
            header {
              &.main-nav {
                width: 120px;
                .main-navbar {
                  .nav-menu {
                    > li {
                      .nav-submenu {
                        right: 120px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }   
    .page-main-header {
      .main-header-right {
        .toggle-sidebar{
          margin-left: 8px;
          margin-right: unset;
        }
        .nav-right {
          .profile-dropdown{
            left: unset;
            right: -103px;
          }
          > ul {
            > li {
              margin-left: 0;
              &:last-child {
                padding-right: 10px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    .faq-accordion {
      .faq-header {
        svg {
          left: 15px;
        }
      }
    }
    .categories {
      .learning-header {
        padding-right: 15px;
      }
    }
    .page-wrapper {
      .search-form {
        .form-group {
          margin-left: 0;
        }
      }
    }
    .card-body {
      .value-left {
        margin-left: 15px;
      }
    }
    .bookmark {
      float: right;
    }
    .todo {
      .todo-list-wrapper {
        .mark-all-tasks {
          left: 15px;
        }
      }
    }
    // dashboard
    .dash-breadcrumb {
      .breadcrumb-sec {
        &.pull-right {
          float: right;
        }
      }
    }
    // ecommerce
    .feature-products {
      .featured-sec {
        text-align: right;
        .select-options {
          float: right;
          margin-left: 10px;
          margin-right: unset;
        }
      }
    }
    .invoice {
      .invo-header {
        .media-body {
          text-align: left;
        }
      }
    }
    // user-profile
    .user-profile {
      .profile-post {
        .post-body {
          .post-react {
            ul {
              li {
                &:nth-child(n + 2) {
                  margin-right: -15px;
                }
              }
            }
            h6 {
              margin-right: 10px;
            }
          }
        }
      }
    }
    // contact
    .list-persons {
      .nav-pills {
        .nav-link {
          .media {
            .media-body {
              text-align: right;
            }
          }
        }
      }
      .profile-mail {
        .email-general {
          ul {
            padding-left: unset;
          }
        }
      }
    }
    // search page
    .search-page {
      .search-form {
        .form-group {
          input {
            padding: 8px 60px 8px 8px;
          }
        }
      }
    }
    .invoice {
      .invo-header {
        .media-body {
          text-align: left;
        }
      }
    }
    // blog page
    .blog-box {
      .blog-details {
        .blog-social {
          li {
            &:nth-child(n + 2) {
              margin-right: 15px;
              padding-right: unset;
              border-right: none;
            }
          }
        }
      }
    }
    .feature-products {
      .select-options {
        float: right;
      }
    }
    // job search
    .job-search {
      .media {
        .media-body {
          h6 {
            .pull-right {
              margin-right: 15px;
              margin-left: 0;
            }
          }
        }
      }
      .job-description {
        label {
          text-align: right !important;
        }
      }
    }
  }
  @media only screen and (max-width: 480px){
    .email-wrap{
      .email-wrapper{
        h6,.right-download{
          float: none;
        }
      }
    }  
  }
  @media only screen and (max-width: 360px) {
    .project-box{
      .badge{
        left: 8px;
        right: unset;
      }
    }
    .page-wrapper{
      .page-main-header{
        .main-header-right{
          .nav-right.right-menu{
            .notification-dropdown.cart{
              right: -48px !important;
              left: unset !important;
            }
            .onhover-show-div.bookmark-flip{
              left: unset;
              right: -85px;
            }
            .notification-dropdown{
              right: -95px !important;
              left: unset !important;
            }
            .language-dropdown{
              left: unset;
              right: -18px;
            }
          }
        }
      }
    }    
    .social-app-profile {
      .hovercard {
        .user-image {
          .avatar {
            img {
              width: 85px;
              height: 85px;
            }
            margin-top: -47px;
          }
        }
      }
    }
    .social-chat {
      .other-msg {
        margin-right: 15px;
      }
    }
  }
  @media screen and (max-width: 1460px) {
    header {
      .main-navbar {
        .nav-menu {
          > li {
            margin-left: 8px;
            margin-right: unset;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .file-content {
      .folder {
        .folder-box {
          .media {
            .media-body {
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 420px) {
    .chat-box{
      .chat-menu{
        right: 10px;
        left: unset;
      }
    } 
    .email-wrap{
      .actions{
        text-align: right;
      }
    } 
    .new-product-section{
      .slid-wrapper{
        right: unset;
        left: 25px;
      }
    } 
    .product-page-main {
      .pro-group {
        .pro-shop {
          .btn {
            &:nth-child(n + 2) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &.dark-only{
    .page-wrapper {
      &.compact-wrapper, &.horizontal-wrapper {
        .search-page {
          ul.search-info {
            > li {
              + li {
                border-left: none;
                border-right: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
    
    .custom-card{
      .card-footer{
        > div{
          + div{
            border-right: 1px solid $dark-card-border;
            border-left: unset !important;
          }
        }
      }
    }
    .bar-chart-widget {
      .bottom-content {
        .b-r-light {
          border-left: unset !important;
          + .b-r-light{
            border-right: 1px solid $dark-card-border !important;
            border-left: unset !important;
          }
        }
      }
    }
    .widget-joins{
      .media{
        .details{
          border-right: 1px solid $dark-card-border;
          border-left: unset !important;
        }
      }
    }
    .b-r-light{
      border-left: 1px solid $dark-card-border !important;
      border-right: unset !important;
    }
    .crm-activity{
      ul.dates{
        li{
          + li{
            border-right: 1px solid $dark-card-border;
            border-left: unset;
          }
        }
      }
    }
    .project-dash{
      .center-content{
        .center-content-right{
          border-right: 1px solid $dark-card-border;
          border-left: unset;
        }
      }
    }  
    .ecommerce-dash{
      .center-content{
        .center-content-right{
          border-right: 1px solid $dark-card-border;
          border-left: unset;
        }
      }
    }  
    .goal-overview-sec{
      .goal-details{
        li{
          &:nth-child(n + 2){
            border-right: 1px solid $dark-card-border;
            border-left: unset !important;
          }
        }
      }
    }
    .social-review-sec{
      .review-details{
        li{
          &:nth-child(2){
            border-right: 1px solid $dark-card-border;
            border-left: unset !important;
          }
        }
      }
    }
    .dashboard-chat{
      .chat{
        .right-side-chat{
          .media-body{
            p{
              &::before{
                border-right: 7px solid $dark-card-background;
                border-left: unset;
              }
            }
          }
        }
        .left-side-chat{
          .media-body{
            p{
              &::before{
                border-left: 7px solid $dark-body-background;
                border-right: unset;
              }
            }
          }
        }
      }
    }
    .page-wrapper{
      &.compact-wrapper{
        .page-body-wrapper{
          header.main-nav{
            border-left: 1px solid $dark-card-border;
          }
        }
      }
    }  
    .page-main-header{
      .main-header-right{
        .left-menu-header{
          ul{
            li{
              .search-form{
                border-right: 1px solid $dark-card-border;
              }
            }
          }
        }
      }
    }
  }
}
/**=====================
    35. RTL CSS Ends
==========================**/